import React from 'react';


const PatronVisitorForm = () => {

 
  return (
    <React.Fragment>
      <section className='patron-visitor form'>
          <h4>Patron Visitor</h4>    
      </section>
    </React.Fragment>
  );
}

export default PatronVisitorForm;