
/***
 * Convert a Feathers error object to plain JS
 */


export interface FeathersError  {
  name: string,
  message: string,
  stack: string,
  code: number,
}

export const serializeFeathersError = (err:any) => {
    const e:FeathersError = {
      name: err.name,
      message: err.message,
      stack: err.stack,
      code: err.code
    }
    if (e.code === undefined){
      e.code = 500;
    }
    return e;
  }
  