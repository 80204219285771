import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import "../FormRenderer.scss";
import ReportSearchCriteria from "./ReportSearchCriteria";
import { Col, Form, Row } from "react-bootstrap";
import "./Reports.scss";
import DataTable from "react-data-table-component";
import { selectLocations } from "../../../features/locations/locationsSlice";
import { PieChart } from "./PieChart";
import { CSVLink } from "react-csv";
import {
  createStaffTotalTimeReport,
  ExtStaffDurationReportElement,
  selectStaffTotalTimeReport,
} from "../../../features/reports/staffTotalTimeReportSlice";
import { useNavigate } from "react-router-dom";
import { selectAuthUser } from "../../../features/auth/authSlice";
import { ExtPermissions } from "../../api/api.types";
import { showNotification } from "../../../features/notifications/notificationSlice";
import doesUserHavePermission from "../../../utils/doesUserHavePermission";


interface CsvHeader {
  label: string;
  key: string;
}

const convertMinToTime = (min: number) => {
  const hours = min / 60;
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);
  const hstr = rhours < 10 ? `0${rhours}` : rhours.toString();
  const mstr = rminutes < 10 ? `0${rminutes}` : rminutes.toString();
  return `${hstr}:${mstr}`;
};

/**
 * Calculates the total time spent by all / selected employees
 * with visitors, interactions, events, etc.
 */

const StaffTimeReport = ({
  isTabletOrMobile,
}: {
  isTabletOrMobile: boolean;
}) => {
  const locationsList = useAppSelector(selectLocations);
  const staffTimeReport = useAppSelector(selectStaffTotalTimeReport);
  
  const [reportCreatedAt, setReportCreatedAt] = useState<Date>(new Date());
  const [reportLocations, setReportLocations] = useState<Array<string>>([]);

  const [isGraphView, setIsGraphView] = useState<boolean>(false);
  const [pieChartData, setPieChartData] = useState<any>();


  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const authUser = useAppSelector(selectAuthUser);

  useEffect(()=>{
    if (doesUserHavePermission(authUser, ExtPermissions.canCreateReports)  ){
          // All good, they're allowed
        }else{
          
          const navNow = ()=>{
          dispatch(showNotification({
                header: "You can't do that",
                body: 'You do not have permission to access reports.',
                type: 'error'
            }))
            navigate('/');
          }
          navNow();
          return;
        }
  }, [authUser, dispatch, navigate])

  /**
   * Set the table columns.
   * Fixed definition for this report.
   */
  const columns = [
    {
      name: "Staff Name",
      selector: (row: ExtStaffDurationReportElement) => row.staff.name,
      sortable: true,
      minWidth: "200px",
    },
    {
      name: "Visitors",
      selector: (row: ExtStaffDurationReportElement) => row.staff.name,
      sortable: true,
      compact: true,
      center: true,
      minWidth: "60px",
      maxWidth: "70px",
      wrap: true,
      format: (row: ExtStaffDurationReportElement) =>
        convertMinToTime(row.patronVisitor.minutes),
    },
    {
      name: "Interactions",
      selector: (row: ExtStaffDurationReportElement) => row.staff.name,
      sortable: true,
      compact: true,
      center: true,
      minWidth: "90px",
      maxWidth: "90px",
      wrap: true,
      format: (row: ExtStaffDurationReportElement) =>
        convertMinToTime(row.patronInteraction.minutes),
    },
    {
      name: "Gaming Staff",
      selector: (row: ExtStaffDurationReportElement) => row.staff.name,
      sortable: true,
      compact: true,
      center: true,
      minWidth: "60px",
      maxWidth: "70px",
      wrap: true,
      allowOverflow: true,
      format: (row: ExtStaffDurationReportElement) =>
        convertMinToTime(row.gamingStaffVisitor.minutes),
    },
    {
      name: "Patron Event",
      selector: (row: ExtStaffDurationReportElement) => row.staff.name,
      sortable: true,
      compact: true,
      center: true,
      minWidth: "60px",
      maxWidth: "70px",
      wrap: true,
      format: (row: ExtStaffDurationReportElement) =>
        convertMinToTime(row.event.partrons.minutes),
    },
    {
      name: "Staff Event",
      selector: (row: ExtStaffDurationReportElement) => row.staff.name,
      sortable: true,
      compact: true,
      center: true,
      minWidth: "60px",
      maxWidth: "70px",
      wrap: true,
      format: (row: ExtStaffDurationReportElement) =>
        convertMinToTime(row.event.staff.minutes),
    },
    {
      name: "Other Event",
      selector: (row: ExtStaffDurationReportElement) => row.staff.name,
      sortable: true,
      compact: true,
      center: true,
      minWidth: "60px",
      maxWidth: "70px",
      wrap: true,
      format: (row: ExtStaffDurationReportElement) =>
        convertMinToTime(row.event.other.minutes),
    },
    {
      name: "Total",
      selector: (row: ExtStaffDurationReportElement) => row.staff.name,
      sortable: true,
      compact: true,
      center: true,
      wrap: true,
      format: (row: ExtStaffDurationReportElement) => {
        return convertMinToTime(
          row.patronVisitor.minutes +
            row.patronInteraction.minutes +
            row.gamingStaffVisitor.minutes +
            row.event.partrons.minutes +
            row.event.staff.minutes +
            row.event.other.minutes
        );
      },
    },
  ];



  /**
   * Generate the list of locations selected, or set as all.
   */
  useEffect(() => {
    if (staffTimeReport === undefined) {
      return;
    }

    console.log("Report result", staffTimeReport);
    setReportCreatedAt(new Date(staffTimeReport!.createdAt));

    // Set which locations this report includes:
    if (staffTimeReport.criteria["location.lids"] === undefined) {
      setReportLocations(["All"]);
    } else {
      const locList: Array<string> = [];
      staffTimeReport.criteria["location.lids"].forEach((lid) => {
        const loc = locationsList.find((l) => {
          return l._id === lid;
        });
        locList.push(loc?.name!);
      });
      setReportLocations(locList);
    }

    
  }, [staffTimeReport, locationsList]);



  /**
   * Format the data for the Pie Chart display
   */
  useEffect(()=>{
    // Sum all "columns", i.e. the different types
    let totalPatronVisitors:number = 0;
    let totalPatronInteractions:number = 0;
    let totalGamingStaff:number = 0;
    let totalEventPatron:number = 0;
    let totalEventStaff:number = 0;
    let totalEventOther:number = 0;

    staffTimeReport?.records.forEach( (rec)=>{
      totalPatronVisitors += rec.patronVisitor.minutes;
      totalPatronInteractions += rec.patronInteraction.minutes;
      totalGamingStaff += rec.gamingStaffVisitor.minutes;
      totalEventPatron += rec.event.partrons.minutes;
      totalEventStaff += rec.event.staff.minutes;
      totalEventOther += rec.event.other.minutes;
    })

    const data:any = [
      {
       id: `Patron Visitors - ${convertMinToTime(totalPatronVisitors)}`,
        value: totalPatronVisitors,
      },
      {
        id: `Patron Interactions - ${convertMinToTime(totalPatronInteractions)}`,
        value: totalPatronInteractions,
      },
      {
        id: `Gaming Staff Visitors - ${convertMinToTime(totalGamingStaff)}`,
        value: totalGamingStaff,
      },
      {
        id: `Patron Events - ${convertMinToTime(totalEventPatron)}`,
        value: totalEventPatron,
      },
      {
        id: `Staff Events - ${convertMinToTime(totalEventStaff)}`,
        value: totalEventStaff,
      },
      {
        id: `Other Events - ${convertMinToTime(totalEventOther)}`,
        value: totalEventOther,
      }
    ];
    setPieChartData(data);
  }, [staffTimeReport])


  /**
   * Generate the headers necessary for a CSV document.
   */
  const getCsvHeaders = () => {
    const headers: Array<CsvHeader> = [
      {
        label: "Staff Name",
        key: "STAFF",
      },
      {
        label: "Patron Visitors",
        key: "PV",
      },
      {
        label: "Patron Interactions",
        key: "PI",
      },
      {
        label: "Gaming Staff Visitors",
        key: "SV",
      },
      {
        label: "Patron Event",
        key: "EV-PT",
      },
      {
        label: "Staff Event",
        key: "EV-ST",
      },
      {
        label: "Other Event",
        key: "EV-OT",
      },
      {
        label: "Total Time",
        key: "TT",
      },
    ];

    return headers;
  };





  /**
   * Format the report data for CSV export.
   * @returns Array with large object of key/value pairs
   */
  const getCsvReportData = ():Array<any> => {
    const rowList:Array<any> = [];
    staffTimeReport?.records.forEach((rec) => {
      const row: any = {};
      row["STAFF"] = rec.staff.name;
      row["PV"] = rec.patronVisitor.minutes;
      row["PI"] = rec.patronInteraction.minutes;
      row["SV"] = rec.gamingStaffVisitor.minutes;
      row["EV-PT"] = rec.event.partrons.minutes;
      row["EV-ST"] = rec.event.staff.minutes;
      row["EV-OT"] = rec.event.other.minutes;
      row["TT"] =
        rec.patronVisitor.minutes +
        rec.patronInteraction.minutes +
        rec.gamingStaffVisitor.minutes +
        rec.event.partrons.minutes +
        rec.event.staff.minutes +
        rec.event.other.minutes;
      rowList.push(row);
    });

    return rowList;
  };



  /**
   * Generate a filename based on the report type and date
   * @returns string the file name
   */
  const getCsvFileName = () => {
    const name = `Staff_Total_Time_Minutes-${new Date().toDateString()}.csv`;
    const re = / /gi;
    const result = name.replace(re, "_");
    return result;
  };



  return (
    <React.Fragment>
      <section className="reports patron-visitor">
        <h2>Staff Time Report</h2>
        <p>
          Staff Time Reports track the time employee's spent with Visitors,
          Gaming Staff, and Events. Events have employee's associated with them. 
          Other form types use the Form Author as the related staff member.
        </p>
        <p>
          Patron Visitor and Gaming Staff Visitor times are rounded down. These
          forms use a time-range (eg. 01-05 minutes) rather than actual in/out
          times. As a result the lower number is used in the calculation. I.e. A
          range of 01-05 minutes will be calculated using 1 minute as the value.
        </p>

        <hr />
        <ReportSearchCriteria
          isTabletOrMobile={isTabletOrMobile}
          reportSelector={selectStaffTotalTimeReport}
          reportThunk={createStaffTotalTimeReport}
          showFormTypeSelector={false}
          showStaffSelector={true}
          showLocationSelector={true}
        />

        {staffTimeReport && (
          <section className="report">
            <Form className="ext-form">
              <Row className="header">
                <Col sm={5}>
                  <h3>Report</h3>
                </Col>
                <Col sm={4}>
                  <Form.Check
                    type="switch"
                    id="visualization-check"
                    label="Visualize Data"
                    className="visualization-check"
                    checked={isGraphView}
                    onChange={() => setIsGraphView(!isGraphView)}
                  />
                </Col>
                <Col sm={3}>
                  <CSVLink
                    data={getCsvReportData()}
                    headers={getCsvHeaders()}
                    filename={getCsvFileName()}
                    className="csv"
                  >
                    Export CSV
                  </CSVLink>
                </Col>
              </Row>

              <Row>
                <section className="input-section">
                  <h4>Staff Total Time Report</h4>
                  <Form.Group as={Row} className="mb-3" controlId="reportType">
                    <Form.Label column md={4} aria-label="Reported At">
                      Report Created At:
                    </Form.Label>
                    <Col md={8}>
                      <Form.Control
                        as="input"
                        value={`${reportCreatedAt.toDateString()} @ ${reportCreatedAt.toLocaleTimeString()}`}
                        readOnly
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="location">
                    <Form.Label column md={4} aria-label="Location">
                      Location(s):
                    </Form.Label>
                    <Col md={8}>
                      <p className="report-locations">
                        {reportLocations.map((name) => {
                          return <span key={name}>{name}</span>;
                        })}
                      </p>
                    </Col>
                  </Form.Group>
                </section>
              </Row>
                    
              
              { !isGraphView && <Row className="data-table">
                <DataTable
                  columns={columns}
                  data={staffTimeReport.records}
                  dense={isTabletOrMobile}
                  fixedHeader
                  pagination
                  paginationPerPage={30}
                  responsive
                  subHeaderWrap
                  highlightOnHover
                  pointerOnHover
                  striped
                />
              </Row>
              }
              { isGraphView && <Row className="graph">
                <div className="chart">
                  <label>Staff Time Graph</label>
                  <PieChart
                    pieData={ pieChartData }
                    isTabletOrMobile={isTabletOrMobile}
                  />
                </div>
                
              </Row>
              }
            </Form>
          </section>
        )}
      </section>
    </React.Fragment>
  );
};

export default StaffTimeReport;
