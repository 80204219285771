import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import "../FormRenderer.scss";
import ReportSearchCriteria from "./ReportSearchCriteria";
import { Col, Form, Row } from "react-bootstrap";
import "./Reports.scss";
import DataTable from "react-data-table-component";
import { CSVLink } from "react-csv";
import { selectAuthUser } from "../../../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { showNotification } from "../../../features/notifications/notificationSlice";
import {
  ExtFormField,
  ExtFormSection,
  ExtOption,
  ExtPermissions,
} from "../../api/api.types";
import doesUserHavePermission from "../../../utils/doesUserHavePermission";
import {
  createFilterReport,
  selectFilterReport,
} from "../../../features/reports/filterReportSlice";
import { selectForms } from "../../../features/forms/formsSlice";


interface CsvHeader {
  label: string;
  key: string;
}

interface InputData {
  lid: string;
  name: string;
  [key: string]: string | number;
}

interface FieldSection {
  field: ExtFormField;
  section: ExtFormSection;
  option: ExtOption;
  parent?: string
}

const ReportFiltered = ({
  isTabletOrMobile,
}: {
  isTabletOrMobile: boolean;
}) => {
  const formList = useAppSelector(selectForms);
  const filteredReport = useAppSelector(selectFilterReport);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const authUser = useAppSelector(selectAuthUser);

  const [columns, setColumns] = useState<Array<any>>([]);
  const [csvHeaders, setCsvHeaders] =  useState<Array<CsvHeader>>([]);

  useEffect(() => {
    if (doesUserHavePermission(authUser, ExtPermissions.canCreateReports)) {
      // All good, they're allowed
    } else {
      const navNow = () => {
        dispatch(
          showNotification({
            header: "You can't do that",
            body: "You do not have permission to access reports.",
            type: "error",
          })
        );
        navigate("/");
      };
      navNow();
      return;
    }
  }, [authUser, dispatch, navigate]);



  const searchFormSectionForField = (section:ExtFormSection, fid:string, valKey:string):FieldSection|undefined =>{
    if (section === undefined){
      console.error("searchFormSectionForField section undefined")
      return;
    }
    if (section.sid === 'PI-PRIVACY' && fid === 'PI-PRIVACY-UNDERSTOOD'){
    //  debugger;
    }
    for (let j = 0; j < section.fields.length; j++) {
      const f = section.fields[j];
     
      if (f.fid === fid) {
        const opt = f.options.find((o) => o.oid === valKey);
        const result: FieldSection = {
          field: f,
          section: section,
          option: opt === undefined ? {
            oid: valKey,
            label: valKey,
            value: valKey,
          } : opt,
        };
        return result;
      }
      // If we didn't find it in the field, see if the field has children
      if (f.children !== undefined){
      //  debugger;
        for (let k=0;k<f.children.length;k++){
          const child = f.children[k]

          for (let l=0;l<child.sections.length;l++){
            const childResult = searchFormSectionForField(f.children[k].sections[l], fid, valKey)
            if  (childResult !== undefined){
              childResult.parent = fid
              return childResult
            }
          }
        }
      }
    }
  }



  /**
   * Take a fieldId#value combo and look up the
   * source Form.section.field
   * @param keyName
   */
  const getFieldSection = (keyName: string): FieldSection | undefined => {
    const keySplitDash = keyName.split("-");
    const formId = keySplitDash[0];

    const keySplit = keyName.split("#");


    const form = formList.find((f) => f.formId === formId);
    if (form === undefined) {
      return;
    }

    // Look for the source field 
    for (let i = 0; i < form.sections.length; i++) {
      const sec = form.sections[i];
      const result = searchFormSectionForField(sec, keySplit[0],keySplit[1])
      if (result !== undefined){
        return result
      }
    }
    console.warn("Could not find: ", keyName)
  };


  
  
  useEffect(() => {
    if (filteredReport?.recordCount === undefined) {
      return;
    }
    let headers: Array<CsvHeader> = []

    if (filteredReport?.recordCount > 0) {
      const inputData: InputData = filteredReport.records[0];
      let output = Object.keys(inputData)
        .filter((key) => key !== "lid")
        .map((key) => {
          // First obtain the friendly name/values then create the columns
          let headerName = "";
          
          if (key !== 'name'){
            const fs = getFieldSection(key);
            if (fs === undefined){
              console.warn("Could not find field for key: ", key)
              headerName = `${key}`
            }else{
              headerName = `${fs?.section.title} > ${fs?.field.label} = ${fs?.option.label}`
            }
           
            
            
            if (fs?.parent){
            //  const parent = getFieldSection(fs.parent)
             // headerName = parent?.field.label + " > " + headerName;
            }
            headers.push( {
              label: headerName,
              key: key
            })
          }
          return {
            name:
              key === "name"
                ? "Location"
                : headerName, //${fs?.section.title} >
            selector: (row: any) => row[key],
            sortable: true,
            compact: true,
            center: true,
            wrap: true,
          };
        });

        const newArr = output.filter((obj) => obj.name !== "Location");

        // sort remaining items
        newArr.sort((a, b) => a.name.localeCompare(b.name));
        headers.sort((a, b) => a.label.localeCompare(b.label));
        setColumns([output[0], ...newArr]);
        setCsvHeaders(headers)
    }
  }, [filteredReport]);

  /**
   * Generate the headers necessary for a CSV document.
   */
  const getCsvHeaders = () => {
    
    return [ { label: "Location", key: "name" }, ...csvHeaders];
  };

  /**
   * Format the report data for CSV export.
   * @returns Array with large object of key/value pairs
   */
  const getCsvReportData = (): Array<any> => {
    
    return filteredReport!.records;
  };

  /**
   * Generate a filename based on the report type and date
   * @returns string the file name
   */
  const getCsvFileName = () => {
    const name = `FilteredReport-${new Date().toDateString()}.csv`;
    const re = / /gi;
    const result = name.replace(re, "_");
    return result;
  };

  return (
    <React.Fragment>
      <section className="reports ">
        <h2>Filtered Report</h2>
        <p>
          The Filtered Report generates form field totals by form type &
          location. I.e. It will calculate the total each form field's value is
          present for the selected location and date ranges.
        </p>
        <hr />
        <ReportSearchCriteria
          isTabletOrMobile={isTabletOrMobile}
          reportSelector={selectFilterReport}
          reportThunk={createFilterReport}
          showFormTypeSelector={true}
          showStaffSelector={false}
          showLocationSelector={true}
          showFormFieldValueSelector={true}
          showSectionFieldSelector={true}
        />

        {filteredReport && (
          <section className="report">
            <Form className="ext-form">
              <Row className="header">
                <Col sm={5}>
                  <h3>Report</h3>
                </Col>
                <Col sm={4}>
                  {/*    <Form.Check
                    type="switch"
                    id="visualization-check"
                    label="Visualize Data"
                    className="visualization-check"
                    checked={isGraphView}
                    onChange={() => setIsGraphView(!isGraphView)}
        /> */}
                </Col>
                <Col sm={3}>
                  <CSVLink
                    data={getCsvReportData()}
                    headers={getCsvHeaders()}
                    filename={getCsvFileName()}
                    className="csv"
                  >
                    Export CSV
                  </CSVLink>
                </Col>
              </Row>

              <Row>
                <section className="input-section">
                  <h4>Filtered Report</h4>
                  <Form.Group as={Row} className="mb-3" controlId="reportType">
                    <Form.Label column md={4} aria-label="Reported At">
                      Report Created At:
                    </Form.Label>
                    <Col md={8}>
                      <Form.Control
                        as="input"
                        value={`${new Date(
                          filteredReport.createdAt
                        ).toDateString()} @ ${new Date(
                          filteredReport.createdAt
                        ).toLocaleTimeString()}`}
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                </section>
              </Row>

              <Row className="data-table">
                <DataTable
                  columns={columns}
                  data={filteredReport.records}
                  dense={isTabletOrMobile}
                  fixedHeader
                  responsive
                  subHeaderWrap
                  highlightOnHover
                  pointerOnHover
                  striped
                />
              </Row>
            </Form>
          </section>
        )}
      </section>
    </React.Fragment>
  );
};

export default ReportFiltered;
