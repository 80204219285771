import React, { useEffect } from 'react';
import { findUsers, selectUsersList, selectUsersNetStatus } from '../../../features/users/usersSlice';
import { useAppDispatch, useAppSelector } from '../../hooks';
import DataTable from 'react-data-table-component';
import { ExtUser } from '../../api/api.types';
import { useNavigate } from 'react-router-dom';


const UserList = ({activeUsers}:{activeUsers:boolean}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const users = useAppSelector(selectUsersList);
  const usersStatus = useAppSelector(selectUsersNetStatus);


  useEffect(()=>{
    dispatch(findUsers({active:activeUsers}));
  }, [activeUsers, dispatch])


  const columns = [
    {
      name: 'Email',
      selector: (row:any) => row.email,
      sortable: true,
    },
    {
        name: 'Last Name',
        selector: (row:any) => row.lastName,
        sortable: true,
    },
    {
        name: 'First Name',
        selector: (row:any) => row.firstName,
        sortable: true,
    },
    {
      name: 'Updated At',
      selector: (row: any) => {
        return new Date(row.updatedAt).toDateString();
      },
      sortable: true,
      sortField: "updatedAt"
    },
   
];

const onUserClicked = (user:ExtUser) =>{
  navigate(`/admin/users/edit/${user._id}`);
}

const paginationComponentOptions = {
  selectAllRowsItem: true,
  selectAllRowsItemText: 'All',
};
  
  return (
      <section className='user-list'>
        <DataTable
              columns={columns}
              data={users}
              expandableRowsHideExpander
              fixedHeaderScrollHeight="300px"
              pagination
              responsive
              subHeaderWrap
              highlightOnHover
              pointerOnHover
              progressPending={usersStatus === 'loading'}
              paginationComponentOptions={paginationComponentOptions}
              striped
              onRowClicked={(usr)=>onUserClicked(usr)}
          />
      </section>
  );
}


export default UserList;