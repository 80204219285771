/***
 * Form Section Renderer
 * 
 * Sections act as the top-level of the dynamically 
 * rendered controls on a form.
 * 
 * Examples would be General Information or 
 * Patron Visitor Profile in a Partron Visitor form.
 */
import React, { useEffect, useState } from 'react';
import {  ExtFormField,  ExtFormRecord, ExtFormSection } from '../../api/api.types';
import FormFieldRenderer from './FormFieldRenderer';




const FormSectionRenderer = ({ section, record, onChange, getFormFieldError, trackedAtDate }:
    { 
        section:ExtFormSection,
        record: ExtFormRecord|undefined,
        onChange:Function,
        getFormFieldError: Function,
        trackedAtDate?: Date,
    }) => {

    const [isSectionVisible, setIsSectionVisible] = useState<boolean>(true);

    useEffect(()=>{
        if (section.enabledDates === undefined || trackedAtDate === undefined){
            return;
        }
        const past = new Date(section.enabledDates.startDate);
        const future = new Date(section.enabledDates.endDate);
        if (trackedAtDate >= past && trackedAtDate < future){
            setIsSectionVisible(true);
        }else{
            setIsSectionVisible(false);
        }
    }, [trackedAtDate]);

    return <React.Fragment>
        { isSectionVisible && <section key={section.sid} className='input-section'>
            { section.showTitle && <h4>{section.title}</h4> }
                {section.fields.map( (field:ExtFormField) =>{
                    return  <FormFieldRenderer field={field} record={record} key={field.fid} onChange={onChange} 
                            error={ getFormFieldError(field) }
                            getFormFieldError={getFormFieldError} 
                            trackedAtDate={trackedAtDate}
                        />
                   
                })}
        </section> }
        </React.Fragment>
}

export default FormSectionRenderer;