import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { apiCreateRecord, apiDeleteRecord, apiFindRecords, apiGetRecord, apiUpdateRecord } from '../../app/api/api';
import { FeathersError, serializeFeathersError } from '../../app/api/serializeFeathersError';
import { ExtFieldValueSimple, ExtForm, ExtFormRecord } from '../../app/api/api.types';



export interface ExtRecordSearchQuery {
  _id?: string;
  internalId?: number,
  formId?: string;
  "location.lids"?: Array<string>; //Reports look $in lids
  "location.lid"?: string;        // Search is lid ==
  "createdBy.id"?: string;
  "createdBy.ids"?: Array<string>;
  reportedAt?: {
    '$gte'?: string|Date,
    '$lt' ?: string|Date,
  };
  olsonTzId?: string, //Browser TZ, eg, "America/New_York"
  
  formSectionIds?:Array<string>,
  formFieldIds?:Array<string>,
  
  $limit?: number;
  $skip?: number;
  $sort?: {};
  form?:ExtForm;//Returned from the server
  whereFields?:Array<ExtFieldValueSimple>  
}

/**
 * Async thunk state
 */
export interface RecordState {
  list: Array<ExtFormRecord>;
  record: ExtFormRecord|undefined;
  status: 'idle' | 'loading' | 'failed';
  error: FeathersError|undefined,
  pagination: {
    limit: number,
    skip: number,
    total: number,
  },
}

const initialState: RecordState = {
  list: [],
  record: undefined,
  status: 'idle',
  error: undefined,
  pagination: {
    limit: 0,
    skip: 0,
    total: 0,
  },
};

/**
 * Get a specific record
 */
export const getRecord = createAsyncThunk(
  'records/get', 
  async ( id:string ,  { rejectWithValue } ) => {
    try{
      const response = await apiGetRecord(id);
      return response;
    }catch(err){
      const e = serializeFeathersError(err)
      return rejectWithValue(e);
    }
  },
);


/**
 * Find records matching a query
 */
 export const findRecords = createAsyncThunk(
  'records/find', 
  async ( query:Object ,  { rejectWithValue } ) => {
    try{
      const response = await apiFindRecords(query);
      return response;
    }catch(err){
      const e = serializeFeathersError(err)
      return rejectWithValue(e);
    }
  },
);




/**
 * Create a new record
 */
export const createRecord = createAsyncThunk(
  'records/create',
  async (record:ExtFormRecord, { rejectWithValue }) => {
    try{
        console.log("Records Thunk calling submit api");
      const response = await apiCreateRecord(record);
      console.log("Records Submit Thunk response", response);
      return response;
    }catch(err){
      const e = serializeFeathersError(err)
      return rejectWithValue(e);
    }
  }  
)

/**
 * Update an existing record
 */
export const updateRecord = createAsyncThunk(
  'records/update',
  async (record:ExtFormRecord,  { rejectWithValue }) => {
    try{
      console.log("records Thunk calling update api");
      const response = await apiUpdateRecord(record);
      console.log("records Update Thunk response", response);
      return response;
    }catch(err){
      const e = serializeFeathersError(err)
      return rejectWithValue(e);
    }
  }  
)


export const deleteRecord = createAsyncThunk(
  'records/delete',
  async (record:ExtFormRecord,  { rejectWithValue }) => {
    try{
      console.log("records Thunk calling delete api");
      const response = await apiDeleteRecord(record);
      console.log("records delete Thunk response", response);
      return response;
    }catch(err){
      const e = serializeFeathersError(err)
      return rejectWithValue(e);
    }
  }  
)


/**
 * Creates the Redux Slice for the Records
 */
export const recordsSlice = createSlice({
  name: 'records',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getRecord.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getRecord.fulfilled, (state, action) => {
        state.status = 'idle';
        state.record = action.payload;
      })
      .addCase(getRecord.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(findRecords.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(findRecords.fulfilled, (state, action) => {
        state.status = 'idle';
        state.list = action.payload.data;
          state.pagination.limit = action.payload.limit;
          state.pagination.skip = action.payload.skip;
          state.pagination.total = action.payload.total;
      })
      .addCase(findRecords.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(createRecord.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createRecord.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(createRecord.fulfilled, (state, action) => {
        state.status = 'idle';
        state.record = action.payload;
      })
      .addCase(updateRecord.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateRecord.fulfilled, (state, action) => {
        state.status = 'idle';
        state.record = action.payload;
      })
      .addCase(updateRecord.rejected, (state) => {
        state.status = 'failed';
      })
  },
});

//export const {  } = formsSlice.actions;

/**
 * Create the Redux Selector to access form state data.
 * @param state RootState
 * @returns Array of all the form schemas
 */
export const selectRecords = (state: RootState) => state.records.list;
export const selectRecord = (state: RootState) => state.records.record;
export const selectRecordsNetStatus = (state: RootState) => state.records.status;
export const selectRecordsPagination = (state:RootState) => state.records.pagination;


export default recordsSlice.reducer;
