import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {  ExtForm  } from '../../app/api/api.types';
import { serializeFeathersError } from '../../app/api/serializeFeathersError';
import { apiGetForms } from '../../app/api/api';


/**
 * Async thunk state
 */
export interface FormState {
  value: Array<ExtForm>;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: FormState = {
  value: [],
  status: 'idle',
};

/**
 * Get all the forms schema's currently supported by the system
 */
export const getForms = createAsyncThunk(
  'forms/get', 
  async ( _ ,  { rejectWithValue } ) => {
    try{
      const response = await apiGetForms();
      return response.data;
    }catch(err){
      const e = serializeFeathersError(err)
      return rejectWithValue(e);
    }
  },
);

/*
export const createForm = createAsyncThunk(
  'forms/create',
  async (record:ExtFormRecord, { rejectWithValue }) => {
    try{
        console.log("Forms Thunk calling submit api");
      const response = await apiCreateRecord(record);
      console.log("Forms Submit Thunk response", response);
      return response.data;
    }catch(err){
      const e = serializeFeathersError(err)
      return rejectWithValue(e);
    }
  }  
)


export const updateForm = createAsyncThunk(
  'forms/update',
  async (record:ExtFormRecord,  { rejectWithValue }) => {
    try{
      console.log("Forms Thunk calling update api");
      const response = await apiUpdateRecord(record);
      console.log("Forms Update Thunk response", response);
      return response.data;
    }catch(err){
      const e = serializeFeathersError(err)
      return rejectWithValue(e);
    }
  }  
)
*/

/**
 * Creates the Redux Slice for the forms
 */
export const formsSlice = createSlice({
  name: 'forms',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getForms.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getForms.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
 //       console.log(action.payload)
      });
  },
});

//export const {  } = formsSlice.actions;

/**
 * Create the Redux Selector to access form state data.
 * @param state RootState
 * @returns Array of all the form schemas
 */
export const selectForms = (state: RootState) => state.forms.value;



// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
/*
export const incrementIfOdd = (amount: number): AppThunk => (
  dispatch,
  getState
) => {
  const currentValue = selectCount(getState());
  if (currentValue % 2 === 1) {
    dispatch(incrementByAmount(amount));
  }
};
*/
export default formsSlice.reducer;
