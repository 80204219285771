import React, {   useEffect, useState } from "react";
import "./Login.scss";
import logo from "../../assets/images/logo_en.png"
import { Button, Form } from "react-bootstrap";
import {useLocation, useNavigate} from "react-router-dom";
import { apiResetPassword } from "../api/api";

const PasswordReset = () => {
  const search = useLocation().search;  
  const navigate = useNavigate();

  //const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [token, setToken] = useState<string|null>("");

  const [errorMsg, setErrorMsg] = useState<string>("");
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  
  const resetPassword= async(e:any)=>{
    e.preventDefault()

    try{
      if (token !== null){
        setIsButtonDisabled(true);
        setErrorMsg('');
        const result = await apiResetPassword({password:password, token: token});
        
        setPassword("");
        console.log(result);

        if (result.status){
          switch(result.status){
            case -3:
              setErrorMsg("Invalid or expired reset code. Please request a new one.")
              return;
            case -2:
              setErrorMsg("Invalid or expired reset code. Please request a new one.")
              return;
            case -1:
              setErrorMsg("Invalid or expired reset code. Please request a new one.")
              return;
          }
        }

        setIsButtonDisabled(false);
        setIsSuccess(true);
      }
    }catch(ex:any){
      console.log("Caught", ex);
      if (ex.code === 400){
        setErrorMsg("Reset link is invalid or expired. Please request a new one.")
      }else{
        setErrorMsg(`Sorry, something went wrong: ${ex.message}`);
      }
      
      setIsButtonDisabled(true);
    }
   
  }
 

  useEffect(()=>{
    
    const t = new URLSearchParams(search).get('token');
    setToken(t);
    console.log('token', t);
  }, [])

  return (
    <div className="page-login">
      <div className="login">
        <img src={logo} alt="PlaySmart" />
        <h1>Responsible Gambling Council Extranet</h1>
        <h3>Set New Password</h3>
        
        { token !== null && token !== 'undefined' ? <div className='reset'>
          {! isSuccess ? <Form onSubmit={resetPassword} >
            <section>
              <Form.Group className="mb-3">
                <Form.Label md={3}>New Password:</Form.Label>
                <Form.Control
                  type="password"
                  id="password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
              {errorMsg !== "" && <p className="error">{errorMsg}</p> }
            </section>
            <div className="d-grid gap-2">
              <Button variant="primary" size="lg" disabled={isButtonDisabled}   type='submit'>
                Update Password
              </Button>
            </div>
          </Form> 
          :
          <div className="success d-grid gap-2">
            <h4>Password updated successsfully!</h4>
            <Button variant='primary' size="lg" onClick={ ()=>navigate('/login') } >Login</Button>  
          </div>
        }
         </div>
        :
          <h4 className='error'>Invalid reset token</h4>
          }
      </div>
    </div>
  );
};

export default PasswordReset;
