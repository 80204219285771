import React, {  useCallback, useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { ExtFormField, ExtFormRecord, ExtOption } from '../../api/api.types';
import { ExtFormFieldError, ExtFormFieldValue } from '../FormRenderer';
import FormFieldChildRenderer from './FormFieldChildRenderer';

const ExtRadio = ({ field, isTabletOrMobile, onChange, existingValue, error, record, getFormFieldError, trackedAtDate }: 
    { 
        field: ExtFormField,
        isTabletOrMobile:boolean, 
        onChange:Function, 
        existingValue:string, 
        error:ExtFormFieldError|undefined ,
        record:ExtFormRecord|undefined,
        getFormFieldError: Function,
        trackedAtDate?:Date
    }) => {
    
    const [currentValue, setCurrentValue] = useState<string>("");

    // If this is the first time we're seeing a value selected:
    const [hasBeenChanged, setHasBeenChanged] = useState<boolean>(false);


    const onChangeCallback = useCallback(args => {
        return onChange(args);
     }, [onChange])
 


    const onRadioClicked = useCallback( (id:string, checked:boolean)=>{
        // Add/remove from our list of selected values:
        if (! hasBeenChanged){
            setHasBeenChanged(true);
        }

        
        const val:ExtFormFieldValue = {
            fid: field.fid,
            field: field,
            dType:  field.dType,
            value: id,
            isError: error?  true : false,
        }
        setCurrentValue(id);
        onChangeCallback(val);
    }, [field, error, hasBeenChanged, onChangeCallback])

    
    useEffect(()=>{
        if (hasBeenChanged){
            // User made a change, don't get caught in a loop
            return;
        }

        if (existingValue === undefined){
            setCurrentValue("");
            return;
        }
        onRadioClicked(existingValue, true);
    }, [existingValue, hasBeenChanged, onRadioClicked])

    
    /**
     * Cleanup effect to remove value for removed fields
     * (e.g. children that are disabled)
     */
     useEffect(()=>{
        return ()=>{
           
            const val:ExtFormFieldValue = {
                fid: field.fid,
                field: field,
                dType:  field.dType,
                value: undefined,
                isError: error?  true : false,
            }
            onChangeCallback(val);
        }
    },[])

    return <Form.Group as={Row} className="mb-3" key={field.fid}>
        <Form.Label column md={3}>{field.required && '*'}{field.label}</Form.Label>
        <Col md={9} key={field.fid} className='radio' >
            {field.options.map( (opt:ExtOption) => {
                return <Form.Check
                    inline
                    type="radio"
                    id={`${field.fid}-${opt.oid}`}
                    label={opt.label}
                    key={opt.oid}
                    name={`${field.fid}-${opt.oid}`}
                    checked={ currentValue === opt.oid }
                    onChange={(e:any)=>{onRadioClicked(opt.oid, e.target.checked)}}
                />
            })}
            {field.hint && <p className='hint'>{field.hint}</p>}
            {error?.fid && <p className='error'>{error?.errorMessage}</p>}
        </Col>
        { field.children && field.children?.length > 0 && 
            <FormFieldChildRenderer 
                children={field.children!} 
                isTabletOrMobile={isTabletOrMobile}
                onChange={onChange}
                record={record} 
                error={error}
                parentValue={currentValue}
                getFormFieldError={getFormFieldError}
                trackedAtDate={ trackedAtDate }
            />  
        }
    </Form.Group>
}

export default ExtRadio;