import React, { useEffect, useState } from 'react';
import { ExtRecordSearchQuery } from '../../features/forms/recoredsSlice';
import ReportListDataTable from "./RecordListDataTable";

const Dashboard = ( {isTabletOrMobile}:{isTabletOrMobile:boolean} ) => {

  const [query, setQuery] = useState<ExtRecordSearchQuery>();

  useEffect( ()=>{
    const now = new Date();
    const day = now.getDate();
    const month = now.getMonth() ;
    const year = now.getFullYear();
    const past = new Date(year, month, day-2, 0, 0, 0);

    const q:ExtRecordSearchQuery = {
      reportedAt:  {$gte: past.toUTCString() }
    }
    setQuery(q);
   
  }, []);



  return (
    <div className='dashboard' >
      <h1>Responsible Gambling Council Extranet</h1>
      <h3>Your feedback is important to us! </h3>
      <p>Please note the 2022 Extranet system is in its first version and will likely see many updates in the near future. 
        Your feedback is valuable and will help shape updates moving forward. </p>
      <p>Please <a className='callout' href='https://www.surveymonkey.com/r/PSCExtranet' target='_blank' rel="noreferrer">submit your feedback here</a>.</p>
      <hr />
      <h2>Recent Reports - Last 48 Hours</h2>
      <ReportListDataTable isTabletOrMobile={isTabletOrMobile} query={query} />
    </div>
  );
}


export default Dashboard;