/**
 * Generate a form slug from the label
 */
 const convertFormLabelToSlug = (label:string):string => {
    let slug = label.toLowerCase();
    slug = slug.replace(' ', '-');

    if (slug.indexOf(' ') > -1){
        return convertFormLabelToSlug(slug);
    }
    return slug;
};

export default convertFormLabelToSlug