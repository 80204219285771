import { ExtPermissions, ExtUser } from "../app/api/api.types"

/**
 * Check if a user has a particular permission:
 */
 const doesUserHavePermission = (user:ExtUser|undefined, permission: ExtPermissions|Array<ExtPermissions>):boolean =>{
    if (user === undefined){
        return false;
    }

    if (Array.isArray(permission)){
        let hasPerm = false;
        permission.forEach(p => {
            const hasIt =  doesUserHavePermission(user, p);
            if (hasIt){
                hasPerm = true;
            }
        })
        return hasPerm;
    }else{
        if (user.permissions.indexOf(permission) > -1){
            return true;
        }
    }

    // If they have admin they get everything:
    if (user.permissions.indexOf(ExtPermissions.isAdmin) > -1 ){
        return true;
    }
    return false;
 }

export default doesUserHavePermission