import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import LocationList from './LocationManagement/LocationList';
import EventTypeList from './EventManagement/EventTypeList';


const EventTypeManager = ({isTabletOrMobile}:{isTabletOrMobile:boolean}) => {
  const [activeTab, setActiveTab] = useState<string>("users");

  useEffect(()=>{
    
  }, [])

  return (
    <div className='location-manager'>
      <h2>Event Type Management</h2>
      <hr/>
      <Tabs defaultActiveKey="users"  className="mb-3"
        activeKey={activeTab}
        onSelect={(k:any) => setActiveTab(k)}
      >
        <Tab eventKey="users" title="Active Event Types">
          {activeTab === 'users' && <EventTypeList activeEvents={true} /> }
        </Tab>
        <Tab eventKey="inactive" title="Inactive Event Types">
          {activeTab === 'inactive' && <EventTypeList activeEvents={false} /> }
        </Tab>
      
       
      </Tabs>
      
    </div>
  );
}


export default EventTypeManager;