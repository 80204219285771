import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {  apiCreateEventType,  apiDeleteEventType,  apiFindEventTypes,  apiGetEventType,  apiPatchEventType } from '../../app/api/api';
import { serializeFeathersError } from '../../app/api/serializeFeathersError';
import { ExtEventType, } from '../../app/api/api.types';



/**
 * Async thunk state
 */
export interface ExtEventTypesState {
  eventTypes: Array<ExtEventType>;
  deleted: Array<ExtEventType>;
  eventType: ExtEventType|undefined,
  status: 'idle' | 'loading' | 'failed';
}

const initialState: ExtEventTypesState = {
  eventTypes: [],
  eventType: undefined,
  deleted: [],
  status: 'idle',
};

/**
 * Get all the active event types
 */
export const findEventTypes = createAsyncThunk(
  'events/find',
  async (query:Object|undefined, { rejectWithValue })  => {
    try{
      const response = await apiFindEventTypes({deletedAt: null} );
      return response.data;
    }catch(err){
      const e = serializeFeathersError(err)
      return rejectWithValue(e);
    }
  }
);


/**
 * Get all the deleted event types
 */
export const findDeletedEventTypes = createAsyncThunk(
  'events/find-deleted',
  async (_, { rejectWithValue })  => {
    try{
      const response = await apiFindEventTypes( {deletedAt: {$ne:null} });
      return response.data;
    }catch(err){
      const e = serializeFeathersError(err)
      return rejectWithValue(e);
    }
  }
);


export const getEventType = createAsyncThunk(
  'events/get',
  async ( id:string, { rejectWithValue }) => {
    try{
      const response = await apiGetEventType(id);
      return response;
    }catch(err){
      const e = serializeFeathersError(err)
      return rejectWithValue(e);
    }
  }
);




/**
 * Create a new event types
 */
export const createEventType = createAsyncThunk(
  'events/create',
  async ( 
    {eventType}:{eventType:ExtEventType}, 
    { rejectWithValue }) => {
    try{
       const response = await apiCreateEventType({eventType:eventType});
      return response.data;
    }catch(err){
      const e = serializeFeathersError(err)
      return rejectWithValue(e);
    }
  }
);


/**
 * Update an existing type
 */
 export const updateEventType = createAsyncThunk(
  'events/update',
  async ( 
    {eventType}:{eventType:ExtEventType}, 
    { rejectWithValue }) => {
    try{
       const response = await apiPatchEventType({eventType:eventType});
      return response.data;
    }catch(err){
      const e = serializeFeathersError(err)
      return rejectWithValue(e);
    }
  }
);


/**
 * Delete an existing event type
 */
export const deleteEventType = createAsyncThunk(
  'events/delete',
  async ( 
    {id}:{id:string}, 
    { rejectWithValue }) => {
    try{
       const response = await apiDeleteEventType({id:id});
      return response.data;
    }catch(err){
      const e = serializeFeathersError(err)
      return rejectWithValue(e);
    }
  }
);




/**
 * Creates the Redux Slice for the forms
 */
export const eventTypeSlice = createSlice({
  name: 'eventTypes',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(findEventTypes.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(findEventTypes.fulfilled, (state, action) => {
        state.status = 'idle';
        state.eventTypes = action.payload;
        state.eventTypes.sort((a, b) =>{
          return (a.label < b.label ? -1 : 1)
        }) ;
      })
      .addCase(findEventTypes.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(findDeletedEventTypes.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(findDeletedEventTypes.fulfilled, (state, action) => {
        state.status = 'idle';
        state.deleted = action.payload;
        state.deleted.sort((a, b) =>{
          return (a.label < b.label ? -1 : 1)
        }) ;
      })
      .addCase(findDeletedEventTypes.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(getEventType.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getEventType.fulfilled, (state, action) => {
        state.status = 'idle';
        state.eventType = action.payload;
      })
      .addCase(getEventType.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(updateEventType.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateEventType.fulfilled, (state, action) => {
        state.status = 'idle';
        state.eventType = action.payload;
      })
      .addCase(updateEventType.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(createEventType.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createEventType.fulfilled, (state, action) => {
        state.status = 'idle';
        state.eventType = action.payload;
      })
      .addCase(createEventType.rejected, (state) => {
        state.status = 'failed';
      })
  },
});


/**
 * Create the Redux Selector to access form state data.
 * @param state RootState
 * @returns Array of all the locations
 */
export const selectEventTypes = (state: RootState) => state.eventTypes.eventTypes;
export const selectEventType = (state: RootState) => state.eventTypes.eventType;
export const selectDeletedEventTypes = (state: RootState) => state.eventTypes.deleted;
export const selectEventTypesNetStatus = (state: RootState) => state.eventTypes.status;




export default eventTypeSlice.reducer;
