import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

/**
 * Search Criteria reflectss the state of the search form.
 * This interface & slice isn't used outside of search.
 */

interface SearchCriteria {
  criteria:{
    _id?:       string,
    reportType: string,
    location?:   string,
    author?:     string,
    fromDate:   string,
    toDate:     string,
  }
}

const initialState: SearchCriteria = {
    criteria: {
        reportType: "ALL",
        location: "ALL",
        author: "ALL",
        fromDate: new Date().toUTCString(),
        toDate: new Date().toUTCString(),
    }
};

/**
 * Creates the Redux Slice for the forms
 */
export const searchSlice = createSlice({
  name: "search",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    updateSearchCriteria: (state, action) => {
        state.criteria = action.payload;
    },
  },

  extraReducers: (builder) => {},
});

export const selectSearchCriteria = (state: RootState) => state.searchCriteria.criteria;

export const { updateSearchCriteria } = searchSlice.actions;
export default searchSlice.reducer;
