import React, {  useEffect } from 'react';
import { selectIsAuthenticated } from '../../features/auth/authSlice';
import { getForms } from '../../features/forms/formsSlice';
import { findLocations } from '../../features/locations/locationsSlice';
import { getStaff } from '../../features/users/staffSlice';
import { useAppDispatch, useAppSelector } from '../hooks';


/**
 * Certain API based activities need to be triggered when the app
 * launches or the authentication state changes. 
 * This component monitors these conditions and dispatches the
 * required events.
 */
const ApiWatchers = () => {
    const isAuthenticated = useAppSelector(selectIsAuthenticated);
    const dispatch = useAppDispatch();
   
    // Load the form data into the local store:
    useEffect(()=>{
        if (isAuthenticated){
            dispatch(getForms());
            dispatch(findLocations());
            dispatch(getStaff() )
    //        apiWatchEventTypes()
        }
    }, [dispatch, isAuthenticated]);
    
  /*
    const apiWatchEventTypes = useCallback(async () => {
        
        const eventTypes = feathersClient.service("event-types");

        try {
            eventTypes.on((et: ExtEventType) => {
                console.log("Got API Watch Events firing")
            // See if the received user matches us, if so update:
            if (isAuthenticated === false){
              return;
            }
            
          });
        } catch (err) {
          throw err;
        }
      }, [isAuthenticated])
*/
  return (<React.Fragment></React.Fragment> );
}

export default ApiWatchers