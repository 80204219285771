/**
 * Form Renderer
 * This is comprised of a series of components that allow for a form
 * to be dynamically generated based on a JSON Schema.
 *
 * The Form Renderer is the top level component that handles all
 * the form logic.
 *
 * Fields common to all forms are rendered above the dynamic part
 * so that their fields can be tracked "above" those of the dynamic
 * elements in the submitted Record.data[] structure.
 *
 * The Form Section Renderer iterates through the form.sections
 * and is the first level of dynamic rendering. In turn it renders a
 * seris of Form Field Renderers.
 *
 * The Form Field Renderer renders the actual HTML controls (labels,
 * input, radio, checkbox, etc).
 *
 * Form Fields can have children, essentially sub-forms, which start
 * at the section level of a form schema.
 * I.e. the Form Field Child Renderer checks for children and their
 * visibility condition and renders children which use the same
 * structure as a form.section. Form.chilren is Array<ExtFormSection>.
 *
 * If we load an existing Form Record into the form, this gets populated
 * as the record property passed down. Each component will set its own
 * data.
 *
 * When components are updated they call the onFormElementValueChanged
 * function to set their state within the form (which is tracked here).
 *
 * Form validation is also handled in the FormRenderer, along with API
 * Redux thunk integration. Components only handle their individual state.
 */

import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  ExtForm,
  ExtFormSection,
  ExtFormField,
  ExtFormRecord,
  ExtFormRecordData,
  ExtDataTypes,
  ExtLocation,
  ExtFormFieldChild,
  ExpFormFieldChildCondtionTypes,
  ExtPermissions,
} from "../api/api.types";
import { selectForms } from "../../features/forms/formsSlice";
import { selectLocations } from "../../features/locations/locationsSlice";
import {
  createRecord,
  deleteRecord,
  getRecord,
  selectRecord,
  selectRecordsNetStatus,
  updateRecord,
} from "../../features/forms/recoredsSlice";
import { useAppDispatch, useAppSelector } from "../hooks";
import "./FormRenderer.scss";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ca from "date-fns/locale/ca";
import { useNavigate } from "react-router-dom";
import { showNotification } from "../../features/notifications/notificationSlice";
import scrollToTop from "../../utils/scrollTop";
import { selectAuthUser } from "../../features/auth/authSlice";
import convertFormLabelToSlug from "../../utils/convertLabelToSlug";
import FormSectionRenderer from "./FormRenderingComponents/FormSectionRenderer";
import doesUserHavePermission from "../../utils/doesUserHavePermission";

registerLocale("ca-en", ca);

/**
 * The data structure of the form field values when
 * individual controls pass them up to this level
 * for compilation int the formData array.
 * (Not included in the API as this stucture
 * is never passed over the network)
 */
export interface ExtFormFieldValue {
  fid: string;
  group?: string;
  dType: ExtDataTypes;
  field?: ExtFormField;
  value: unknown;
  optValue?: number;
  isError: boolean;
}


export interface ExtFormFieldError {
  fid: string;
  errorMessage: ExtFormFieldErrorMessages;
}

export enum ExtFormFieldErrorMessages {
  required = "* Required",
}

/***
 * Form Renderer
 * Dynamically renders a report form based on Form data provided by the API.
 */
const FormRenderer = ({ isTabletOrMobile }: { isTabletOrMobile: boolean }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const forms = useAppSelector(selectForms);
  const locations = useAppSelector(selectLocations);
  const record = useAppSelector(selectRecord);
  const authUser = useAppSelector(selectAuthUser);

  const recordStatus = useAppSelector(selectRecordsNetStatus);

  const { slug } = useParams();
  const { id } = useParams();

  const [form, setForm] = useState<ExtForm>();
  const [trackedAtDate, setTrackedAtDate] = useState<Date>(new Date());
  const [selectedLocation, setSelectedLocation] = useState<string>("");

  const [formData, setFormData] = useState<Array<ExtFormFieldValue>>([]);
  const formDataRef = useRef(formData);

  const [isNetwork, setIsNetwork] = useState<boolean>(false);

  const [loadedRecord, setLoadedRecord] = useState<ExtFormRecord | undefined>();

  const [fieldsWithError, setFieldsWithError] = useState<
    Array<ExtFormFieldError>
  >([]);
  const fieldsWithErrorRef = useRef(fieldsWithError);

  const [isLocationError, setIsLocationError] = useState<boolean>(false);
  const [isDateError, setIsDateError] = useState<boolean>(false);

  const [userLocations, setUserLocations] = useState<Array<ExtLocation>>([]);

  const [isReadOnly, setIsReadOnly] = useState<boolean>(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
  const [canUserEditForms, setCanUserEditForms] = useState<boolean>(false);
  const [canUserDeleteForms, setCanUserDeleteForms] = useState<boolean>(false);

  const [currentFormType, setCurrentFormType] = useState<string>("");
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState<boolean>(false);

  /**
   * Erase any values in the form:
   */
  const wipeFormData = useCallback(() => {
    // console.log("* Wipe form called *")
    setCurrentFormType("");
    setLoadedRecord(undefined);
    setSelectedLocation("");
    if (authUser) {
      setSelectedLocation(authUser.defaultLocation);
    }
    setTrackedAtDate(new Date());
    setFormData([]);
    formDataRef.current = formData;
    setIsReadOnly(false);
  }, [authUser]);

  /**
   * Update the tracking date for the record
   * @param val Date
   */
  const onDateChange = (val: Date) => {
    if (val === null) {
      return;
    }
    setTrackedAtDate(val);
  };

  /**
   * When a child form controls value is modified this
   * passed function is called to track the overall state.
   * @param val
   */
  const onFormElementValueChanged = (val: ExtFormFieldValue) => {
    //const newFormData = [...formData];
    const idx = formDataRef.current.findIndex(
      (obj: ExtFormFieldValue) => obj.fid === val.fid
    );

    if (val.value === undefined) {
      // Remove the value if it exists:
      if (idx > -1) {
        formDataRef.current.splice(idx, 1);
      }
    } else {
      // Add or update the value:
      if (idx > -1) {
        // replace the value:
        formDataRef.current[idx] = val;
      } else {
        formDataRef.current.push(val);
      }
    }

    // Remove the error if applicable:
    const errIdx = fieldsWithError.findIndex((err: ExtFormFieldError) => {
      return err.fid === val.fid;
    });

    if (errIdx > -1) {
      const newList = [...fieldsWithError];
      newList.splice(errIdx, 1);
      setFieldsWithError(newList);
    }

    setFormData(formDataRef.current);
  };

  /**
   * Form submit button was clicked on,
   * save the changes (create or update)
   */
  const onSubmitClicked = async () => {
    setFieldsWithError([]);

    if (!validateForm()) {
      scrollToTop();
      return;
    }

    const success = await saveForm(false);
    if (success) {
      dispatch(
        showNotification({
          header: "Success!",
          body: "Your new record was created succssfully",
          type: "success",
        })
      );

      wipeFormData();

      navigate("/");
    }
  };

  /**
   * Submit a form but keep the existing values
   * as the basis for a new form.
   */
  const onSubmitDuplicateClicked = async () => {
    if (!validateForm()) {
      scrollToTop();
      return;
    }

    const success = await saveForm(false);
    if (success) {
      dispatch(
        showNotification({
          header: "Success!",
          body: "Your new record was created successfully",
          type: "success",
        })
      );
    }
  };

  /**
   * Update the existing record with new value(s)
   */
  const onUpdateClicked = async () => {
    if (!validateForm()) {
      scrollToTop();
      return;
    }

    const success = await saveForm(true);
    if (success) {
      dispatch(
        showNotification({
          header: "Success!",
          body: "Your record was updated successfully",
          type: "success",
        })
      );
    }
  };

  const onDeleteClicked = () => {
    setIsConfirmDeleteOpen(true);
  };

  const closeConfirmDelete = async (doDelete:boolean)=>{
    setIsConfirmDeleteOpen(false);

    if (!doDelete){
      return;
    }


    const rec: ExtFormRecord = {
      _id: record?._id,
      formId: form!.formId,
      locationId: selectedLocation,
      reportedAt: trackedAtDate.toISOString(),
      data: [],
    };

    let result: any;

    result = await dispatch(deleteRecord(rec));

    if (result.payload.code) {
      dispatch(
        showNotification({
          header: "Delete Failed...",
          body: result.payload.message,
          type: "error",
        })
      );
      return false;
    }else{
      dispatch(
        showNotification({
          header: "Success!",
          body: "Form Record was deleted.",
          type: "success",
        })
      );

      wipeFormData();

      navigate("/");
    }
    
    
  }

  /**
   * Remove fields that aren't enabled in our current date rage:
   * @param form Form Data
   * @param trackedAtDate Date of the form
   * @returns Form
   */
  const RemoveDisabledFormElements = (
    form: ExtForm,
    trackedAtDate?: Date
  ): ExtForm => {
    if (trackedAtDate === undefined) {
      return form;
    }

    
    // Check to see if there are fields we need to remove
    // from the original form data based on enabled dates:
    let updateForm = { ...form };

    for (let i = updateForm.sections.length - 1; i > 0; i--) {
      let section = updateForm.sections[i];

      if (section.enabledDates === undefined) {
        continue;
      }
      const past = new Date(section.enabledDates.startDate);
      const future = new Date(section.enabledDates.endDate);
      if (trackedAtDate >= past && trackedAtDate < future) {
        // All Good, keep as is
      } else {
        // Remove the section from the form:

        let list = [...updateForm.sections];
        list.splice(i, 1);
        updateForm.sections = list;
        continue;
      }
    }

    return updateForm;
  };

  /**
   * Sets the active form for rendering
   * @param form Form Data
   */
  const setActiveForm = (form: ExtForm, trackedAtDate?: Date) => {
    const formData = RemoveDisabledFormElements(form, trackedAtDate);
    setForm(formData);
    setCurrentFormType(formData.formId);
  };

  /**
   * Use references so we can access the data immediately,
   * not after a React update (can be important with child
   * components sending state "up" to the form)
   */
  useEffect(() => {
    formDataRef.current = formData;
  }, [formData]);

  useEffect(() => {
    fieldsWithErrorRef.current = fieldsWithError;
  }, [fieldsWithError]);

  const setDefinedForm = (trackedAtDate?: Date) => {
    forms.forEach((f: ExtForm) => {
      // Set the active form based on the URL slug
      let fslug = convertFormLabelToSlug(f.label);
      if (fslug === slug) {
        setActiveForm({ ...f }, trackedAtDate);
      }
    });
  };

  /**
   * Use the slug in the URL to lookup the correct
   * form, then set it as the active one for rendering.
   */
  useEffect(() => {
    setDefinedForm();
  }, [forms, slug]);

  /**
   * Filter the overall location list to just those
   * available to the logged in user
   */
  useEffect(() => {
    if (authUser === undefined) {
      return;
    }

    const locs = locations.filter((loc: ExtLocation) => {
      return authUser.locations.indexOf(loc._id) > -1;
    });

    setUserLocations(locs);

    // Set the default
    if (authUser.defaultLocation !== undefined) {
      setSelectedLocation(authUser.defaultLocation);
      //    console.log("Selected location set", authUser.defaultLocation);
    }
  }, [authUser, locations]);


  const delay = (ms:number) => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  /**
   * Check to see if an :id exists in the URL path.
   * If so, load the record data into the form.
   */
  useEffect(() => {
    wipeFormData();

    if (id === undefined) {
      return;
    }

    // Load the form data:
    const loadData = async() => {
      await delay(600);
      dispatch(getRecord(id));
    }
    loadData()
  }, [id, dispatch, wipeFormData]);

  /**
   * If an existing form record is being loaded
   * (record isn't undefined), set the form state
   * to match the loaded data.
   */
  useEffect(() => {
    if (id === undefined) {
      // Slice State can contain the last worked on form
      // so if there's no ID, nothing to render
      return;
    }

    if (record === undefined) {
      // We tried to load an existing form, but either
      // it doesn't exist or we don't have access to it.
      // The recordStatus useEffect handles this scenario.
      return;
    }

    // All good, popuplate the form:
    if (record?.location !== undefined) {
      setSelectedLocation(record.location.lid);
      setTrackedAtDate(new Date(record.reportedAt));
    }

    // Make document editable if either has manager permissions or is author
    if (
      doesUserHavePermission(authUser, ExtPermissions.canManageForms) ||
      record.createdBy!.id === authUser!._id
    ) {
      setCanUserEditForms(true);
    }

    if (doesUserHavePermission(authUser, ExtPermissions.canDeleteForms)) {
      setCanUserDeleteForms(true);
    }

    setIsReadOnly(true);
    setLoadedRecord(record);
  }, [authUser, record, id]);

  /**
   * If our attempt to load a form fails show an error
   */
  useEffect(() => {
    if (recordStatus === "failed" && isFormSubmitting === false) {
      dispatch(
        showNotification({
          header: "Form does not exist!",
          body: "The form you're attempting to access does not exist, or you do not have permission to access it.",
          type: "error",
        })
      );

      wipeFormData();
      navigate("/");
    }
  }, [recordStatus, isFormSubmitting, dispatch, navigate, wipeFormData]);

  /**
   * Cleanup when leaving a form:
   */
  useEffect(() => {
    return () => {
      //    console.log("Cleanup calling")
      wipeFormData();
    };
  }, [wipeFormData]);

  /**
   * If the form type changes wipe the existing data.
   * This clears out partially completed, but not submitted,
   * form data when the form is changed.
   */
  useEffect(() => {
    wipeFormData();
  }, [currentFormType, wipeFormData]);

  /**
   * Validate if the form is valid
   * (Currently checking for Required only)
   * @returns boolean Succes/Fail
   */
  const validateForm = (): boolean => {
    // Check that we have a value for all required fields:
    let isError = false;
    setIsLocationError(false);
    setIsDateError(false);

    const errorList: Array<ExtFormFieldError> = [];

    if (form) {
      isError = validateSections(form.sections, errorList);
    }

    errorList.push(...fieldsWithError);

    // Check the common fields a the top of the form:
    if (selectedLocation === "") {
      setIsLocationError(true);
      isError = true;
    }

    if (trackedAtDate === null) {
      setIsDateError(true);
      isError = true;
    }

    setFieldsWithError(errorList);

    if (isError) {
      dispatch(
        showNotification({
          header: "Validation errors...",
          body: "Please correct the validation errors and try submitting again.",
          type: "error",
        })
      );
    }

    return !isError;
  };

  /***
   * Recursively walks the sections.fields, section.fields.children
   * path looking for fields that have Required validation enabled.
   */
  const validateSections = (
    sections: Array<ExtFormSection>,
    errorList: Array<ExtFormFieldError>
  ): boolean => {
    let isError = false;
    let isChildError = false;
    sections.forEach((section: ExtFormSection) => {
      // See if the section is hidden by date limits:
      let isSectionHidden = false;
      if (section.enabledDates !== undefined && trackedAtDate !== undefined) {
        const past = new Date(section.enabledDates.startDate);
        const future = new Date(section.enabledDates.endDate);
        if (trackedAtDate >= past && trackedAtDate < future) {
          // All good
        } else {
          isSectionHidden = true;
        }
      }

      if (!isSectionHidden) {
        section.fields.forEach((field: ExtFormField) => {
          let isFieldHidden = false;

          //See if the field is hidden:
          if (field.enabledDates !== undefined && trackedAtDate !== undefined) {
            const past = new Date(field.enabledDates.startDate);
            const future = new Date(field.enabledDates.endDate);
            if (trackedAtDate >= past && trackedAtDate < future) {
              // All good, between dates
            } else {
              isFieldHidden = true;
            }
          }

          if (field.required && !isFieldHidden) {
            // Make sure we have a value for this field:
            const hasValue = doesRecordHaveValueForField(field);
            if (!hasValue) {
              errorList.push({
                fid: field.fid,
                errorMessage: ExtFormFieldErrorMessages.required,
              });
              isError = true;
            }
          }

          // Parse any required fields for children... if they are active
          field.children?.forEach((child: ExtFormFieldChild) => {
            const isVisible = isChildVisible(field, child);
            if (isVisible) {
              // Check it for required:
              const isErr = validateSections(child.sections, errorList);
              if (isErr) {
                // If there isn't an error in a given section,
                // we don't want to overwrite this
                isChildError = true;
              }
            }
          });
        });
      }
    });
    return isError || isChildError;
  };

  /**
   * Children appear conditionally. This validates if a particular child
   * is visible or not.
   * @param field The parent field
   * @param child The child property
   * @returns boolean if the child can be seen
   */
  const isChildVisible = (
    field: ExtFormField,
    child: ExtFormFieldChild
  ): boolean => {
    const fieldData = formData.find((val: ExtFormFieldValue) => {
      return val.fid === field.fid;
    });
    if (fieldData === undefined) {
      // Parent Field not located, so child can't be visible
      return false;
    }
    const fieldValue = fieldData.value;

    // If the child is date-ranged, skip any further checking:
    if (child.enabledDates !== undefined) {
      const past = new Date(child.enabledDates.startDate);
      const future = new Date(child.enabledDates.endDate);
      if (trackedAtDate >= past && trackedAtDate < future) {
        // All good, between dates
      } else {
        return false;
      }
    }

    switch (child.conditionType) {
      case ExpFormFieldChildCondtionTypes.Equals:
        if (Array.isArray(fieldValue)) {
          return fieldValue.indexOf(child.conditionValue) > -1;
        }
        return fieldValue === child.conditionValue;
      default:
        return false;
    }
  };

  /***
   * Check to see if the passed field has a value in the record
   * @param ExtFormSectionField The field to validate
   * @returns boolean Does the record value exist?
   */
  const doesRecordHaveValueForField = (field: ExtFormField): boolean => {
    const idx = formData.findIndex((data) => data.fid === field.fid);
    if (idx > -1) {
      const val = formData[idx].value;
      if (Array.isArray(val)) {
        if (val.length === 0) {
          // An empty array counts as no value.
          return false;
        }
      }
      if (val === undefined || val === "") {
        return false;
      }
    } else {
      return false;
    }
    return true;
  };

  /**
   * Create or update a record for this form
   * @param isUpdate Is this an update to an existing record?
   */
  const saveForm = async (isUpdate: boolean) => {
    setIsNetwork(true);
    setIsFormSubmitting(true);

    let formRecords: Array<ExtFormRecordData> = [];

    formData.forEach((rec) => {
      if (rec.fid.indexOf(form!.formId) === -1) {
        console.warn("Found old form data, skipping: ", rec);
      } else {
        const newRec: ExtFormRecordData = {
          fid: rec.fid,
          dType: rec.dType,
          value: rec.value,
        };
        if (rec.optValue !== undefined) {
          newRec.optValue = rec.optValue;
        }
        formRecords.push(newRec);
      }
    });

    const rec: ExtFormRecord = {
      _id: record?._id,
      formId: form!.formId,
      locationId: selectedLocation,
      reportedAt: trackedAtDate.toISOString(),
      data: formRecords,
    };

    let result: any;
    if (!isUpdate) {
      result = await dispatch(createRecord(rec));
    } else {
      result = await dispatch(updateRecord(rec));
    }

    scrollToTop();
    setIsNetwork(false);

    if (result.payload.code) {
      dispatch(
        showNotification({
          header: "Something went wrong...",
          body: result.payload.message,
          type: "error",
        })
      );
      return false;
    }

    return true;
  };

  /**
   * Form fields call this function to see if they should be
   * considered in an error state.
   *
   * @param field The form field "asking"
   * @returns
   */
  const getFormFieldError = (
    field: ExtFormField
  ): ExtFormFieldError | undefined => {
    // Pass the error object down if this field has an error
    const idx = fieldsWithError.findIndex((f) => f.fid === field.fid);
    if (idx > -1) {
      return fieldsWithError[idx];
    }
    return undefined;
  };

  return (
    <Container className="input-form" fluid="xl">
      <h2>{form?.title}</h2>
      {loadedRecord !== undefined && canUserEditForms && (
        <Form.Check
          className="edit-switch"
          type={"switch"}
          id="isEditable"
          label="Edit"
          key="edit"
          checked={!isReadOnly}
          onChange={(e: any) => {
            setIsReadOnly(!e.target.checked);
          }}
        />
      )}
      <p>{form?.description}</p>
      <p>* Indicates a required field.</p>

      {loadedRecord?._id && <h3>Form ID: {loadedRecord?.internalId}</h3>}
      <Form className="ext-form">
        {
          // Render the constant fields for all forms (location & date).
          // These are broken out as they're set at the top-level of the
          // response data rather than within the .data property.
        }
        <fieldset disabled={isReadOnly}>
          <section className="input-section">
            <h4>Location</h4>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column md={3}>
                * PlaySmart Site:
              </Form.Label>
              <Col md={9}>
                <Form.Select
                  aria-label="PlaySmart Centre Site"
                  value={
                    selectedLocation === ""
                      ? authUser?.defaultLocation
                      : selectedLocation
                  }
                  onChange={(e) => setSelectedLocation(e.target.value)}
                >
                  <option value="">Select</option>
                  {id === undefined
                    ? userLocations.map((loc: ExtLocation) => {
                        return (
                          <option key={loc._id} value={loc._id}>
                            {loc.name}
                          </option>
                        );
                      })
                    : locations.map((loc: ExtLocation) => {
                        return (
                          <option key={loc._id} value={loc._id}>
                            {loc.name}
                          </option>
                        );
                      })}
                </Form.Select>
                {isLocationError && (
                  <p className="error">{ExtFormFieldErrorMessages.required}</p>
                )}
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column md={3}>
                * Date:
              </Form.Label>
              <Col md={9}>
                <DatePicker
                  disabled={isReadOnly}
                  dateFormat={"yyyy/MM/dd"}
                  selected={trackedAtDate}
                  onChange={onDateChange} //only when value has changed
                />
                {isDateError && (
                  <p className="error">{ExtFormFieldErrorMessages.required}</p>
                )}
              </Col>
            </Form.Group>
          </section>
          {
            /// Render the dynamic sections of the form:
          }

          {form?.sections.map((section: ExtFormSection) => {
            return (
              <FormSectionRenderer
                key={section.sid}
                section={section}
                record={loadedRecord}
                onChange={onFormElementValueChanged}
                getFormFieldError={getFormFieldError}
                trackedAtDate={trackedAtDate}
              />
            );
          })}

          {!isReadOnly && (
            <div className="form-end">
              {loadedRecord !== undefined ? (
                <section className="form-controls">
                  {canUserDeleteForms && (
                <Button
                  variant="danger"
                  className="delete"
                  onClick={onDeleteClicked}
                  disabled={isNetwork}
                >
                  Delete
                </Button>
              )}

                  <Button
                    variant="primary"
                    className="float-right"
                    onClick={onUpdateClicked}
                    disabled={isNetwork}
                  >
                    {isNetwork ? "Saving..." : "Update"}
                  </Button>
                </section>
              ) : (
                <section className="form-controls">
                  <Button
                    variant="warning"
                    className="float-right"
                    onClick={onSubmitDuplicateClicked}
                    disabled={isNetwork}
                  >
                    {isNetwork ? "Saving..." : "Submit & Duplicate"}
                  </Button>
                  <Button
                    variant="primary"
                    className="float-right"
                    onClick={onSubmitClicked}
                    disabled={isNetwork}
                  >
                    {isNetwork ? "Saving..." : "Submit"}
                  </Button>
                </section>
              )}
            </div>
          )}
        </fieldset>
      </Form>

      <Modal show={isConfirmDeleteOpen} onHide={()=>closeConfirmDelete(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Form Record?</Modal.Title>
        </Modal.Header>
        <Modal.Body>This cannot be undone, all data will be lost! Are you sure?</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={()=>closeConfirmDelete(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={()=>closeConfirmDelete(true)}>
            DELETE
          </Button>
        </Modal.Footer>
      </Modal>

    </Container>
  );
};

export default FormRenderer;
