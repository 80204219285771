import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import LocationList from './LocationManagement/LocationList';


const LocationManager = ({isTabletOrMobile}:{isTabletOrMobile:boolean}) => {
  const [activeTab, setActiveTab] = useState<string>("users");

  useEffect(()=>{
    console.log("Init fired")
  }, [])

  return (
    <div className='location-manager'>
      <h2>Location Management</h2>
      <hr/>
      <Tabs defaultActiveKey="users"  className="mb-3"
        activeKey={activeTab}
        onSelect={(k:any) => setActiveTab(k)}
      >
        <Tab eventKey="users" title="Active Locations">
          {activeTab === 'users' && <LocationList activeLocations={true} /> }
        </Tab>
        <Tab eventKey="inactive" title="Inactive Locations">
          {activeTab === 'inactive' && <LocationList activeLocations={false} /> }
        </Tab>
      
       
      </Tabs>
      
    </div>
  );
}


export default LocationManager;