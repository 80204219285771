/**
 * Form represents and entire submission form schema
 */
 export interface ExtForm{
    _id:        string,
    formId:     string,
    weight:     number,
    title:      string,
    label:      string,
    description:string,
    enabled:    boolean,
    sections:   Array<ExtFormSection>,
    createdAt:  Date,
    updatedAt:  Date,
}

/**
 * Sections are the parts of a form, eg
 * Patron Visitor Profile, Services Provided, etc.
 */
export interface ExtFormSection {
  sid:      string,
  title:    string,
  fields:   Array<ExtFormField>,
  showTitle: boolean,
  enabledDates?: {
    startDate: Date,
    endDate: Date
  },
}



export interface ExtBaseFormField{
  fid:        string,
  label:      string,
  control:    ExtControlTypes,
  dType:      ExtDataTypes,
  required:   Boolean,
  enabledDates?: {
    startDate: Date,
    endDate: Date
  },
  permissions?: Array<ExtPermissions>,
}


/**
 * Fields are the individual questions on the form.
 */
export interface ExtFormField extends ExtBaseFormField {
  hint?:      string,
  options:    Array<ExtOption>,
  optionsRef?:  {
    service:  string,
    value:    string,
    label:    string|Array<string>,
    filter: {
      field:      string,
      condition:  string,
      value:      string
    }
  }
  children?:  Array<ExtFormFieldChild>
  durationStart: ExtBaseFormField,
  durationEnd: ExtBaseFormField,
  
}


/**
 * Selected values distilled to a key/value pair
 */
export interface ExtFieldValueSimple{
  fid: string,
  value: any,
}



/**
 * Control types reflect the HTML control used to
 * input data for this field.
 */
 export enum ExtControlTypes {
    Text =      'TEXT',
    TextField = 'TEXTAREA',
    Number =    'NUMBER',
    CheckBox =  'CHECK',
    Select =    'SELECT', 
    Radio =     'RADIO',
    DateTime =  'DATETIME',
    Date =      'DATE',
    Duration =  'DURATION'
  }
  

/**
 * Data types reflect the expected field input type.
 */
export enum ExtDataTypes {
    String =    'STRING',
    Number =    'NUMBER',
    Bool =      'BOOL',
    ArrString = 'ARRSTRING',
    DateTime =  'DATETIME',
    Date =      'DATE',
    Duration =  'DURATION',
    ArrObjectId = 'ARROBJECTID',
    ObjectId =  'OBJECTID'
}


/**
 * Options are used in radio/checkbox or drop-down select lists.
 */
export interface ExtOption {
  oid:    string,
  label:  string,
  value: any,
  enabledDates?: {
    startDate: Date,
    endDate: Date
  },
  deletedAt?: Date,
  permissions?: Array<ExtPermissions>,
}


/**
 * Represents a submitted form record and its values
 * When submitting a record, a locationId is passed.
 * When loading a record, a locationRef is received.
 * 
 * Optional properties are not set by the front-end,
 * but are set by the back-end.
 */
export interface ExtFormRecord{
  _id?:         string,
  internalId?:  number,
  formId:       string, 
  label?:       string,
  createdBy?:    ExtUserRef,
  reviewedBy?:  string,
  locationId?:  string,
  location?:    ExtLocationRef,
  reportedAt:   string,
  status?:      string,
  data:         Array<ExtFormRecordData>,

  createdAt?:   Date,
  updatedAt?:   Date,    
}


/**
 * The individual form field values as found in a populated form (record).
 * Some controls, like time ranges, need an additional numeric value of summing
 */
 export interface ExtFormRecordData {
  fid:    string,
  dType:  ExtDataTypes,
  value:  any,
  optValue?: any, 
}


/**
 * Duration data are unusual as they are comprised of two distinct values
 */
 export interface ExtFormRecordDataDuration {
  durationStart: {
    fid:    string,
    dType:  ExtDataTypes,
    value:  Date,
  },
  durationEnd: {
    fid:    string,
    dType:  ExtDataTypes,
    value:  Date,
  }
  diffMin: Number,
}



/**
 * Region definition
 */
export enum ExtRegions {
  CENTRAL_NORTH = 'CENTRAL_NORTH',
  EAST =          'EAST',
  SOUTH_EAST =    'SOUTH_EAST',
  SOUTH_WEST =    'SOUTH_WEST',
  SOUTH      =    'SOUTH',
  CENTRAL =       'CENTRAL',
}


export interface ExtRegion {
  rid:  string,
  name: string,
}

export interface ExtLineOfBusiness {
  lid: string,
  name: string
}

export const ExtRegionList:Array<ExtRegion> = [
  {
      rid: ExtRegions.CENTRAL,
      name: 'Central'
  },
  {
      rid: ExtRegions.CENTRAL_NORTH,
      name: 'Central North'
  },
  {
      rid: ExtRegions.EAST,
      name: 'East'
  },
  {
    rid: ExtRegions.SOUTH,
    name: 'South'
},
  {
      rid: ExtRegions.SOUTH_EAST,
      name: 'South East'
  },
  {
      rid: ExtRegions.SOUTH_WEST,
      name: 'South West'
  },
];



export const LineOfBusinessList:Array<ExtLineOfBusiness> = [
  {
      lid: "GAMING",
      name: "Casino",
  },
  {
      lid: "CGAMING",
      name: "Bingo",
  }
]



/**
 * Location structure
 */
 export interface ExtLocation{
    _id:        string,
    name:       string,
    margi:      string,
    address1:   string,
    address2:   string,
    city:       string,
    province:   string,
    phone:      string,
    region:    string,
    lob:        string,
    deletedAt?: Date,
}


/**
 * Extranet User
 */
export interface ExtUser {
  _id?:       string,
  email:      string,
  password?:  string,
  firstName:  string,
  lastName:   string,
  active:     boolean,
  lastLoginAt?:     Date,
  defaultLocation:  string,
  region:     string,
  locations:  Array<string>,
  permissions:Array<string>,
  createdBy?: {
    id:       string,
    name:     string,
  },
  createdAt?: Date,
  updatedAt?: Date,
}


/**
 * Extranet Permissions
 */
 export enum ExtPermissions {
  isActive            = 'ACTIVE',
  
  canSubmitForms      = 'FORMS_CREATE',
  canViewOthersForms  = 'FORMS_VIEW_OTHERS',
  canManageForms      = 'FORMS_MANAGE',
  canDeleteForms      = 'FORMS_DELETE',
  canCreateReports    = 'REPORTS_CREATE',

  canManageUsers      = 'USERS_MANAGE',
  canAddUsers         = 'USERS_ADD',

  canManageEventTypes = "EVENT_TYPES_MANAGE",


  canManageLocations  = 'LOCATIONS_MANAGE',
  canCallPostFields   = 'CALL_POST',
  
  isAdmin             = 'ADMIN',
}




/**
 * Structure for referencing a user in another document
 */
 export interface ExtUserRef{
  id:         string,
  firstName:  string,
  lastName:   string,
}




/**
 * Stucture for referencing a location in another doc
 */
 export interface ExtLocationRef {
  lid:   string,
  name: string,
}


/**
 * Structure for an Audience label
 */
export interface ExtAudienceType {
  // eventId:    string,
  audienceId: string,
  label:      string,
  //enabled?:    boolean,
  createdAt?: Date,
  updatedAt?: Date,
  deletedAt?: Date,
}


export const ExtAudienceTypes:Array<ExtAudienceType> = [
  {
    audienceId: "AUDIENCE-PATRONS",
    label: "Patrons",
    
  },{
    audienceId: "AUDIENCE-STAFF_CASINO_BINGO",
    label: "Gaming Staff - Casino or Bingo",

  },{
    audienceId: "AUDIENCE-STAFF_HORSERACING",
    label: "Gaming Staff - Horse Racing",
  },{
    audienceId: "AUDIENCE-OTHER",
    label: "Other",
  }
] 



/**
 * Form fields with conditional children:
 */
 export interface ExtFormFieldChild {
  conditionType:  ExpFormFieldChildCondtionTypes,
  conditionValue: any,
  sections:       Array<ExtFormSection>
  enabledDates?: {
    startDate: Date,
    endDate: Date
  },
}



/**
 * Types of form field conditional checks
 */
export enum ExpFormFieldChildCondtionTypes {
  Equals =  'EQUALS',
}






/**
 * Structure for an Event
 */
 export interface ExtEventType {
  _id?:        string,
  eventId:    string,
  audienceId: string,
  label:      string,
  //enabled:    boolean,
  createdAt?: Date,
  updatedAt?: Date,
  deletedAt?: Date,
}


