/***
 * Form Field Child Renderer
 * 
 * Selecting certain values on some fields causes
 * additional form fields to appear. 
 * These additional fields are children of the field.
 * 
 */
import React from 'react';
import { ExpFormFieldChildCondtionTypes,  ExtFormFieldChild, ExtFormRecord, ExtFormSection } from '../../api/api.types';
import { ExtFormFieldError } from '../FormRenderer';
import FormSectionRenderer from './FormSectionRenderer';




const FormFieldChildRenderer = ({ children, onChange, record, error, parentValue, isTabletOrMobile, getFormFieldError, trackedAtDate }: 
    { children: Array<ExtFormFieldChild>, 
        onChange:Function, 
        record:ExtFormRecord|undefined, 
        error:ExtFormFieldError|undefined,
        parentValue:any,
        isTabletOrMobile:boolean,
        getFormFieldError:Function,    
        trackedAtDate?: Date,
    }) => {

   
    const renderChild = ((child:ExtFormFieldChild)=>{
        let isChildVisible = true;

        if (child.enabledDates === undefined || trackedAtDate === undefined){
           // Do nothing
        }else{
            const past = new Date(child.enabledDates.startDate);
            const future = new Date(child.enabledDates.endDate);
            if (trackedAtDate >= past && trackedAtDate < future){
                isChildVisible = true;
            }else{
             //   isChildVisible = false;
                return "";
            }
        }
        
        switch (child.conditionType){
            case ExpFormFieldChildCondtionTypes.Equals:
                if (Array.isArray(parentValue) ){
                    // If it's an array see if the array _contains_ the condition value:
                    if (parentValue.indexOf(child.conditionValue) > -1){
                        return child.sections.map( (section:ExtFormSection) => {
                            return <FormSectionRenderer
                                key={section.sid} 
                                section={section} 
                                record={record} 
                                onChange={onChange} 
                                getFormFieldError={getFormFieldError} 
                                trackedAtDate={trackedAtDate}
                            />
                        })
                    }
                }else{
                    // Treat it as a primitive, eg. string or number
                    if (child.conditionValue === parentValue){
                        return child.sections.map( (section:ExtFormSection) => {
                            return <FormSectionRenderer
                                key={section.sid} 
                                section={section} 
                                record={record} 
                                onChange={onChange} 
                                getFormFieldError={getFormFieldError} 
                                trackedAtDate={trackedAtDate}
                            />
                        })
                    }
                }
            break;
            default:
                throw new Error(`Child data contains non-implemented condition: ${child.conditionType}`);
        }
     })

    return <React.Fragment>
        <div className='child row'>
        { children.map((child:ExtFormFieldChild)=>{
            return renderChild(child);
        })}
        </div>
    </React.Fragment>
}

export default FormFieldChildRenderer;