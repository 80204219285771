/**
 * Form Field Renderer
 * 
 * Fields comprise the individual HTML controls
 * as found on a generated form.
 * Examples include Labels, Select, Radio, Check, etc.
 */
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive'
import ExtCheckBox from './ExtCheckBox';
import ExtSelect from './ExtSelect';
import ExtRadio from './ExtRadio';
import { ExtControlTypes, ExtFormField, ExtFormRecord, ExtFormRecordData } from '../../api/api.types';
import { ExtFormFieldError } from '../FormRenderer';
import ExtTextInput from './ExtTextInput';
import ExtDateTime from './ExtDateTime';
import ExtDuration from './ExtDuration';
import doesUserHavePermission from '../../../utils/doesUserHavePermission';
import { useAppSelector } from '../../hooks';
import { selectAuthUser } from '../../../features/auth/authSlice';





const FormFieldRenderer = ({ field, onChange, record, error, getFormFieldError, trackedAtDate }: 
    { 
        field: ExtFormField, 
        onChange:Function, 
        record:ExtFormRecord|undefined,
        error:ExtFormFieldError|undefined,
        getFormFieldError: Function, 
        trackedAtDate?:Date,
    }) => {

    const [isFieldVisible, setIsFieldVisible] = useState<boolean>(true);

    const authUser = useAppSelector(selectAuthUser);


    useEffect(()=>{
        if (field.enabledDates === undefined || trackedAtDate === undefined){
            return;
        }
        const past = new Date(field.enabledDates.startDate);
        const future = new Date(field.enabledDates.endDate);
        if (trackedAtDate >= past && trackedAtDate < future){
            setIsFieldVisible(true);
        }else{
            setIsFieldVisible(false);
        }
    }, [trackedAtDate]);

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })
    let fieldData:any = undefined;

    // If a record is passed to us, search its data field for the control ID
    // of this field. If they match, pass the selected value(s) to the control.
    if (record !== undefined){
        const idx = record.data.findIndex( (obj:ExtFormRecordData) => obj.fid === field.fid );
        if (idx > -1 ){
            fieldData = record.data[idx];
        }
    }

    const renderField = ((field:ExtFormField)=>{
        if (field.permissions !== undefined){
            // Check if we have permission:
            const hasPerm = doesUserHavePermission(authUser, field.permissions)
            // Don't have permission, so hide this:
            if (! hasPerm){
                return <></>
            }
        }
        switch(field.control){
            case ExtControlTypes.CheckBox:
                return <ExtCheckBox 
                    field={field} 
                    isTabletOrMobile={isTabletOrMobile} 
                    onChange={onChange} 
                    existingValues={fieldData === undefined ? undefined : fieldData.value} 
                    record={record}
                    error={error} 
                    getFormFieldError={getFormFieldError}  
                    trackedAtDate={trackedAtDate}
                />
    
    
            case ExtControlTypes.Radio:
                return <ExtRadio 
                    field={field} 
                    isTabletOrMobile={isTabletOrMobile} 
                    onChange={onChange} 
                    existingValue={fieldData === undefined ? undefined : fieldData.value} 
                    record={record}
                    error={error} 
                    getFormFieldError={getFormFieldError}   
                    trackedAtDate={trackedAtDate} 
                />
    
    
            case ExtControlTypes.Select:
                return  <ExtSelect 
                    field={field} 
                    isTabletOrMobile={isTabletOrMobile} 
                    onChange={onChange}
                    existingValue={fieldData === undefined ? undefined : fieldData.value}
                    error={error}
                    record={record}
                    getFormFieldError={getFormFieldError} 
                    trackedAtDate={trackedAtDate}   
                />
    
            case ExtControlTypes.Text:
                return <ExtTextInput  
                    field={field} 
                    isTabletOrMobile={isTabletOrMobile} 
                    onChange={onChange}
                    existingValue={fieldData === undefined ? undefined : fieldData.value}
                    isLarge={false}
                    error={error}
                    record={record}
                    getFormFieldError={getFormFieldError}
                    parseAs='string'  
                    trackedAtDate={trackedAtDate} 
                />
           

            case ExtControlTypes.TextField:
                return <ExtTextInput  
                    field={field} 
                    isTabletOrMobile={isTabletOrMobile} 
                    onChange={onChange}
                    existingValue={fieldData === undefined ? undefined : fieldData.value}
                    isLarge={true}
                    error={error}
                    record={record}
                    getFormFieldError={getFormFieldError}   
                    parseAs='string'
                    trackedAtDate={trackedAtDate}
                />

            case ExtControlTypes.Number:
                return <ExtTextInput  
                    field={field} 
                    isTabletOrMobile={isTabletOrMobile} 
                    onChange={onChange}
                    existingValue={fieldData === undefined ? undefined : fieldData.value}
                    isLarge={false}
                    error={error}
                    record={record}
                    getFormFieldError={getFormFieldError}   
                    parseAs='number'
                    trackedAtDate={trackedAtDate}
                />
                
            case ExtControlTypes.Date:
                return <ExtDateTime 
                    field={field} 
                    isTabletOrMobile={isTabletOrMobile} 
                    onChange={onChange}
                    existingValue={fieldData === undefined ? undefined : fieldData.value}
                    error={error} 
                    showTime={false} 
                    record={record}
                    getFormFieldError={getFormFieldError}  
                    trackedAtDate={trackedAtDate}
                />

            case ExtControlTypes.DateTime:
                return <ExtDateTime 
                    field={field} 
                    isTabletOrMobile={isTabletOrMobile} 
                    onChange={onChange}
                    existingValue={fieldData === undefined ? undefined : fieldData.value}
                    error={error}
                    showTime={true} 
                    record={record}
                    getFormFieldError={getFormFieldError}  
                    trackedAtDate={trackedAtDate}
                 />

            case ExtControlTypes.Duration:
                return <ExtDuration 
                    field={field} 
                    isTabletOrMobile={isTabletOrMobile} 
                    onChange={onChange}
                    existingValue={fieldData === undefined ? undefined : fieldData.value}
                    error={error}
                    showTime={true} 
                    record={record}
                    getFormFieldError={getFormFieldError}  
                    trackedAtDate={trackedAtDate}
                    />
            default:
                return <p className='error'>Missing {field.control}</p>
        }
    });

    return <React.Fragment>
        {isFieldVisible && renderField(field)}
    </React.Fragment>
}

export default FormFieldRenderer;