import React, {   useState } from "react";
import "./Login.scss";
import logo from "./../../assets/images/logo_en.png";
import { Button, Form } from "react-bootstrap";
import { apiSendResetPasswordRequest } from "../api/api";

const ForgotPassword = () => {

  const [email, setEmail] = useState<string>("");

  const [errorMsg, setErrorMsg] = useState<string>("");
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const resetPasswordRequest = async(e:any)=>{
    e.preventDefault()

    try{
      setIsButtonDisabled(true);
      setErrorMsg('');
      const result = await apiSendResetPasswordRequest({email:email});
      setIsButtonDisabled(false);
      setIsSuccess(true);
      console.log(result);
  
    }catch(ex:any){
      console.log("Caught", ex);
      setErrorMsg(ex.message);
      setIsButtonDisabled(false);
    }
   
  }
 

  return (
    <div className="page-login">
      <div className="login">
        <img src={logo} alt="PlaySmart" />

        <h1>Responsible Gambling Council Extranet</h1>
        <h3>Password Reset Request</h3>
        {! isSuccess ? <Form onSubmit={resetPasswordRequest} >
          <section>
            <Form.Group className="mb-3">
              <Form.Label md={3}>Email Address:</Form.Label>
              <Form.Control
                type="email"
                id="email"
                autoComplete="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            {errorMsg !== "" && <p className="error">Sorry, something went wrong: {errorMsg}</p> }
          </section>
          <div className="d-grid gap-2">
            <Button variant="primary" size="lg" disabled={isButtonDisabled}   type='submit'>
              Get Password Reset Email
            </Button>
          </div>
        </Form> 
        :
        <div className="success">
          <h4>Please check your email for the password reset link.</h4>  
        </div>
      }
        
      </div>
    </div>
  );
};

export default ForgotPassword;
