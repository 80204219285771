import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
   apiCreateInteractionTimesReport,
} from "../../app/api/api";
import { serializeFeathersError } from "../../app/api/serializeFeathersError";
import { ExtRecordSearchQuery } from "../forms/recoredsSlice";


/*
interface ExtInteractionTimesReport {
    criteria: ExtRecordSearchQuery;
    createdAt: Date;
    records: Array<ExtEventSummaryReportElement>;
    recordCount: number;
  }
  
  interface ExtEventSummaryReportElement {
    location: {
      lid: string,
      name: string,
    },
    audience: string,
    locationInVenue: string,
    eventCount: number,
    visitorCount: number,
  }
  */

  export interface ExtInteractionTimesReportEntry {
    location: {
      lid: string,
      name: string
    },
    numInteractions: number
  }
  
 export interface ExtInteractionTimesReport {
      '0000-0659': Array<ExtInteractionTimesReportEntry>,
      '0700-1159': Array<ExtInteractionTimesReportEntry>,
      '1200-1859': Array<ExtInteractionTimesReportEntry>,
      '1900-2359': Array<ExtInteractionTimesReportEntry>,
      olsonTzId?: string,
      criteria?:any,
  }
  
  
  

export interface InteractionTimesReportState {
  value: ExtInteractionTimesReport | undefined;
  status: "idle" | "loading" | "failed";
}

const initialState: InteractionTimesReportState = {
  value: undefined,
  status: "idle",
};

/**
 * Submit the report criteria
 */
export const createInteractionTimesReport = createAsyncThunk(
  "reporting/interaction-times/create",
  async (query: ExtRecordSearchQuery, { rejectWithValue }) => {
    try {
      console.log("Sending query", query);
      const response = await apiCreateInteractionTimesReport(query);
      console.log("Thunk api response", response)
      return response;
    } catch (err) {
      const e = serializeFeathersError(err);
      return rejectWithValue(e);
    }
  }
);

/**
 * Creates the Redux Slice for the forms
 */
export const interactionTimesReportSlice = createSlice({
  name: "reporting/interactionTimes",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(createInteractionTimesReport.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createInteractionTimesReport.fulfilled, (state, action) => {
        state.status = "idle";
        state.value = action.payload;
      });
  },
});


export const selectInteractionTimesReport = (state: RootState) => state.interactionTimesReport.value;

export default interactionTimesReportSlice.reducer;
