import feathersClient from '../../client';
import { ExtRecordSearchQuery } from '../../features/forms/recoredsSlice';
import { ExtEventType, ExtFormRecord,  ExtLocation,  ExtUser } from './api.types';

/***
 * The Extranet API is powered by https://feathersjs.com
 * Our implementation uses a persistent socket connection 
 * along with the FeathersJS client to interact with the
 * back-end Feathers Services.
 * 
 * Most of these functions are wrapped/called by a 
 * Redux Slice such that the store can maintain state.
 */


// ------ Authentication -------

export const apiLoginUser = async ({email, password, remember}:{email:string, password:string, remember:boolean}) => {
    try{
          await feathersClient.authenticate({
              strategy: "local",
              email: email,
              password: password,
              remember: remember
          });
          
         return feathersClient.get("authentication");
      }catch(err){
          
         return err;
      }
  }
  
export const apiReAuthenticate = async () => {
    try{
        return await feathersClient.reAuthenticate();
    }catch(err:any){
    //    console.log("Reauth err", err);
        err.hook = undefined;
        err.data = undefined;
        return ;
    }
}


export const apiLogoutUser = async () => {
    return await feathersClient.logout();
}
  



export const apiSendResetPasswordRequest = async  ({email}:{email:string}) => {
    const auth = feathersClient.service('password-reset-request');
    return await auth.create(
    {
      email: email,
    });
}


export const apiResetPassword= async ( {token, password}:{token:string,  password:string} ) => {
    const auth = feathersClient.service('reset-password');
    return await auth.create(
    {
        password: password,
        code: token,
    });

}



// --- Users ---

export const apiGetUser = async (id:string) => {
    try{
        const usersService = feathersClient.service("users");
        return await usersService.get(id);
    }catch(err){
        throw err;
    }
}


export const apiFindUsers = async (query:Object) => {
    try{
        const usersService = feathersClient.service("users");
        return await usersService.find({query:query});
        
    }catch(err){
        throw err;
    }
}



export const apiCreateUser = async ({user}:{user:ExtUser}) => {
    try{
        const usersService = feathersClient.service("users");
        const resp =  await usersService.create(user);
        console.log(resp);
        return resp;
    }catch(err){
        throw err;
    }
}


export const apiPatchUser = async ({user}:{user:ExtUser}) => {
    try{
        const usersService = feathersClient.service("users");
        const resp =  await usersService.patch(user._id, user);
        return resp;
    }catch(err){
        throw err;
    }
}


export const apiFindStaff = async () => {
    try{
        const staffService = feathersClient.service("staff");
        return await staffService.find();
        
    }catch(err){
        throw err;
    }
}



// --- Forms ---


export const apiGetForms = async ()=>{
    try{
        const formsService = feathersClient.service("forms");
        return await formsService.find();
    }catch(err){
        throw err;
    }
}




export const apiGetRecord = async (id:string)=>{
    try{
        const recService = feathersClient.service("records");
        return await recService.get(id);
    }catch(err){
        throw err;
    }
}



export const apiFindRecords = async (query:Object)=>{
    //ebugger;
   // console.log("Query", query);
    try{
        const recService = feathersClient.service("records");
        return await recService.find({query: query});
    }catch(err){
        throw err;
    }
}



export const apiCreateRecord = async (record:ExtFormRecord)=>{
    try{
        console.log("New records:", record);
        delete(record._id);
        const formsService = feathersClient.service("records");
        return formsService.create(record);
    }catch(err){
        throw err;
    }
}



export const apiUpdateRecord = async (record:ExtFormRecord)=>{
    try{
        const formsService = feathersClient.service("records");
        return formsService.patch(record._id, record);
    }catch(err){
        throw err;
    }
}


export const apiDeleteRecord = async (record:ExtFormRecord)=>{
    try{
        const formsService = feathersClient.service("records");
        return formsService.remove(record._id);
    }catch(err){
        throw err;
    }
}



export const apiFindLocations = async (query:Object)=>{
   
    try{
        const service = feathersClient.service("locations");
        const result = await service.find({query:query});
        return result;
    }catch(err){
        throw err;
    }
}


export const apiGetLocation = async (id:string)=>{
   
    try{
        const service = feathersClient.service("locations");
        const result = await service.get(id);
        return result;
    }catch(err){
        throw err;
    }
}


export const apiCreateLocation = async ({location}:{location:ExtLocation})=>{
    try{
        const service = feathersClient.service("locations");
        return service.create(location);
    }catch(err){
        throw err;
    }
}


export const apiPatchLocation = async ({location}:{location:ExtLocation})=>{
    try{
        const service = feathersClient.service("locations");
        return service.patch(location._id, location);
    }catch(err){
        throw err;
    }
}


export const apiDeleteLocation = async ({id}:{id:string})=>{
    try{
        const service = feathersClient.service("locations");
        return service.remove(id);
    }catch(err){
        throw err;
    }
}





export const apiFindEventTypes = async (query:Object)=>{
   
    try{
        const service = feathersClient.service("event-types");
        const result = await service.find({query:query});
        return result;
    }catch(err){
        throw err;
    }
}

export const apiGetEventType = async (id:string)=>{
    try{
        const service = feathersClient.service("event-types");
        return await service.get(id);
    }catch(err){
        throw err;
    }
}



export const apiCreateEventType = async ({eventType}:{eventType:ExtEventType})=>{
    try{
        const service = feathersClient.service("event-types");
        return service.create(eventType);
    }catch(err){
        throw err;
    }
}


export const apiPatchEventType = async ({eventType}:{eventType:ExtEventType})=>{
    try{
        const service = feathersClient.service("event-types");
        return service.patch(eventType._id, eventType);
    }catch(err){
        throw err;
    }
}


export const apiDeleteEventType = async ({id}:{id:string})=>{
    try{
        const service = feathersClient.service("event-types");
        return service.remove(id);
    }catch(err){
        throw err;
    }
}




// --- Reporting ---

export const apiCreateSumReport = async ( search:ExtRecordSearchQuery )=>{
    
    try{
        const sumReportService = feathersClient.service("reporting/summing");
        return sumReportService.create(search);
    }catch(err){
        throw err;
    }
}


export const apiCreateCumulativeTimeReport = async ( search:ExtRecordSearchQuery )=>{
    try{
        const reportService = feathersClient.service("reporting/staff-duration");
        return reportService.create(search);
    }catch(err){
        throw err;
    }
}

export const apiCreateEventSummaryReport = async ( search:ExtRecordSearchQuery )=>{
    try{
        const reportService = feathersClient.service("reporting/event-summary");
        return reportService.create(search);
    }catch(err){
        throw err;
    }
}


export const apiCreateSummaryReport = async ( search:ExtRecordSearchQuery )=>{
    try{
        const reportService = feathersClient.service("reporting/summary");
        return reportService.create(search);
    }catch(err){
        throw err;
    }
}


export const apiCreateFilterReport = async ( search:ExtRecordSearchQuery )=>{
    try{
        const reportService = feathersClient.service("reporting/filtered-report");
        return reportService.create(search);
    }catch(err){
        throw err;
    }
}



export const apiCreateInteractionTimesReport = async ( search:ExtRecordSearchQuery )=>{
    try{
       
        const reportService = feathersClient.service("reporting/interaction-times");
        return reportService.create(search);
    }catch(err){
        throw err;
    }
}

