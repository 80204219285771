import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {  apiCreateLocation, apiDeleteLocation, apiFindLocations, apiGetLocation, apiPatchLocation } from '../../app/api/api';
import { serializeFeathersError } from '../../app/api/serializeFeathersError';
import { ExtLocation } from '../../app/api/api.types';



/**
 * Async thunk state
 */
export interface ExtLocationState {
  locations: Array<ExtLocation>;
  deleted: Array<ExtLocation>;
  location: ExtLocation|undefined,
  status: 'idle' | 'loading' | 'failed';
}

const initialState: ExtLocationState = {
  locations: [],
  location: undefined,
  deleted: [],
  status: 'idle',
};

/**
 * Get all the locations
 */
export const findLocations = createAsyncThunk(
  'locations/find',
  async (query:Object|undefined, { rejectWithValue })  => {
    try{
      const response = await apiFindLocations({deletedAt: null} );
      return response.data;
    }catch(err){
      const e = serializeFeathersError(err)
      return rejectWithValue(e);
    }
  }
);


/**
 * Get all the deleted locations
 */
export const findDeletedLocations = createAsyncThunk(
  'locations/find-deleted',
  async (_, { rejectWithValue })  => {
    try{
      const response = await apiFindLocations( {deletedAt: {$ne:null} });
      return response.data;
    }catch(err){
      const e = serializeFeathersError(err)
      return rejectWithValue(e);
    }
  }
);


export const getLocation = createAsyncThunk(
  'locations/get',
  async ( id:string, { rejectWithValue }) => {
    try{
      const response = await apiGetLocation(id);
      return response;
    }catch(err){
      const e = serializeFeathersError(err)
      return rejectWithValue(e);
    }
  }
);




/**
 * Create a new location
 */
export const createLocation = createAsyncThunk(
  'locations/create',
  async ( 
    {location}:{location:ExtLocation}, 
    { rejectWithValue }) => {
    try{
       const response = await apiCreateLocation({location:location});
      return response.data;
    }catch(err){
      const e = serializeFeathersError(err)
      return rejectWithValue(e);
    }
  }
);


/**
 * Update an existing location
 */
 export const updateLocation = createAsyncThunk(
  'locations/update',
  async ( 
    {location}:{location:ExtLocation}, 
    { rejectWithValue }) => {
    try{
       const response = await apiPatchLocation({location:location});
      return response.data;
    }catch(err){
      const e = serializeFeathersError(err)
      return rejectWithValue(e);
    }
  }
);


/**
 * Delete an existing location
 */
export const deleteLocation = createAsyncThunk(
  'locations/delete',
  async ( 
    {id}:{id:string}, 
    { rejectWithValue }) => {
    try{
       const response = await apiDeleteLocation({id:id});
      return response.data;
    }catch(err){
      const e = serializeFeathersError(err)
      return rejectWithValue(e);
    }
  }
);




/**
 * Creates the Redux Slice for the forms
 */
export const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(findLocations.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(findLocations.fulfilled, (state, action) => {
        state.status = 'idle';
        state.locations = action.payload;
        state.locations.sort((a, b) =>{
          return (a.name < b.name ? -1 : 1)
        }) ;
      })
      .addCase(findLocations.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(findDeletedLocations.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(findDeletedLocations.fulfilled, (state, action) => {
        state.status = 'idle';
        state.deleted = action.payload;
        state.deleted.sort((a, b) =>{
          return (a.name < b.name ? -1 : 1)
        }) ;
      })
      .addCase(findDeletedLocations.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(getLocation.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getLocation.fulfilled, (state, action) => {
        state.status = 'idle';
        state.location = action.payload;
      })
      .addCase(getLocation.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(updateLocation.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateLocation.fulfilled, (state, action) => {
        state.status = 'idle';
        state.location = action.payload;
      })
      .addCase(updateLocation.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(createLocation.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createLocation.fulfilled, (state, action) => {
        state.status = 'idle';
        state.location = action.payload;
      })
      .addCase(createLocation.rejected, (state) => {
        state.status = 'failed';
      })
  },
});


/**
 * Create the Redux Selector to access form state data.
 * @param state RootState
 * @returns Array of all the locations
 */
export const selectLocations = (state: RootState) => state.locations.locations;
export const selectLocation = (state: RootState) => state.locations.location;
export const selectDeletedLocations = (state: RootState) => state.locations.deleted;
export const selectLocationsNetStatus = (state: RootState) => state.locations.status;




export default locationsSlice.reducer;
