import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import "../FormRenderer.scss";
import ReportSearchCriteria from "./ReportSearchCriteria";
import { Col, Form, Row } from "react-bootstrap";
import "./Reports.scss";
import DataTable from "react-data-table-component";
import { PieChart } from "./PieChart";
import { CSVLink } from "react-csv";
import { createEventSummaryReport, selectEventSummaryReport } from "../../../features/reports/eventSummaryReportSlice";
import { selectAuthUser } from "../../../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { showNotification } from "../../../features/notifications/notificationSlice";
import { ExtPermissions } from "../../api/api.types";
import doesUserHavePermission from "../../../utils/doesUserHavePermission";

  
interface CsvHeader {
  label: string;
  key: string;
}

/*
interface PieChartData {
  id: string,
  label: string;
  value: number;
  color?: string;
}
*/

const ReportEventSummary = ({
  isTabletOrMobile,
}: {
  isTabletOrMobile: boolean;
}) => {
  
  const eventSummaryReport = useAppSelector(selectEventSummaryReport);
  
  const [isGraphView, setIsGraphView] = useState<boolean>(false);
  const [pieChartData, setPieChartData] = useState<any>();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const authUser = useAppSelector(selectAuthUser);

  useEffect(()=>{
    if (doesUserHavePermission(authUser, ExtPermissions.canCreateReports)  ){
          // All good, they're allowed
        }else{
          
          const navNow = ()=>{
          dispatch(showNotification({
                header: "You can't do that",
                body: 'You do not have permission to access reports.',
                type: 'error'
            }))
            navigate('/');
          }
          navNow();
          return;
        }
  }, [authUser, dispatch, navigate])



  const columns = [
    {
        name: "Number of Events",
        selector: (row: any) => row.eventCount,
        sortable: true,
        compact: true,
        center: true,
        wrap: true,
        maxWidth: "100px"
    },
    {
        name: "Venue",
        selector: (row: any) => row.location.name,
        sortable: true,
        compact: true,
        center: true,
        wrap: true,
        grow: 2,
        maxWidth: "260px"
    },
    {
        name: "Audience",
        selector: (row: any) => row.audience,
        sortable: true,
        compact: true,
        center: true,
        wrap: true,
        maxWidth: "200px"
    },
    {
        name: "Location in Venue",
        selector: (row: any) => row.locationInVenue,
        sortable: true,
        compact: true,
        center: true,
        wrap: true,
        maxWidth: "200px"
    },
    {
        name: "Total Event Visitors",
        selector: (row: any) => row.visitorCount,
        sortable: true,
        compact: true,
        center: true,
        wrap: true,
        maxWidth: "80px"
    },
  ];




  /**
   * Generate the headers necessary for a CSV document.
   */
   const getCsvHeaders = () => {
    const headers: Array<CsvHeader> = [
      {
        label: "Number of Events",
        key: "NUMEVT",
      },
      {
        label: "Venue",
        key: "VENUE",
      },
      {
        label: "Audience",
        key: "AUD",
      },
      {
        label: "Location in Venue",
        key: "LOCVENUE",
      },
      {
        label: "Total Event Visitors",
        key: "TOTAL",
      },
     
    ];

    return headers;
  };


 

  /**
   * Format the report data for CSV export.
   * @returns Array with large object of key/value pairs
   */
   const getCsvReportData = (): Array<any> => {
    const rowList:Array<any> = [];
    eventSummaryReport?.records.forEach((rec) => {
      const row: any = {};
      row["NUMEVT"] = rec.eventCount;
      row["VENUE"] = rec.location.name;
      row["AUD"] = rec.audience;
      row["LOCVENUE"] = rec.locationInVenue;
      row["TOTAL"] = rec.visitorCount;
      rowList.push(row);
    });

    return rowList;
  };



  /**
   * Generate a filename based on the report type and date
   * @returns string the file name
   */
  const getCsvFileName = () => {
    const name = `Event_Summary-${new Date().toDateString()}.csv`;
    const re = / /gi;
    const result = name.replace(re, "_");
    return result;
  };




  return (
    <React.Fragment>
      <section className="reports patron-visitor">
        <h2>Event Summary Report</h2>
        <p>
          Event Summary Reports calculate the number of events by location, event type, and location in venue. 
          They include the total number of visitors.
        </p>
       

        <hr />
        <ReportSearchCriteria
          isTabletOrMobile={isTabletOrMobile}
          reportSelector={selectEventSummaryReport}
          reportThunk={createEventSummaryReport}
          showFormTypeSelector={false}
          showStaffSelector={false}
          showLocationSelector={true}
        />

        {eventSummaryReport && (
          <section className="report">
            <Form className="ext-form">
              <Row className="header">
                <Col sm={5}>
                  <h3>Report</h3>
                </Col>
                <Col sm={4}>
              {/*    <Form.Check
                    type="switch"
                    id="visualization-check"
                    label="Visualize Data"
                    className="visualization-check"
                    checked={isGraphView}
                    onChange={() => setIsGraphView(!isGraphView)}
        /> */}
                </Col>
                <Col sm={3}>
                  <CSVLink
                      data={getCsvReportData()}
                      headers={getCsvHeaders()}
                      filename={getCsvFileName()}
                      className="csv"
                    >
                      Export CSV
                  </CSVLink>
                </Col>
              </Row>

              <Row>
                <section className="input-section">
                  <h4>Event Summary Report</h4>
                  <Form.Group as={Row} className="mb-3" controlId="reportType">
                    <Form.Label column md={4} aria-label="Reported At">
                      Report Created At:
                    </Form.Label>
                    <Col md={8}>
                      <Form.Control
                        as="input"
                        value={`${new Date(eventSummaryReport.createdAt).toDateString()} @ ${new Date(eventSummaryReport.createdAt).toLocaleTimeString()}`}
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                </section>
              </Row>
                    
              
              { !isGraphView && <Row className="data-table">
                <DataTable
                  columns={columns}
                  data={eventSummaryReport.records}
                  dense={isTabletOrMobile}
                  fixedHeader
                  pagination
                  paginationPerPage={30}
                  responsive
                  subHeaderWrap
                  highlightOnHover
                  pointerOnHover
                  striped
                />
              </Row>
              }
              { isGraphView && <Row className="graph">
                <div className="chart">
                  <label>Event Summary Audience Graph</label>
                  <PieChart
                    pieData={ pieChartData }
                    isTabletOrMobile={isTabletOrMobile}
                  />
                </div>
                
              </Row>
              }
            </Form>
          </section>
        )}
      </section>
    </React.Fragment>
  );
};

export default ReportEventSummary;
