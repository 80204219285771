import React, { useEffect, useState } from "react";
import { Button, Col, Container,  Form, Modal, Row } from "react-bootstrap";
import { ExtAudienceType, ExtAudienceTypes, ExtEventType, } from "../../api/api.types";
import { useAppDispatch } from "../../hooks";
import { showNotification } from '../../../features/notifications/notificationSlice';
import './EventEditor.scss';
import scrollToTop from "../../../utils/scrollTop";
import {  useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { createEventType, deleteEventType, findEventTypes, getEventType, selectEventType, selectEventTypesNetStatus, updateEventType } from "../../../features/events/eventTypeSlice";
import { getForms } from "../../../features/forms/formsSlice";




const EventTypeEditor = ({isTabletOrMobile}:{ isTabletOrMobile:boolean}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { id } = useParams();

    const editEventType = useSelector(selectEventType);
    const eventNetStatus = useSelector(selectEventTypesNetStatus);

    const [eventTypeId, setEventTypeId] = useState<string>('');
    const [label, setLabel] = useState<string>('');
    const [audience, setAudience] = useState<string>('');

    const [isEditExisting, setIsEditExisting] = useState<boolean>(false);

    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState<boolean>(false);


    useEffect(()=>{
        const loadEventType = async (id:string|undefined) =>{
            if (id !== undefined) {
                setIsEditExisting(true);
                const resp = await dispatch(getEventType(id));
                if (resp.payload !== undefined){
                    switch (resp.payload.code){
                        case undefined:
                            // Nothing to do, all good
                        break;
                        case 404:
                            setIsDisabled(true);
                            dispatch(showNotification({
                                header: 'Event Type not found',
                                body: resp.payload.message,
                                type: 'error'
                            }));
                            break;
                        default:
                            setIsDisabled(true);
                            dispatch(showNotification({
                                header: 'Something went wrong',
                                body: resp.payload.message,
                                type: 'error'
                            }))
                    }
                }
            }
        }
        if (id === undefined){
            resetData();
            setIsEditExisting(false);
        }
        resetData();
        loadEventType(id);
    }, [id, dispatch])


    useEffect(()=>{
        if (id && editEventType !== undefined){
            setEventTypeId(editEventType.eventId);
            setLabel(editEventType.label);
            setAudience(editEventType.audienceId);
            
            if (editEventType.deletedAt){
                setIsDisabled(true)
            }else{
                setIsDisabled(false)
            }
        }
    }, [editEventType])


  


    /**
     * Wipe out any existing form data
     */
    const resetData = ()=>{
        setEventTypeId('');
        setLabel('');
        setAudience('');
        setIsDisabled(false);
        setIsEditExisting(false);
    }



    /**
     * When submit is clicked on the form
     * @param event Form Event
     */
    const onSubmitClicked = async (event:any)=>{
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            return;
        }

        const newId = (label.replace(/\s/g, "") + Math.random() * 10).toLowerCase();

        const evt:ExtEventType = {
            _id: editEventType ? editEventType._id : undefined,
            eventId: eventTypeId ? eventTypeId : newId, 
            label: label,
            audienceId: audience
        }

      
       
        let result;
        if (isEditExisting){
          //  loc._id = editLocation?._id;
            result = await dispatch(updateEventType({eventType:evt}));
        }else{
            result = await dispatch(createEventType({eventType: evt}));
        }
       
        dispatch(getForms()) // Loading forms re-loads the relationships, which events are one
        
        scrollToTop()
       

        if (result.meta.requestStatus === 'rejected'){
            dispatch(showNotification({
                header: 'Something went wrong',
                body: `${result.payload.name}: ${result.payload.message}`,
                type: 'error'
            }))
        }else{
            dispatch(showNotification({
                header: 'Success!',
                body: 'The event type was saved successfully',
                type: 'success'
            }))
            resetData();
            
            navigate('/admin/event-types')
   
        }
    }


    const onDeleteEventType = async ()=>{
        if (editEventType){
            const result = await dispatch(deleteEventType({id: editEventType._id! }))

            if (result.meta.requestStatus === 'rejected'){
                dispatch(showNotification({
                    header: 'Something went wrong',
                    body: `${result.payload.name}: ${result.payload.message}`,
                    type: 'error'
                }))
            }else{
                dispatch(showNotification({
                    header: 'Success!',
                    body: 'The event type was marked deleted',
                    type: 'success'
                }))
              
                dispatch(getForms()) // Loading forms re-loads the relationships, which events are one
            
                resetData();
                navigate('/admin/event-types')
       
            }
        }  

    }

  return (
    <Container className='input-form location-editor'  fluid='xl' >
        {isEditExisting ? <h2>Update Event Type</h2> : <h2>Add New Event Type</h2>}
        <hr/>

        {eventNetStatus === 'loading' ? <h3>Loading...</h3>  : <Form onSubmit={onSubmitClicked}   >
            <fieldset disabled={isDisabled }>
            <section key='basic-info' className='input-section'>
          
                <Form.Group as={Row} className="mb-3" controlId="name">
                    <Form.Label column md={3}>*Name:</Form.Label>
                    <Col md={9} >
                        <Form.Control 
                            name='label' 
                            type="text" 
                            placeholder="Event type name"    
                            required
                            value={label}
                            onChange={e => setLabel( e.target.value)}
                        />
                        
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="audience">
                    <Form.Label column md={3}>*Audience:</Form.Label>
                        <Col md={9} >
                        <Form.Select aria-label="audience" 
                            required
                            value={audience}
                            onChange={e => setAudience(e.target.value) }  >
                                <option value=''>Select</option>
                                {ExtAudienceTypes.map( (a:ExtAudienceType) => {
                                    return <option key={`${a.audienceId}`} value={a.audienceId}>{a.label}</option>
                                })}
                            </Form.Select>
                        </Col>
                </Form.Group>
                
            </section>
            
            {isEditExisting && 
                <section className="warning">
                    <p>Please note: editing an existing event type is intended <strong>only</strong> for correcting things like spelling, etc. 
                     If you edit an existing event type these changes will apply to <em>all submitted records and reports, existing or future</em>. 
                     If this is not what you want please mark this event type as deleted and create a new one to keep old forms and reports accurate.
                    </p>
                </section>
            }
         
            <section className='form-controls'>
                <Button variant="primary" type="submit" disabled={editEventType?.deletedAt !== undefined} >
                    {isEditExisting ? "Update Event Type" : "Create Event Type"}
                </Button>
                {isEditExisting && <Button variant="danger" onClick={()=>setIsConfirmDeleteOpen(true) }  disabled={editEventType?.deletedAt !== undefined}>Mark Deleted</Button> }
            </section> 
        </fieldset>

        
      </Form>
}

    <Modal show={isConfirmDeleteOpen} onHide={()=>setIsConfirmDeleteOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Event Type?</Modal.Title>
        </Modal.Header>
        <Modal.Body>This will mark the event as inactive. You won't be able to use it with any forms going forward.</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={()=>setIsConfirmDeleteOpen(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={()=>onDeleteEventType()}>
            DELETE
          </Button>
        </Modal.Footer>
      </Modal>

    </Container>
  );
};

export default EventTypeEditor;
