import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { selectForms } from "../../../features/forms/formsSlice";
import { selectLocations } from "../../../features/locations/locationsSlice";
import {
  ExtControlTypes,
  ExtFieldValueSimple,
  ExtForm,
  ExtFormField,
  ExtFormSection,
  ExtLineOfBusiness,
  ExtLocation,
  ExtRegion,
  ExtRegionList,
  ExtUser,
  LineOfBusinessList,
} from "../../api/api.types";
import { useAppDispatch, useAppSelector } from "../../hooks";
import "../FormRenderer.scss";
import { ExtRecordSearchQuery } from "../../../features/forms/recoredsSlice";
import "./Reports.scss";
import { selectStaffList } from "../../../features/users/staffSlice";
import FormFieldRenderer from "../FormRenderingComponents/FormFieldRenderer";
import { ExtFormFieldValue } from "../FormRenderer";

/**
 * ReportSearchCriteria
 * Allows you to set the search criteria and trigger
 * a report to be generated.
 *
 * Should be wrapped in a component that will pass
 * the necessary report generation thunk and selector.
 *
 * The selector is used to watch for network traffic.
 * The thunk to generate the report based on the query.
 */

const ReportSearchCriteria = ({
  reportSelector, //Pass the selector used to view this report.
  reportThunk, // Pass the thunk to generate this report
  showFormTypeSelector,
  showStaffSelector,
  showLocationSelector,
  showSectionFieldSelector,
  showFormFieldValueSelector,
  showGroupByRegionSelector,
  showGroupByLineOfBusiness,
  isTabletOrMobile,
}: {
  reportSelector: any;
  reportThunk: any;
  showFormTypeSelector: boolean;
  showStaffSelector: boolean;
  showLocationSelector: boolean;
  showSectionFieldSelector?: boolean;
  showFormFieldValueSelector?: boolean;
  showGroupByRegionSelector?: boolean;
  showGroupByLineOfBusiness?: boolean;
  isTabletOrMobile: boolean;
}) => {
  const dispatch = useAppDispatch();

  const formList = useAppSelector(selectForms);
  const locationsList = useAppSelector(selectLocations);
  const staffList = useAppSelector(selectStaffList);
  const repSelector = useAppSelector(reportSelector);

  const [selectedFormType, setSelectedFormType] = useState<string>("PV");
  const [selectedLocationIds, setSelectedLocationIds] = useState<Array<string>>(
    []
  );
  const [selectedStaff, setSelectedStaff] = useState<Array<string>>([]);
  const [selectedFormSection, setSelectedFormSection] = useState<
    string | undefined
  >();
  const [sectionOptionsList, setSectionOptionsList] = useState<
    Array<ExtFormSection>
  >([]);

  const [selectedForm, setSelectedForm] = useState<ExtForm>();

  const [fromDate, setSelectedFromDate] = useState<Date>();
  const [toDate, setSelectedToDate] = useState<Date>();
  const [isNetwork, setIsNetwork] = useState<boolean>(false);

  const [selectedFormWhereFields, setSelectedFormWhereFields] = useState<
    Array<ExtFormField>
  >([]);
  const [
    selectedFormWhereConditionValues,
    setSelectedFormWhereConditionValues,
  ] = useState<Array<ExtFieldValueSimple>>([]);

  const [selectedFormFieldIds, setSelectedFormFieldIds] = useState<
    Array<string>
  >([]);
  const [selectedRegionIds, setSelectedRegionIds] = useState<Array<string>>([]);
  const [selectedLobIds, setSelectedLobIds] = useState<Array<string>>([]);

  const onFormTypeChanged = (typeId: string) => {
    setSelectedFormWhereFields([]);
    setSelectedFormType(typeId);
    formList.forEach((f) => {
      if (f.formId === typeId) {
        setSelectedForm(f);
      }
    });
  };

  const onFromDateChanged = (val: Date) => {
    setSelectedFromDate(val);
  };

  const onToDateChanged = (val: Date) => {
    setSelectedToDate(val);
  };

  /**
   * When a line of business checkbox is clicked
   * @param id LoB ID
   * @param checked Is it checked?
   * @param forcedAdd Is this forcibly added (prevents a toggle action)
   */
  const onLobClicked = (
    id: string,
    checked: boolean,
    forcedAdd: boolean = false
  ) => {
    // Add/remove from our list of selected values:
    let newList = [...selectedLobIds];
    if (checked) {
      newList = [...selectedLobIds, id];
      setSelectedLobIds(newList);
    } else {
      if (!forcedAdd) {
        const idx = selectedLobIds.indexOf(id);
        newList.splice(idx, 1);
        setSelectedLobIds(newList);
      }
    }

    setSelectedLocationsBasedOnLobs(newList);
  };

  /**
   * When a location checkbox is clicked
   * @param id Location ID
   * @param checked Is it checked?
   * @param forcedAdd Is this forcibly added (prevents a toggle action)
   */
  const onLocationClicked = (
    id: string,
    checked: boolean,
    forcedAdd: boolean = false
  ) => {
    // Add/remove from our list of selected values:
    let newList = [...selectedLocationIds];
    if (checked) {
      newList = [...selectedLocationIds, id];
      setSelectedLocationIds(newList);
    } else {
      if (!forcedAdd) {
        const idx = selectedLocationIds.indexOf(id);
        newList.splice(idx, 1);
        setSelectedLocationIds(newList);
      }
    }

    // If you manually touch locations get rid of regions & LoBs
    // since it's no longer 1:1 match.
    setSelectedRegionIds([]);
    setSelectedLobIds([]);
  };

  /**
   * When a region is clicked select all locations within that region
   * @param regionId
   */
  const onRegionClicked = (
    regionId: string,
    checked: boolean,
    forcedAdd?: boolean
  ) => {
    // Add/remove from our list of selected values:
    let newList = [...selectedRegionIds];
    if (checked) {
      newList = [...selectedRegionIds, regionId];
      setSelectedRegionIds(newList);
    } else {
      if (!forcedAdd) {
        const idx = selectedRegionIds.indexOf(regionId);
        newList.splice(idx, 1);
        setSelectedRegionIds(newList);
      }
    }

    setSelectedLocationsBasedOnRegions(newList);
  };

  /**
   * When changing the selected LoBs or Regions we need to
   * determine which locations are selected.
   * Since updates to the tracked state are async, use the passed
   * list for whichever just changed rather than the tracked state
   * (which will be one update behind).
   * @param newList List of selected IDs
   */
  const setSelectedLocationsBasedOnLobs = (
    newList: Array<string>,
  ) => {
    const list: Array<string> = [];

    if (newList.length === 0 && selectedRegionIds.length === 0){
      // Nothing is selected:
      setSelectedLocationIds([]);
      return;
    }


    const allRegionsIdsList = ExtRegionList.map( (r) => { return r.rid } );
    
    if (newList.length === 0){
      newList = LineOfBusinessList.map( l => l.lid);
    }
    const regionsFilter = selectedRegionIds.length > 0 ? selectedRegionIds : allRegionsIdsList ;

    locationsList.forEach((l) => {
      if (newList.indexOf(l.lob) > -1 && regionsFilter.indexOf(l.region) > -1) {
        list.push(l._id);
      }
    });
    
    setSelectedLocationIds(list);
  };


 /**
   * When changing the selected LoBs or Regions we need to
   * determine which locations are selected.
   * Since updates to the tracked state are async, use the passed
   * list for whichever just changed rather than the tracked state
   * (which will be one update behind).
   * @param newList List of selected IDs
   */
  const setSelectedLocationsBasedOnRegions = (
    newList: Array<string>,
  ) => {

    if (newList.length === 0 && selectedLobIds.length === 0){
      // Nothing is selected:
      setSelectedLocationIds([]);
      return;
    }

    const list: Array<string> = [];

    const allLobIdsList = LineOfBusinessList.map( (l) => { return l.lid } );

    if (newList.length === 0){
      newList = ExtRegionList.map(r=>r.rid);
    }

    const lobsFilter = selectedLobIds.length > 0 ? selectedLobIds : allLobIdsList ;

    locationsList.forEach((l) => {
      if (newList.indexOf(l.region) > -1 && lobsFilter.indexOf(l.lob) > -1) {
        list.push(l._id);
      }
    });
    
    setSelectedLocationIds(list);
  }


  /**
   * When a staff checkbox is clicked
   * @param id Location ID
   * @param checked Is it checked?
   * @param forcedAdd Is this forcibly added (prevents a toggle action)
   */
  const onStaffClicked = (
    id: string,
    checked: boolean,
    forcedAdd: boolean = false
  ) => {
    // Add/remove from our list of selected values:
    let newList = [...selectedStaff];
    if (checked) {
      newList = [...selectedStaff, id];
      setSelectedStaff(newList);
    } else {
      if (!forcedAdd) {
        const idx = selectedStaff.indexOf(id);
        newList.splice(idx, 1);
        setSelectedStaff(newList);
      }
    }
  };

  /**
   * If a field is checked to be added to the WHERE clause
   * @param field
   * @param isChecked
   */
  const onFieldCriteraClicked = (field: ExtFormField, isChecked: boolean) => {
    const list = [...selectedFormWhereFields];
    if (isChecked) {
      list.push(field);
    } else {
      // Delete it from the list of selected fields
      const idx = list.findIndex((f) => f.fid === field.fid);
      list.splice(idx, 1);

      // Also delete any where values tracked for this field:
      const whereList = [...selectedFormWhereConditionValues];
      const wIdx = whereList.findIndex((w) => w.fid === field.fid);
      if (wIdx > -1) {
        whereList.splice(wIdx, 1);
        setSelectedFormWhereConditionValues(whereList);
      }
    }

    setSelectedFormWhereFields(list);
    if (list.length === 0) {
      // Remove the last one, remove the condtions
      setSelectedFormWhereConditionValues([]);
    }
  };

  /**
   * User sets a value for one of the conditions
   * @param value
   */
  const onSetWhereValue = (value: ExtFormFieldValue) => {
    const list = [...selectedFormWhereConditionValues];

    // If we don't have it on the list, push it.
    // If we do update its value
    const idx = list.findIndex((v) => v.fid === value.fid);
    if (idx === -1) {
      list.push({
        fid: value.fid,
        value: value.value,
      });
    } else {
      list[idx].value = value.value;
      if (list[idx].value === "") {
        // Remove empty strings:
        list.splice(idx, 1);
      }
    }

    // Remove any that have become undefined (children removed by parent)
    for (let i = list.length - 1; i >= 0; i--) {
      if (list[i].value === undefined) {
        list.splice(i, 1);
      }
    }

    setSelectedFormWhereConditionValues(list);
  };

  const onGenerateReport = () => {
    setIsNetwork(true);
    let q: ExtRecordSearchQuery = {
      reportedAt: {
        $gte: fromDate!,
        $lt: toDate!,
      },
    };

    if (selectedFormType === "") {
      setSelectedFormType(formList[0].formId);
      q.formId = formList[0].formId;
    } else {
      q.formId = selectedFormType;
    }

    if (selectedLocationIds.length > 0) {
      q["location.lids"] = selectedLocationIds;
    } else {
      delete q["location.lids"];
    }

    if (selectedStaff.length > 0) {
      q["createdBy.ids"] = selectedStaff;
    } else {
      delete q["createdBy.ids"];
    }

    if (selectedFormSection !== undefined && selectedFormSection !== "ALL") {
      q["formSectionIds"] = [selectedFormSection];
    }

    if (
      selectedFormWhereConditionValues !== undefined &&
      selectedFormWhereConditionValues.length > 0
    ) {
      q["whereFields"] = selectedFormWhereConditionValues;
    } else {
      delete q["whereFields"];
    }

    if (selectedFormFieldIds !== undefined && selectedFormFieldIds.length > 0) {
      q["formFieldIds"] = selectedFormFieldIds;
    } else {
      delete q["formFieldIds"];
    }

    dispatch(reportThunk(q));
  };

  useEffect(() => {
    const now = new Date();
    const day = now.getDate();
    const month = now.getMonth();
    const year = now.getFullYear();
    const lastMonth = new Date(year, month, 1, 0, 0, 0);
    const today = new Date(year, month, day, 23, 59, 59);
    setSelectedFromDate(lastMonth);
    setSelectedToDate(today);
    onFormTypeChanged("PV"); // Initialize it
  }, []);

  /**
   * When the selected form section changes update the
   * list of form fields within that section
   */
  useEffect(() => {
    const section: ExtFormSection | undefined = sectionOptionsList.find(
      (s) => s.sid === selectedFormSection
    );
    if (section !== undefined) {
      setSelectedFormFieldIds(section.fields.map((f) => f.fid));
    }
  }, [selectedFormSection]);

  /**
   * Compile the list of sections for the section filter selector
   */
  useEffect(() => {
    if (formList === undefined || selectedFormType === undefined) {
      return;
    }
    const selectedFormList = formList.filter((form) => {
      return form.formId === selectedFormType;
    });

    if (selectedFormList.length > 0) {
      const sectionList = selectedFormList[0].sections;

      const sectionOptList: Array<ExtFormSection> = [
        { sid: "ALL", title: "All Sections", fields: [], showTitle: false },
        ...sectionList,
      ];
      setSectionOptionsList(sectionOptList);
    }
  }, [formList, selectedFormType]);

  useEffect(() => {
    setIsNetwork(false);
  }, [repSelector]);

  return (
    <React.Fragment>
      <section className="reports search-criteria input-section">
        <Form className="ext-form">
          {showFormTypeSelector && (
            <Form.Group as={Row} className="mb-3" controlId="reportType">
              <Form.Label column md={3} aria-label="Report Type">
                Form Type:
              </Form.Label>
              <Col md={9}>
                <Form.Select
                  onChange={(e) => onFormTypeChanged(e.target.value)}
                  value={selectedFormType}
                >
                  {formList.map((form: ExtForm, idx: number) => {
                    return (
                      <option key={form.formId} value={form.formId}>
                        {form.label}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Form.Group>
          )}

          <Form.Group as={Row} className="mb-3" controlId="fromDate">
            <Form.Label column md={3} aria-label="From Date">
              From Date:
            </Form.Label>
            <Col md={9}>
              <ReactDatePicker
                dateFormat="dd/MM/yyyy"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                //selected={selectedDate}
                onChange={onFromDateChanged}
                showTimeSelect={false}
                selected={fromDate}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="toDate">
            <Form.Label column md={3} aria-label="To Date">
              To Date:
            </Form.Label>
            <Col md={9}>
              <ReactDatePicker
                dateFormat="dd/MM/yyyy"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                //selected={selectedDate}
                onChange={onToDateChanged}
                showTimeSelect={false}
                selected={toDate}
              />
            </Col>
          </Form.Group>

          {showSectionFieldSelector && (
            <Form.Group as={Row} className="mb-3" controlId="onlySection">
              <Form.Label column md={3} aria-label="Only Include Section">
                Only Section:
              </Form.Label>
              <Col md={9}>
                <Form.Select
                  onChange={(e) => setSelectedFormSection(e.target.value)}
                  value={selectedFormSection}
                >
                  {sectionOptionsList.map(
                    (sec: ExtFormSection, idx: number) => {
                      return (
                        <option key={sec.sid} value={sec.sid}>
                          {sec.title}
                        </option>
                      );
                    }
                  )}
                </Form.Select>
              </Col>
            </Form.Group>
          )}

          {showStaffSelector && (
            <Form.Group
              as={Row}
              className="mb-3 checklist-scroll"
              controlId="staff"
            >
              <Form.Label column md={3} aria-label="staff">
                Only Staff:
              </Form.Label>
              <Col md={9}>
                {staffList.map((staff: ExtUser) => {
                  return (
                    <Form.Check
                      type={isTabletOrMobile ? "switch" : "checkbox"}
                      id={staff._id}
                      label={`${staff.lastName}, ${staff.firstName}`}
                      key={staff._id}
                      checked={
                        selectedStaff.indexOf(staff._id!) > -1 ? true : false
                      }
                      onChange={(e: any) => {
                        onStaffClicked(staff._id!, e.target.checked);
                      }}
                    />
                  );
                })}
              </Col>
            </Form.Group>
          )}

          {showGroupByLineOfBusiness && (
            <Form.Group as={Row} className="mb-3" controlId="lob" required>
              <Form.Label column md={3} aria-label="lob">
                Only LoBs:
              </Form.Label>
              <Col md={9} className="checklist-scroll">
                {LineOfBusinessList.map((l: ExtLineOfBusiness) => {
                  return (
                    <Form.Check
                      type={isTabletOrMobile ? "switch" : "checkbox"}
                      id={l.lid}
                      label={l.name}
                      key={l.lid}
                      name={l.name}
                      checked={
                        selectedLobIds.indexOf(l.lid) > -1 ? true : false
                      }
                      onChange={(e: any) => {
                        onLobClicked(l.lid, e.target.checked);
                      }}
                    />
                  );
                })}
              </Col>
            </Form.Group>
          )}

          {showGroupByRegionSelector && (
            <Form.Group as={Row} className="mb-3" controlId="regions" required>
              <Form.Label column md={3} aria-label="regions">
                Only Regions:
              </Form.Label>
              <Col md={9} className="checklist-scroll">
                {ExtRegionList.map((reg: ExtRegion) => {
                  return (
                    <Form.Check
                      type={isTabletOrMobile ? "switch" : "checkbox"}
                      id={reg.rid}
                      label={reg.name}
                      key={reg.rid}
                      name={reg.name}
                      checked={
                        selectedRegionIds.indexOf(reg.rid) > -1 ? true : false
                      }
                      onChange={(e: any) => {
                        onRegionClicked(reg.rid, e.target.checked);
                      }}
                    />
                  );
                })}
              </Col>
            </Form.Group>
          )}

          {showLocationSelector && (
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="defaultLocation"
              required
            >
              <Form.Label column md={3} aria-label="Locations">
                Only Locations:
              </Form.Label>
              <Col md={9} className="checklist-scroll">
                {locationsList.map((loc: ExtLocation) => {
                  return (
                    <Form.Check
                      type={isTabletOrMobile ? "switch" : "checkbox"}
                      id={loc._id}
                      label={loc.name}
                      key={loc._id}
                      name={loc.name}
                      checked={
                        selectedLocationIds.indexOf(loc._id) > -1 ? true : false
                      }
                      onChange={(e: any) => {
                        onLocationClicked(loc._id, e.target.checked);
                      }}
                    />
                  );
                })}
              </Col>
            </Form.Group>
          )}

          {showFormFieldValueSelector && selectedForm && (
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="fieldValues"
              required
            >
              <Form.Label column md={3} aria-label="Locations">
                Where Conditions:
              </Form.Label>
              <Col md={9} className="checklist-scroll">
                {selectedForm.sections.map((section) => {
                  return section.fields.map((field) => {
                    return (
                      <Form.Check
                        disabled={
                          field.control === ExtControlTypes.Duration ||
                          field.control === ExtControlTypes.Text ||
                          field.control === ExtControlTypes.TextField
                        }
                        type={isTabletOrMobile ? "switch" : "checkbox"}
                        id={field.fid}
                        label={field.label}
                        key={field.fid}
                        name={field.fid}
                        onChange={(e: any) => {
                          onFieldCriteraClicked(field, e.target.checked);
                        }}
                      />
                    );
                  });
                })}
              </Col>
              {selectedFormWhereFields.length > 0 && (
                <>
                  <Form.Label
                    column
                    md={3}
                    aria-label="Locations"
                    className="mt-2"
                  >
                    Where Values:
                  </Form.Label>
                  <Col md={9} className="field-list mt-2">
                    {selectedFormWhereFields.map((field: ExtFormField) => {
                      if (field.control === ExtControlTypes.Duration) {
                        return <></>;
                      }
                      return (
                        <FormFieldRenderer
                          field={field}
                          key={field.fid}
                          error={undefined}
                          record={undefined}
                          getFormFieldError={() => {}}
                          onChange={(value: ExtFormFieldValue) => {
                            onSetWhereValue(value);
                          }}
                          trackedAtDate={undefined}
                        />
                      );
                    })}
                  </Col>
                </>
              )}
            </Form.Group>
          )}

          <section className="form-controls">
            <Button
              variant="primary"
              className="float-right"
              onClick={onGenerateReport}
              disabled={isNetwork}
            >
              {isNetwork ? "Generating now..." : "Generate Report"}
            </Button>
          </section>
        </Form>
      </section>
    </React.Fragment>
  );
};

export default ReportSearchCriteria;
