import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import "../FormRenderer.scss";
import ReportSearchCriteria from "./ReportSearchCriteria";
import { Col, Form, Row } from "react-bootstrap";
import "./Reports.scss";
import DataTable from "react-data-table-component";
import { CSVLink } from "react-csv";
import { selectAuthUser } from "../../../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { showNotification } from "../../../features/notifications/notificationSlice";
import { ExtPermissions } from "../../api/api.types";
import doesUserHavePermission from "../../../utils/doesUserHavePermission";
import {
  createSummaryReport,
  selectSummaryReport,
} from "../../../features/reports/summaryReportSlice";

interface CsvHeader {
  label: string;
  key: string;
}

const months = ["", "January", "February", "March", "April", "May", "June", 
"July", "August", "September", "October", "November", "December" ];

const ReportSummary = ({ isTabletOrMobile }: { isTabletOrMobile: boolean }) => {
  const summaryReport = useAppSelector(selectSummaryReport);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const authUser = useAppSelector(selectAuthUser);

  useEffect(() => {
    if (doesUserHavePermission(authUser, ExtPermissions.canCreateReports)) {
      // All good, they're allowed
    } else {
      const navNow = () => {
        dispatch(
          showNotification({
            header: "You can't do that",
            body: "You do not have permission to access reports.",
            type: "error",
          })
        );
        navigate("/");
      };
      navNow();
      return;
    }
  }, [authUser, dispatch, navigate]);

  const columns = [
    {
      name: "Location",
      selector: (row: any) => row.name,
      sortable: false,
      compact: false,
      center: false,
      wrap: true,
      maxWidth: "400px",
      
    },
    {
      name: "Patron Visitor",
      selector: (row: any) => row.PV,
      sortable: true,
      compact: true,
      center: true,
      wrap: true,
      grow: 2,
      maxWidth: "80px",
      minWidth: "0px",
    },
    {
      name: "Patron Interaction",
      selector: (row: any) => row.PI,
      sortable: true,
      compact: true,
      center: true,
      wrap: true,
      maxWidth: "80px",
      minWidth: "0px",
    },
    {
      name: "Staff Visitors",
      selector: (row: any) => row.SV,
      sortable: true,
      compact: true,
      center: true,
      wrap: true,
      maxWidth: "80px",
      minWidth: "0px",
    },
    {
      name: "Event Patron Visitors",
      selector: (row: any) => row.EVpatronVisitors,
      sortable: true,
      compact: true,
      center: true,
      wrap: true,
      maxWidth: "80px",
      minWidth: "0px",
    },
    {
      name: "Event Staff Visitors",
      selector: (row: any) => row.EVstaffVisitors,
      sortable: true,
      compact: true,
      center: true,
      wrap: true,
      maxWidth: "80px",
      minWidth: "0px",
    },
/*    {
      name: "Event Total Visitors",
      selector: (row: any) => row.EVtotalVisitors,
      sortable: true,
      compact: true,
      center: true,
      wrap: true,
      maxWidth: "80px",
      minWidth: "0px",
    },*/
    {
      name: "Total Visitors",
      selector: (row: any) => row.TotalVisitors,
      sortable: true,
      compact: true,
      center: true,
      wrap: true,
      maxWidth: "80px",
      minWidth: "0px",
    },
    {
      name: "CS Touch Points",
      selector: (row: any) => row.EScsTouchPoints,
      sortable: true,
      compact: true,
      center: true,
      wrap: true,
      maxWidth: "80px",
      minWidth: "0px",
    },
  ];

  const columnsByDate = [
    {
      name: "Year",
      selector: (row: any) => row.year,
      sortable: false,
      compact: true,
      center: true,
      wrap: true,
      maxWidth: "400px",
    },
    {
      name: "Month",
      selector: (row: any) => months[row.month],
      sortable: false,
      compact: true,
      center: true,
      wrap: true,
      grow: 2,
      maxWidth: "80px",
      minWidth: "0px",
    },
    {
      name: "Patron Visitor",
      selector: (row: any) => row.PV,
      sortable: true,
      compact: true,
      center: true,
      wrap: true,
      maxWidth: "80px",
      minWidth: "0px",
    },
    {
      name: "Patron Interaction",
      selector: (row: any) => row.PI,
      sortable: true,
      compact: true,
      center: true,
      wrap: true,
      maxWidth: "80px",
      minWidth: "0px",
    },
    {
      name: "Staff Visitors",
      selector: (row: any) => row.SV,
      sortable: true,
      compact: true,
      center: true,
      wrap: true,
      maxWidth: "80px",
      minWidth: "0px",
    },
    {
      name: "Event Patron Visitors",
      selector: (row: any) => row.EVpatronVisitors,
      sortable: true,
      compact: true,
      center: true,
      wrap: true,
      maxWidth: "80px",
      minWidth: "0px",
    },
    {
      name: "Event Staff Visitors",
      selector: (row: any) => row.EVstaffVisitors,
      sortable: true,
      compact: true,
      center: true,
      wrap: true,
      maxWidth: "80px",
      minWidth: "0px",
    },
    /*
    {
      name: "Event Total Visitors",
      selector: (row: any) => row.EVtotalVisitors,
      sortable: true,
      compact: true,
      center: true,
      wrap: true,
      maxWidth: "80px",
      minWidth: "0px",
    },*/
    {
      name: "Total Visitors",
      selector: (row: any) => row.TotalVisitors,
      sortable: true,
      compact: true,
      center: true,
      wrap: true,
      maxWidth: "80px",
      minWidth: "0px",
    },
    {
      name: "CS Touch Points",
      selector: (row: any) => row.EScsTouchPoints,
      sortable: true,
      compact: true,
      center: true,
      wrap: true,
      maxWidth: "80px",
      minWidth: "0px",
    },
  ];

  /**
   * Generate the headers necessary for a CSV document.
   */
  const getCsvHeadersByLocation = () => {
    const headers: Array<CsvHeader> = [
      {
        label: "Location",
        key: "name",
      },
      {
        label: "Patron Visitor",
        key: "PV",
      },
      {
        label: "Patron Interaction",
        key: "PI",
      },
      {
        label: "Staff Visitors",
        key: "SV",
      },
      {
        label: "Event Patron Visitors",
        key: "EVpatronVisitors",
      },
      {
        label: "Event Staff Visitors",
        key: "EVstaffVisitors",
      },
      /*{
        label: "Event Total Visitors",
        key: "EVtotalVisitors",
      },*/
      {
        label: "Total Visitors",
        key: "TotalVisitors",
      },
      {
        label: "CS Touch Points",
        key: "EScsTouchPoints",
      },
    ];

    return headers;
  };

  /**
   * Generate the headers necessary for a CSV document.
   */
  const getCsvHeadersByDate = () => {
    const headers: Array<CsvHeader> = [
      {
        label: "Year",
        key: "year",
      },
      {
        label: "Month",
        key: "month",
      },
      {
        label: "Patron Visitor",
        key: "PV",
      },
      {
        label: "Patron Interaction",
        key: "PI",
      },
      {
        label: "Staff Visitors",
        key: "SV",
      },
      {
        label: "Event Patron Visitors",
        key: "EVpatronVisitors",
      },
      {
        label: "Event Staff Visitors",
        key: "EVstaffVisitors",
      },
/*      {
        label: "Event Total Visitors",
        key: "EVtotalVisitors",
      },*/
      {
        label: "Total Visitors",
        key: "TotalVisitors",
      },
      {
        label: "CS Touch Points",
        key: "EScsTouchPoints",
      },
    ];

    return headers;
  };

  /**
   * Format the report data for CSV export.
   * @returns Array with large object of key/value pairs
   */
  const getCsvReportDataByLocation = (): Array<any> => {
    const rowList: Array<any> = [];
    summaryReport?.recordsByLocation.forEach((rec) => {
      const row: any = {};
      row["name"] = rec.name;
      row["PV"] = rec.PV;
      row["PI"] = rec.PI;
      row["SV"] = rec.SV;
      row["EVpatronVisitors"] = rec.EVpatronVisitors;
      row["EVstaffVisitors"] = rec.EVstaffVisitors;
      row["EVtotalVisitors"] = rec.EVtotalVisitors;
      row["EScsTouchPoints"] = rec.EScsTouchPoints;
      row["TotalVisitors"] = rec.TotalVisitors;
      rowList.push(row);
    });

    rowList.push(summaryReport?.totalRowByLocation[0]);
    return rowList;
  };

  /**
   * Format the report data for CSV export.
   * @returns Array with large object of key/value pairs
   */
  const getCsvReportDataByDate = (): Array<any> => {
    const rowList: Array<any> = [];
    summaryReport?.recordsByDate.forEach((rec) => {
      const row: any = {};
      row["year"] = rec.year;
      row["month"] = months[rec.month];//rec.month;
      row["PV"] = rec.PV;
      row["PI"] = rec.PI;
      row["SV"] = rec.SV;
      row["EVpatronVisitors"] = rec.EVpatronVisitors;
      row["EVstaffVisitors"] = rec.EVstaffVisitors;
      row["EVtotalVisitors"] = rec.EVtotalVisitors;
      row["EScsTouchPoints"] = rec.EScsTouchPoints;
      row["TotalVisitors"] = rec.TotalVisitors;
      rowList.push(row);
    });

    rowList.push(summaryReport?.totalRowByDate[0]);
    return rowList;
  };

  /**
   * Generate a filename based on the report type and date
   * @returns string the file name
   */
  const getCsvFileNameForLocations = () => {
    const name = `SummaryReport-ByLocation-${new Date().toDateString()}.csv`;
    const re = / /gi;
    const result = name.replace(re, "_");
    return result;
  };

  const getCsvFileNameForDate = () => {
    const name = `SummaryReport-ByDate-${new Date().toDateString()}.csv`;
    const re = / /gi;
    const result = name.replace(re, "_");
    return result;
  };

  return (
    <React.Fragment>
      <section className="reports ">
        <h2>Summary Report</h2>
        <p>
          The Summary Report generates totals by location. This is calculated
          slightly differently depending on the form type:
        </p>
        <ul className="small">
          <li>
            Patron Info Visitor: total number of PIV forms submitted for the
            selected date range.
          </li>
          <li>
            Patron Interaction: total number of PI forms submitted for the
            selected date range.
          </li>
          <li>
            Gaming Staff Visitor: total number of GSV forms submitted for the
            selected date range.
          </li>
          <li>
            Event Patron Visitors: total number of patron visitors to events for
            the selected date range.
          </li>
          <li>
            Event Staff Visitors: total number of Casino/Bingo and Horse Racing
            staff visitors to events for the selected date range.
          </li>
          <li>
            Total Visitors: The sum of Patron Info Visitor, Patron Interaction, Gaming Staff Visitor,
            Event Patron Visitors and Event Staff visitors.
          </li>
          <li>
            CS Touch Points: the sum of all End of Shift Summary &gt; Total
            Customer Service Touch points for the selected date range.
          </li>
        </ul>

        <hr />
        <ReportSearchCriteria
          isTabletOrMobile={isTabletOrMobile}
          reportSelector={selectSummaryReport}
          reportThunk={createSummaryReport}
          showFormTypeSelector={false}
          showStaffSelector={false}
          showLocationSelector={true}
          showGroupByRegionSelector={true}
          showGroupByLineOfBusiness={true}
        />

        {summaryReport && (
          <section className="report">
            <Form className="ext-form">
              <Row className="header">
                <Col sm={5}>
                  <h3>Report</h3>
                </Col>
                <Col sm={4}>
                  <CSVLink
                    data={getCsvReportDataByDate()}
                    headers={getCsvHeadersByDate()}
                    filename={getCsvFileNameForDate()}
                    className="csv"
                  >
                    Export By Date CSV
                  </CSVLink>
                </Col>
                <Col sm={3}>
                  <CSVLink
                    data={getCsvReportDataByLocation()}
                    headers={getCsvHeadersByLocation()}
                    filename={getCsvFileNameForLocations()}
                    className="csv"
                  >
                    Export By Location CSV
                  </CSVLink>
                </Col>
              </Row>

              <Row>
                <section className="input-section">
                  <h4>Summary Report</h4>
                  <Form.Group as={Row} className="mb-3" controlId="reportType">
                    <Form.Label column md={4} aria-label="Reported At">
                      Report Created At:
                    </Form.Label>
                    <Col md={8}>
                      <Form.Control
                        as="input"
                        value={`${new Date(
                          summaryReport.createdAt
                        ).toDateString()} @ ${new Date(
                          summaryReport.createdAt
                        ).toLocaleTimeString()}`}
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                </section>
              </Row>

              <Row className="data-table">
                <DataTable
                  columns={columnsByDate}
                  data={summaryReport.recordsByDate}
                  dense={isTabletOrMobile}
                  fixedHeader
                  responsive
                  subHeaderWrap
                  highlightOnHover
                  pointerOnHover
                  striped
                />
              </Row>
              <Row className="data-table total">
                <DataTable
                  columns={columnsByDate}
                  data={summaryReport.totalRowByDate}
                  dense={isTabletOrMobile}
                  responsive
                  highlightOnHover
                  pointerOnHover
                  striped
                  noHeader={true}
                />
              </Row>

              <Row className="data-table mt-5">
                <DataTable
                  columns={columns}
                  data={summaryReport.recordsByLocation}
                  dense={isTabletOrMobile}
                  fixedHeader
                  responsive
                  subHeaderWrap
                  highlightOnHover
                  pointerOnHover
                  striped
                />
              </Row>
              <Row className="data-table total">
                <DataTable
                  columns={columns}
                  data={summaryReport.totalRowByLocation}
                  dense={isTabletOrMobile}
                  responsive
                  highlightOnHover
                  pointerOnHover
                  striped
                  noHeader={true}
                />
              </Row>
            </Form>
          </section>
        )}
      </section>
    </React.Fragment>
  );
};

export default ReportSummary;
