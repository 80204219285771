import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import DataTable from 'react-data-table-component';
import { ExtAudienceTypes, ExtEventType, ExtLocation } from '../../api/api.types';
import { useNavigate } from 'react-router-dom';

import { findDeletedEventTypes, findEventTypes, selectDeletedEventTypes, selectEventTypes, selectEventTypesNetStatus } from '../../../features/events/eventTypeSlice';


const EventTypeList = ({activeEvents}:{activeEvents:boolean}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const eventTypes = useAppSelector(selectEventTypes);
  const deletedEventTypes = useAppSelector(selectDeletedEventTypes);
  const eventTypesStatus = useAppSelector(selectEventTypesNetStatus);


  useEffect(()=>{
    if (activeEvents){
        dispatch(findEventTypes());
    }else{
        dispatch(findDeletedEventTypes());
    }
   
  }, [activeEvents, dispatch])


  const columns = [
    {
      name: 'Event Type',
      selector: (row:any) => row.label,
      sortable: true,
      grow:2,
    },
    {
      name: 'Audience',
      selector: (row:any) => {
        const aud = ExtAudienceTypes.find( t => t.audienceId === row.audienceId)
        if (aud){
          return aud.label;
        }
        return "Missing";
      },
      sortable: true,
      grow:3,
    },
    {
      name: 'Updated At',
      selector: (row: any) => {
        if (row.updatedAt === undefined){
            return "Never";
        }
        return new Date(row.updatedAt).toDateString();
      },
      sortable: true,
      sortField: "updatedAt"
    },
   
];

const onEventClicked = (loc:ExtEventType) =>{
  navigate(`/admin/event-types/edit/${loc._id}`);
}

const paginationComponentOptions = {
  selectAllRowsItem: true,
  selectAllRowsItemText: 'All',
};
  
  return (
      <section className='user-list'>
        <DataTable
              columns={columns}
              data={ activeEvents ? eventTypes : deletedEventTypes}
              expandableRowsHideExpander
              fixedHeaderScrollHeight="300px"
              pagination
              responsive
              subHeaderWrap
              highlightOnHover
              pointerOnHover
              progressPending={eventTypesStatus === 'loading'}
              paginationComponentOptions={paginationComponentOptions}
              striped
              onRowClicked={(loc)=>onEventClicked(loc)}
          />
      </section>
  );
}


export default EventTypeList;