import React, {  useCallback, useEffect,  useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import { ExtDataTypes, ExtFormField, ExtFormRecord, ExtFormRecordDataDuration } from '../../api/api.types';
import { ExtFormFieldError, ExtFormFieldValue } from '../FormRenderer';



const ExtDuration = ({ field, isTabletOrMobile, onChange, existingValue, error, showTime, record,  getFormFieldError, trackedAtDate}: 
    { 
        field: ExtFormField, 
        isTabletOrMobile:boolean, 
        onChange:Function, 
        existingValue:ExtFormRecordDataDuration, 
        error:ExtFormFieldError|undefined, 
        showTime:boolean 
        record:ExtFormRecord|undefined,
        getFormFieldError: Function, 
        trackedAtDate?:Date
    }) => {
  
 //   const isUnmountingRef = useRef<boolean>(false);

    const [selectedStartDate, setSelectedStartDate] = useState<Date>(new Date());
    const [selectedEndDate, setSelectedEndDate] = useState<Date>(new Date());
    const [durationHours, setDurationHours] = useState<string>("00");
    const [durationMinutes, setDurationMinutes] = useState<string>("00");
    const [isNegativeError, setIsNegativeError] = useState<boolean>(false);

     // If this is the first time we're seeing a value selected:
    const [hasBeenChanged, setHasBeenChanged] = useState<boolean>(false);
    


    const onChangeCallback = useCallback(args => {
        return onChange(args);
     }, [onChange])
 

    /**
     * Handle a change to the start date
     */
    const onStartDateChanged = useCallback( ( newDate:Date)=>{
        if (newDate === null){
            return;
        }

       
        // Add/remove from our list of selected values:
        if (! hasBeenChanged){
            setHasBeenChanged(true);
        }

       
        setSelectedStartDate(newDate);
          
    }, [ hasBeenChanged] );


    /**
     * Handle a change to the end date
     */
    const onEndDateChanged = useCallback( ( newDate:Date)=>{
        if (newDate === null){
            return;
        }

        // Add/remove from our list of selected values:
        if (! hasBeenChanged){
            setHasBeenChanged(true);
        }
    
        setSelectedEndDate(newDate);

    }, [ hasBeenChanged] );




    const setDurationTime = ()=>{

        const dateDiffMinutes = Math.round( ( selectedEndDate.getTime() - selectedStartDate.getTime() ) / 1000 / 60 );

        if (dateDiffMinutes <= 0 ){
            // Can't have a negative value and be valid:
            setDurationHours("--");
            setDurationMinutes("--");
            setIsNegativeError(true);
            // Wipe out any tracked values so we're invalid:
            const val:ExtFormFieldValue = {
                fid:      field.fid,
                dType:    field.dType,
                field:    field,
                value:    undefined,
                isError: true,
            }
            onChangeCallback(val);
            return;
        }

        setIsNegativeError(false);


   //     console.log("\n\nEnd: " , selectedEndDate, "\nStart", selectedStartDate, "\ndiff min: " , dateDiffMinutes);
        const hours = Math.floor(dateDiffMinutes / 60 );
        const mins = Math.floor( dateDiffMinutes % 60 );
  //      console.log( "hrs:", hours, "mins", mins)
        if (hours < 10){
            setDurationHours(`0${hours}`);
        }else{
            setDurationHours(hours.toString() );
        }
        
        if (mins < 10){
            setDurationMinutes(`0${mins}`);
        }else{
            setDurationMinutes(mins.toString() );
        }

        const val:ExtFormFieldValue = {
            fid:      field.fid,
            dType:    field.dType,
            field:    field,
            value:    {
                durationStart: {
                    fid:    field.durationStart.fid,
                    dType:  ExtDataTypes.DateTime,
                    value:  selectedStartDate,
                },
                durationEnd: {
                    fid:    field.durationEnd.fid,
                    dType:  ExtDataTypes.DateTime,
                    value:  selectedEndDate,
                  },
                diffMin: dateDiffMinutes,
            },
            isError: error ? true: false,
        }
        onChangeCallback(val);
    }


    useEffect(()=>{
       // console.log("Selected value changed")
        setDurationTime();
    }, [selectedEndDate, selectedStartDate])
   

    useEffect(()=>{
    //    console.log("Existin value fire", hasBeenChanged);
        if (hasBeenChanged){
            // User made a change, don't get caught in a loop
        //   return; (disabled to we can load data )
        }

        if (existingValue === undefined){
            onStartDateChanged( new Date() );
            return;
        }
        
        onStartDateChanged( new Date(existingValue.durationStart.value) );
        onEndDateChanged( new Date(existingValue.durationEnd.value) );
    }, [existingValue, hasBeenChanged, onStartDateChanged, onEndDateChanged])




    useEffect(()=>{
        return ()=>{
           
            const val:ExtFormFieldValue = {
                fid: field.fid,
                field: field,
                dType:  field.dType,
                value: undefined,
                isError: error?  true : false,
            }
            onChangeCallback(val);
        }
    },[])


    /**
     * To avoid dependency hell and ensure our main cleanup logic
     * only fires once, use a ref to track if we are unmounting.
    
       useEffect(()=>{
        return ()=> {
            isUnmountingRef.current = true;
        }
    }, [])
    


    /**
     * Cleanup effect to remove value for removed fields
     * (e.g. children that are disabled)
     
         useEffect(()=>{
            if(isUnmountingRef.current === true){
                return ()=>{
                    const val:ExtFormFieldValue = {
                        fid: field.fid,
                        field: field,
                        dType:  field.dType,
                        value: undefined,
                        isError: error?  true : false,
                    }
                    onChangeCallback(val);
                }
            }
        },[isUnmountingRef, error, field, onChangeCallback])
        */
    
    return <Form.Group as={Row} className="mb-3 duration-wrapper" key={field.fid}>
   
        <h6>{field.label}</h6>
        {error?.fid && <p className='error'>{error?.errorMessage}</p>}

        <Form.Group as={Row} className="mb-3 " key={field.durationStart.fid}>
            <Form.Label column md={4}>{field.required && '*'}{field.durationStart.label}:</Form.Label>
            <Col sm={8} key={field.durationStart.fid} >
                <ReactDatePicker
                    dateFormat="MM/dd/yyyy h:mm aa"
                    selected={selectedStartDate}
                    onChange={onStartDateChanged} //only when value has changed
                    showTimeSelect={showTime}       
                    timeIntervals={15}            
                />
                
               
            </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" key={field.durationEnd.fid}>
            <Form.Label column md={4}>{field.required && '*'}{field.durationEnd.label}:</Form.Label>
            <Col sm={8} key={field.durationEnd.fid} >
                <ReactDatePicker
                    dateFormat="MM/dd/yyyy h:mm aa"
                    selected={selectedEndDate}
                    onChange={onEndDateChanged} //only when value has changed
                    showTimeSelect={showTime}       
                    timeIntervals={15}            
                />

            </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" key={`duration-${field.fid}`}>
            <Form.Label column md={4}>Duration:</Form.Label>
            <Col sm={8} key={field.durationEnd.fid} >
                <Form.Control
                    className='duration-display'
                    readOnly
                    as='input'
                    id={field.fid}
                    value={`${durationHours}:${durationMinutes}`}
                />
                {field.hint && <p className='hint'>{field.hint}</p>}
                {isNegativeError && <p className='error'>Duration cannot be zero or negative.</p>}
            </Col>
        </Form.Group>
</Form.Group>
}

export default ExtDuration;