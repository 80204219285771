import React from 'react';
//import { Navigate } from 'react-router-dom';
import { selectIsAuthenticated } from '../../features/auth/authSlice';
import { useAppSelector } from '../hooks';

const PrivateRoute = ({ children }: { children: React.ReactNode }) => {
    const isAuthenticated = useAppSelector(selectIsAuthenticated);

    return <React.Fragment>
        { isAuthenticated ? children : children } {//<Navigate to="/login" />
        }
    </React.Fragment>
  }
  
  export default PrivateRoute