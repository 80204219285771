import { createAsyncThunk,  createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { FeathersError, serializeFeathersError } from '../../app/api/serializeFeathersError';
import {  ExtUser } from '../../app/api/api.types';
import {  apiFindStaff } from '../../app/api/api';


/**
 * Async thunk state
 */
export interface StaffState {
  list: Array<ExtUser>;
  status: 'idle' | 'loading' | 'failed';
  error: FeathersError|undefined,
  pagination: {
    limit: number,
    skip: number,
    total: number,
  },
}

const initialState: StaffState = {
  list: [],
  status: 'idle',
  error: undefined,
  pagination: {
    limit: 0,
    skip: 0,
    total: 0,
  },
};



/**
 * Get staff list
 */
export const getStaff = createAsyncThunk(
  'Staff/find',
  async ( _, { rejectWithValue }) => {
    try{
      const response = await apiFindStaff();
      return response;
    }catch(err){
      const e = serializeFeathersError(err)
      return rejectWithValue(e);
    }
  }
);





/**
 * Creates the Redux Slice 
 */
export const StaffSlice = createSlice({
  name: 'Staff',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      
      .addCase(getStaff.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getStaff.fulfilled, (state, action) => {
        state.status = 'idle';
        state.list = action.payload.data;
        state.list.sort((a, b) =>{
            return (a.lastName < b.lastName ? -1 : 1)
          }) ;

        state.error = undefined;
      })
      
  },
});


/**
 * Create the Redux Selector to access  state data.
 * @param state RootState
 * @returns Array of all Staff
 */
export const selectStaffList = (state: RootState) => state.staff.list
export const selectStaffNetStatus = (state: RootState) => state.staff.status;


export default StaffSlice.reducer;
