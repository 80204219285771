import React, {  useCallback, useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { selectAuthUser } from "../../features/auth/authSlice";
import { selectForms } from "../../features/forms/formsSlice";
import { ExtRecordSearchQuery } from "../../features/forms/recoredsSlice";
import { selectLocations } from "../../features/locations/locationsSlice";
import { selectSearchCriteria, updateSearchCriteria } from "../../features/search/searchSlice";
import { selectStaffList } from "../../features/users/staffSlice";
import doesUserHavePermission from "../../utils/doesUserHavePermission";
import {
  ExtForm,
  ExtLocation,
  ExtPermissions,
  ExtUser,
} from "../api/api.types";
import {  useAppDispatch, useAppSelector } from "../hooks";
import ReportListDataTable from "./RecordListDataTable";
import {DebounceInput} from 'react-debounce-input';


const SearchRecords = ({ isTabletOrMobile }: { isTabletOrMobile: boolean }) => {
  const dispatch = useAppDispatch(); 
  const authUser = useAppSelector(selectAuthUser);
  const formList = useAppSelector(selectForms);
  const locationsList = useAppSelector(selectLocations);
  const staffList = useAppSelector(selectStaffList);
  const searchCriteria = useAppSelector(selectSearchCriteria);

  const [fromDate, setSelectedFromDate] = useState<Date>();
  const [toDate, setSelectedToDate] = useState<Date>();

//  const [formInternalId, setFormInternalId] = useState<number|undefined>();

  const [query, setQuery] = useState<ExtRecordSearchQuery>();
 
//  const [isShowingIdWarning, setIsShowingIdWarning] = useState<boolean>(false);
  const [isShowingSeachCriteria, setIsShowingSearchCritera] = useState<boolean>(true);

  /**
   * When any of the search parameters change,
   * update the search query object
   */
  const updateQueryCallback = useCallback(
    () => {
      
      if (searchCriteria.fromDate ===searchCriteria.toDate){
        return;// State hasn't been initialized yet
      }
      let q: ExtRecordSearchQuery = {
        reportedAt: {
          $gte:searchCriteria.fromDate,
          $lt:searchCriteria.toDate,
        },
      };

      if (searchCriteria.reportType !== "ALL") {
        q.formId =searchCriteria.reportType;
      }

      if (searchCriteria.location !== "ALL") {
        q["location.lid"] = searchCriteria.location!;
      }else{
        delete(q["location.lids"]);
      }

      if (searchCriteria.author !== "ALL") {
        q["createdBy.id"] = searchCriteria.author;
      }else{
        delete(q["createdBy.id"]);
      }

      if (searchCriteria._id !== undefined){
        q["_id"] = searchCriteria._id;
      }
     
     setQuery(q);
    },
    [searchCriteria]
  );



  const onReportTypeChanged = (val: string) => {
    const newCriteria = { ...searchCriteria};
    newCriteria.reportType = val;
    dispatch(updateSearchCriteria(newCriteria));
  };

  const onLocationChanged = (val: string) => {
    const newCriteria = { ...searchCriteria};
    newCriteria.location = val;
    dispatch(updateSearchCriteria(newCriteria));
  };

  const onAuthorChanged = (val: string) => {
    const newCriteria = { ...searchCriteria};
    newCriteria.author = val;
    dispatch(updateSearchCriteria(newCriteria));
  };

  const onFromDateChanged = (val: Date) => {
    setSelectedFromDate(val);
    const newCriteria = { ...searchCriteria};
    newCriteria.fromDate = val.toISOString();
    dispatch(updateSearchCriteria(newCriteria));
  };

  const onToDateChanged = (val: Date) => {
    setSelectedToDate(val);
    
    const newCriteria = { ...searchCriteria};
    newCriteria.toDate = val.toISOString();
    dispatch(updateSearchCriteria(newCriteria));
  };




  const onFormIdChanged = (val: string) => {
    if (val.length > 0){
      const id = parseInt(val);

    //  setFormInternalId( id );
      setIsShowingSearchCritera(true);

      let q: ExtRecordSearchQuery = {
        internalId: id
      };

      setIsShowingSearchCritera(false);
      setQuery(q);

    }else{
   //   setFormInternalId( undefined);
      setIsShowingSearchCritera(true);
      updateQueryCallback();
    }
    

    /*
    if ( (val.length > 0 && val.length < 24) || (val.length > 24) ){
      setIsShowingSearchCritera(false);
      setIsShowingIdWarning(true);
    }else{
      setIsShowingIdWarning(false);
      setIsShowingSearchCritera(true);
    }

    if (val.length === 24 ){
      let q: ExtRecordSearchQuery = {
        _id: val
      };
      setIsShowingSearchCritera(false);
      setQuery(q);
    }

    if (val.length === 0){
      updateQueryCallback();
    }
    */
  }


  /**
   * Set initial values on load:
   */
  useEffect(() => {
    if (searchCriteria.fromDate !==searchCriteria.toDate){
      // Populate the page info:
      setSelectedFromDate(new Date(searchCriteria.fromDate) );
      setSelectedToDate(new Date(searchCriteria.toDate));
      return;// State already set, keep existing
    }

    // Set default parameters for the search:
    const now = new Date();
    const day = now.getDate();
    const month = now.getMonth();
    const year = now.getFullYear();
    const lastWeek = new Date(year, month, day - 7, 0, 0, 0);
    const today = new Date(year, month, day, 23, 59, 59);
    setSelectedFromDate(lastWeek);
    setSelectedToDate(today);

    const newCriteria = { ...searchCriteria};
   
    newCriteria.reportType = 'ALL';
    newCriteria.location =   'ALL';
    newCriteria.author =     'ALL';
    newCriteria.fromDate =  lastWeek.toISOString();
    newCriteria.toDate =    today.toISOString();

   dispatch(updateSearchCriteria(newCriteria));
  }, [dispatch, searchCriteria]);

  
  /***
   * When the filtering criteria changes make a new network call:
   */
  useEffect(() => {
    //console.log('Search criteria changed', searchCriteria)
    updateQueryCallback();
  }, [searchCriteria, updateQueryCallback]);


  return (
    <React.Fragment>
      <Container className="input-form" fluid="xl">
        <h1>Search Form Submissions</h1>

        <Form>
          <section className="search-by-id">
            <Form.Group as={Row} className="mb-3" controlId="formId">
              <Form.Label column md={3} aria-label="Form ID">
                Form ID:
              </Form.Label>
              <Col md={9}>
               {/* <Form.Control
                    as='input'
                    onChange={e => onFormIdChanged( e.target.value) }
                    value={formInternalId}
  />*/} 
                <DebounceInput
                  type="text"
                  onChange={event => onFormIdChanged(event.target.value)}
                  placeholder=""
                  className="form-control"
                  debounceTimeout={300}
                  />
                {/*isShowingIdWarning && <p className="help">The ID must be 24 characters long.</p>*/ }
              </Col>
            </Form.Group>
          </section>
          {isShowingSeachCriteria && <section className="search-form">
            <Form.Group as={Row} className="mb-3" controlId="reportType" >
              <Form.Label column md={3} aria-label="Report Type">
                Report Type:
              </Form.Label>
              <Col md={9}>
                <Form.Select
                  onChange={(e) => onReportTypeChanged(e.target.value)}
                  value={searchCriteria.reportType}
                >
                  <option value={"ALL"}>All</option>
                  {formList.map((form: ExtForm, idx: number) => {
                    return (
                      <option key={form.formId} value={form.formId}>
                        {form.label}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="location">
              <Form.Label column md={3} aria-label="Location">
                Location:
              </Form.Label>
              <Col md={9}>
                <Form.Select
                  onChange={(e) => onLocationChanged(e.target.value)}
                  value={searchCriteria.location}
                >
                  <option value={"ALL"}>All</option>
                  {locationsList.map((location: ExtLocation, idx: number) => {
                    return (
                      <option key={location._id} value={location._id}>
                        {location.name}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Form.Group>

            { doesUserHavePermission(authUser, ExtPermissions.canViewOthersForms)  &&
            <Form.Group as={Row} className="mb-3" controlId="author">
              <Form.Label column md={3} aria-label="Author">
                Author:
              </Form.Label>
              <Col md={9}>
                <Form.Select
                  onChange={(e) => onAuthorChanged(e.target.value)}
                  value={searchCriteria.author}
                >
                  <option value={"ALL"}>All</option>
                  {staffList.map((staff: ExtUser, idx: number) => {
                    return (
                      <option key={staff._id} value={staff._id}>
                        {staff.lastName}, {staff.firstName}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Form.Group> }

            <Form.Group as={Row} className="mb-3" controlId="fromDate">
              <Form.Label column md={3} aria-label="From Date">
                From Date:
              </Form.Label>
              <Col md={9}>
                <ReactDatePicker
                  dateFormat="dd/MM/yyyy"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  //selected={selectedDate}
                  onChange={onFromDateChanged}
                  showTimeSelect={false}
                  selected={fromDate }
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="toDate">
              <Form.Label column md={3} aria-label="To Date">
                To Date:
              </Form.Label>
              <Col md={9}>
                <ReactDatePicker
                  dateFormat="dd/MM/yyyy"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  //selected={selectedDate}
                  onChange={onToDateChanged}
                  showTimeSelect={false}
                  selected={toDate }
                />
              </Col>
            </Form.Group>

            
          </section> }

          <ReportListDataTable
             isTabletOrMobile={isTabletOrMobile}
             query={query}
          />
        </Form>
      </Container>
    </React.Fragment>
  );
};

export default SearchRecords;
