import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {apiLoginUser, apiReAuthenticate, apiLogoutUser} from "../../app/api/api";
import { serializeFeathersError } from '../../app/api/serializeFeathersError';
import {  ExtUser } from '../../app/api/api.types';


export interface UserState {
    data:ExtUser|undefined,
    isAuthenticated: boolean,
    isError: boolean,
    error:any,
    errorCode: number,
    status: 'idle' | 'loading' | 'failed';
  }
  
  const initialState: UserState = {
    data: undefined,
    isAuthenticated: false,
    isError: false,
    error: null,
    errorCode: 0,
    status: 'idle',
  };

  

export const login = createAsyncThunk(
    'auth/login', 
    async ( {email, password, remember = false}:{email:string, password:string, remember:boolean}, { rejectWithValue }   ) => {
  try{
    let result;
    if (email === '') {
      result  = await apiReAuthenticate().catch( (err:any)=> {
         const e = serializeFeathersError(err)
         rejectWithValue(e);
         return
      });
      if (result === undefined){
        return initialState.data;
      }
    }else{
      result = await apiLoginUser({email: email, password: password, remember: remember}).catch( (err:any)=> {
        const e = serializeFeathersError(err)
         rejectWithValue(e);
         return;
      });
    }
    if (result.code !== undefined){
      throw result
    }
    
    return result.user;
  }catch(err){
  
    const e = serializeFeathersError(err)
    return rejectWithValue(e);

  }

})



export const logout = createAsyncThunk('auth/logout', async () => {
  try {
    await apiLogoutUser();
 //   const dispatch = useDispatch();
 //   dispatch(logoutUser())
    return {user:initialState};
  } catch (e) {
    return console.error(e);
  }
});




// --- Slice Logic ---


export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    // You can have regular reducers as needed.
    updateUser: (state, params) => {
      try{
      
      }catch(err){
        // Concurrent changes to the user object can cause conflict
      }
    },
    logoutUser: (state, params) => {
      state = initialState;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = 'loading';
        state.isAuthenticated = false;
      })
      .addCase(login.rejected, (state, action) => {
        state.status = 'failed';
        state.isError = true;
        state.error = action.payload
        state.errorCode = state.error.code;
        state.isAuthenticated = false;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = 'idle';
        
        if (action.payload === undefined){
            state.isAuthenticated = false;
        }else{
            state.isAuthenticated = true;
            state.data = action.payload;
        }
      });
  },
});

// --- Selectors ---
//export const { updateUser, logoutUser } = userSlice.actions


export const selectIsAuthenticated = (state: RootState) => state.auth.isAuthenticated;
export const selectAuthUser = (state: RootState) => state.auth.data;


export default authSlice.reducer;
