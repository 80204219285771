import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
  apiCreateSumReport,
} from "../../app/api/api";
import { serializeFeathersError } from "../../app/api/serializeFeathersError";
import { ExtRecordSearchQuery } from "../forms/recoredsSlice";
import { ExtControlTypes, ExtDataTypes } from "../../app/api/api.types";



export interface AggResult {
  aid: string;
  label: string;
  count: number;
  sumTotal?:number,
}


export interface ExtSumReportElement {
  dType: ExtDataTypes,
  control: ExtControlTypes,
  key: string;
  values: Array<AggResult>;
}

export interface ExtSumReportSection {
  sid: string;
  title: string;
  elements: Array<ExtSumReportElement>;
}

export interface ExtSumReport {
  criteria: ExtRecordSearchQuery;
  createdAt: Date;
  sections: Array<ExtSumReportSection>;
  recordCount: number;
}


export interface SumReportState {
  value: ExtSumReport | undefined;
  status: "idle" | "loading" | "failed";
}

const initialState: SumReportState = {
  value: undefined,
  status: "idle",
};

/**
 * Submit the report criteria
 */
export const createSumReport = createAsyncThunk(
  "reporting/sum-report/create",
  async (query: ExtRecordSearchQuery, { rejectWithValue }) => {
    try {
      const response = await apiCreateSumReport(query);
      return response;
    } catch (err) {
      const e = serializeFeathersError(err);
      return rejectWithValue(e);
    }
  }
);

/**
 * Creates the Redux Slice for the forms
 */
export const sumReportSlice = createSlice({
  name: "reporting/sum-report",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(createSumReport.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createSumReport.fulfilled, (state, action) => {
        state.status = "idle";
        state.value = action.payload;
      });
  },
});

/**
 * Create the Redux Selector to access form state data.
 * @param state RootState
 * @returns Array of all the locations
 */
export const selectSumReport = (state: RootState) => state.sumReport.value;

export default sumReportSlice.reducer;
