import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';


export interface ExtNotificationState {
  value: ExtNotification|undefined;
}


export interface ExtNotification {
    header: string,
    body: string,
    type: 'success' | 'error'
}


const initialState: ExtNotificationState = {
  value: undefined
};

/**
 * Get all the locations
 */
export const showNotification = createAsyncThunk(
  'notifications/show',
   ( notification:ExtNotification, { rejectWithValue }) => {
  
    return notification;
  }
);

/**
 * Creates the Redux Slice for the forms
 */
export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
  },

  extraReducers: (builder) => {
   
    builder
        .addCase(showNotification.fulfilled, (state, action) => {
            state.value = action.payload
        })
        
    },

});



export const selectNotifications = (state: RootState) => state.notifications.value;



export default notificationsSlice.reducer;
