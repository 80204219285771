import React, { useCallback, useEffect, useState } from 'react';
import {  useNavigate } from 'react-router-dom';
import { login } from '../../features/auth/authSlice';
import { useAppDispatch } from '../hooks';

const ReAuthenticate = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [init, setInit] = useState<boolean>(false);
    
    /**
     * Create dispatch callback to avoid
     * dependency hell in useEffect
     */
    const dispatchCallback = useCallback(args => {
       return dispatch(args);
    }, [dispatch])

    /**
     * Create navigate callback to avoid
     * dependency hell in useEffect
     */
    const navigateCallback = useCallback(args => {
        return navigate(args);
     }, [navigate])


    /**
     * Try to re-authenticate the user based on any existing 
     * auth tokens in storage. 
     * If the payload is undefined the authentication failed,
     * the user needs to login again.
     */
    useEffect(() => {
        const doLogin = async () => {
            if (init){
                return;
            }
            setInit(true);

            try {
                const result = await dispatchCallback(
                    login({ email: '', password: '', remember: false })
                );
              
                if (result.payload === undefined){
           //         navigateCallback('/login')
                }
            } catch (ex) {
                console.error(ex);
            }
        }
        doLogin();

    }, [dispatchCallback, navigateCallback, init])

  

    return <React.Fragment>
        
    </React.Fragment>
  }
  
  export default ReAuthenticate