import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import DataTable, {
  createTheme,
  ExpanderComponentProps,
} from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import {
  ExtRecordSearchQuery,
  findRecords,
  selectRecords,
  selectRecordsNetStatus,
  selectRecordsPagination,
} from "../../features/forms/recoredsSlice";
import convertFormLabelToSlug from "../../utils/convertLabelToSlug";
import { ExtFormRecord, ExtFormRecordData } from "../api/api.types";
import { useAppDispatch, useAppSelector } from "../hooks";

/**
 * This component will render a table of Records
 * based on the query params passed down to it.
 */




const ReportListDataTable = ({
  query,
  isTabletOrMobile,
}: {
  query: ExtRecordSearchQuery | undefined;
  isTabletOrMobile: boolean;
}) => {
  const pagination = useAppSelector(selectRecordsPagination);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const records = useAppSelector(selectRecords);
  const recordsNetStatus = useAppSelector(selectRecordsNetStatus);

  const [queryTotal, setQueryTotal] = useState<number>(10);
  const [queryPage, setQueryPage] = useState<number>(1);

  const [isExpandAll, setIsExpandAll] = useState<boolean>(false);




/**
 * Define the column layout of the tabe
 */
const columns = [
  {
    name: "ID",
    selector: (row: any) => row.internalId,
    sortable: true,
    sortField: "internalId",
  },
  {
    name: "Type",
    selector: (row: any) => row.label,
    sortable: true,
    sortField: "formId",
  },
  {
    name: "Author",
    selector: (row: any) => {
      return `${row.createdBy.lastName}, ${row.createdBy.firstName}`;
    },
    sortable: true,
    sortField: "lastName",
  },
  {
    name: "Location",
    selector: (row: any) => row.location.name,
    sortable: true,
    sortField: "location.name",
  },
  {
    name: "Date",
    selector: (row: any) => {
      return new Date(row.reportedAt).toDateString();
    },
    sortable: true,
    sortField: "reportedAt",
  },
  {
    name:"Open",
    cell: (row:any) => <Button variant="primary" size="sm" onClick={()=>openInNewTab(row)}>&#x2197;</Button>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
  } 
];


  const onRowClicked = (record: ExtFormRecord) => {
    const slug = convertFormLabelToSlug(record.label!);
    navigate(`/forms/${slug}/${record._id}`);
    //window.open(`${window.location.origin}/forms/${slug}/${record._id}`)
  }

  const openInNewTab = (record: ExtFormRecord) => {
    const slug = convertFormLabelToSlug(record.label!);
    //navigate(`/forms/${slug}/${record._id}`);
    window.open(`${window.location.origin}/forms/${slug}/${record._id}`)
  }


  const handlePerRowsChange = (count: number) => {
    setQueryTotal(count);
  };

  const handlePageChange = (count: number) => {
    setQueryPage(count);
  };

  createTheme(
    "rgc-table",
    {
      striped: {
        default: "#C1E0F4",
      },
    },
    "light"
  );

  /**
   * Triggers the API request with query params
   * and pagination settings configured
   */
  const searchRecordsCallback = useCallback(
    (sortField: string | undefined, sortDirection: string | undefined) => {
      if (query === undefined) {
        return;
      }

      query.$limit = queryTotal;
      query.$skip = queryTotal * (queryPage - 1);
      query.$sort = {
        reportedAt: -1,
      };

      if (sortField !== undefined) {
        query.$sort = {
          [sortField]: sortDirection === "asc" ? 1 : -1,
        };
      }

      dispatch(findRecords(query));
    },
    [dispatch, queryPage, queryTotal, query]
  );

  /***
   * Sort directive changed, get new data with query:
   */
  const handleSort = async (column: any, sortDirection: any) => {
    searchRecordsCallback(column.sortField, sortDirection);
  };

  /**
   * When a row is expanded to show more details
   * @param param0 Row being expanded
   * @returns The HTML to render
   */
  const ExpandedComponent: React.FC<ExpanderComponentProps<ExtFormRecord>> = ({
    data,
  }) => {
    return renderDetails(data);
  };

  /**
   *
   * @param key Field ID key we're looking for
   * @param rec The entire Form Record
   * @returns The RecordData of the requested Field ID
   */
  const getRecordDataForKey = (key: string, rec: ExtFormRecord) => {
  
    return rec.data.find((r: ExtFormRecordData) => {
      return r.fid === key;
    });
  };


  /**
   * 
   * @param parent 
   * @param rec 
   * @returns 
   */
  const calculateDurationDataForKey = (parent:string, rec:ExtFormRecord) => {
  
    const data = getRecordDataForKey(parent, rec);
    if (data  === undefined){
      return;
    }
    const start = new Date(data.value.durationStart.value);
    const end = new Date(data.value.durationEnd.value);

    return renderElapsedTime(start, end);
  }

  /**
   * Trigger a new query when the parent query object
   * changes or any of the pagination controls update
   */
  useEffect(() => {
    if (query !== undefined) {
      searchRecordsCallback(undefined, undefined);
    }
  }, [query, queryPage, queryTotal, searchRecordsCallback]);

  /**
   * Render the details expansion box in table rows
   * @param rec The expended record
   * @returns HTML for the layout
   */
  const renderDetails = (rec: ExtFormRecord) => {
  //  console.log(rec);
    switch (rec.formId) {
      case "EV":
        return (
          <div className="row-details">
            <Form.Group as={Row}>
              <Form.Label column sm={1}>
                Type:
              </Form.Label>
              <Col sm={3}>
                <p>
                  {getRecordDataForKey("EV-INFO-AUDIENCE-EVENT-TYPE", rec)?.value.split('-')[2]}
                </p>
              </Col>
              <Form.Label column sm={1}>
                Location:
              </Form.Label>
              <Col sm={3}>
                <p>
                  {getRecordDataForKey("EV-INFO-LOCATION_VENUE", rec)?.value}
                </p>
              </Col>
              <Form.Label column sm={1}>
                Duration:
              </Form.Label>
              <Col sm={3}>
              <p>
                  {
                    calculateDurationDataForKey("EV-INFO-DURATION", rec)
                  }
                </p>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={2}>
                Event Visitors:
              </Form.Label>
              <Col sm={2}>
                <p>
                  {
                    getRecordDataForKey("EV-VISITORS-EVENT_VISITORS", rec)
                      ?.value
                  }
                </p>
              </Col>
              <Form.Label column sm={2}>
                Information Visitors:
              </Form.Label>
              <Col sm={2}>
                <p>
                  {getRecordDataForKey("EV-VISITORS-INFO_VISITORS", rec)?.value}
                </p>
              </Col>
              <Form.Label column sm={2}>
                Interaction Visitors:
              </Form.Label>
              <Col sm={2}>
                <p>
                  {
                    getRecordDataForKey("EV-VISITORS-INTERACTION_VISITORS", rec)
                      ?.value
                  }
                </p>
              </Col>
            </Form.Group>
            {renderLocationDate(rec)}
          </div>
        );
      case "PV":
        return (
          <div className="row-details">
            <Form.Group as={Row}>
              <Form.Label column sm={2}>
                Location in Venue:
              </Form.Label>
              <Col sm={3}>
                <p>
                  {getRecordDataForKey("PV-GENERAL-LOCATION_VENUE", rec)?.value}
                </p>
              </Col>
              <Form.Label column sm={2}>
                Duration of Visit:
              </Form.Label>
              <Col sm={3}>
                <p>
                  {getRecordDataForKey("PV-GENERAL-VISIT_DURATION", rec)?.value}{" "}
                  minutes
                </p>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm={2}>
                Gender:
              </Form.Label>
              <Col sm={3}>
                <p>
                  {getRecordDataForKey("PV-PROFILE-GENDER", rec)?.value}
                </p>
              </Col>
              <Form.Label column sm={2}>
                Age:
              </Form.Label>
              <Col sm={3}>
                <p>
                  {getRecordDataForKey("PV-PROFILE-AGE_GROUP", rec)?.value}{" "}
                  minutes
                </p>
              </Col>
            </Form.Group>
            {renderLocationDate(rec)}
          </div>
        );

      case "ES":
        return (
          <div className="row-details">
            <Form.Group as={Row}>
              <Form.Label column sm={2}>
                Activity:
              </Form.Label>
              <Col sm={3}>
                <p>
                  {getRecordDataForKey("ES-SHIFT-ACTIVITY", rec)?.value.map(
                    (act: string) => {
                      return <span key={act}>{act}, </span>;
                    }
                  )}
                </p>
              </Col>
            
              <Form.Label column sm={2}>
                Shift Duration:
              </Form.Label>
              <Col sm={4}>
                <p>
                  {
                    calculateDurationDataForKey("ES-SHIFT-DURATION", rec)
                  }
                </p>
              </Col>
            </Form.Group>
            {renderLocationDate(rec)}
          </div>
        );

      case "PI":
        return (
          <div className="row-details">
            <Form.Group as={Row}>
            {/*<Form.Label column sm={2}>
                Time In/Out:
              </Form.Label>
              <Col sm={4}>
                <p>
                  {new Date(
                    getRecordDataForKey("PI-GENERAL-DURATION", rec)?.value.durationStart.value
                  ).toLocaleString("en-CA")}
                  <br />
                  {new Date(
                    getRecordDataForKey("PI-GENERAL-DURATION", rec)?.value.durationEnd.value
                  ).toLocaleString("en-CA")}
                  </p>*
                  </Col>*/}
              <Form.Label column sm={2}>
                Location in Venue:
              </Form.Label>
              <Col sm={3}>
                <p>
                  {getRecordDataForKey("PI-GENERAL-LOCATION_VENUE", rec)?.value}
                </p>
              </Col>
              
              <Form.Label column sm={2}>
                Duration:
              </Form.Label>
              <Col sm={4}>
                <p>{getRecordDataForKey("PI-GENERAL-DURATION", rec)?.value.diffMin} minutes</p>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={2}>
                Gender:
              </Form.Label>
              <Col sm={3}>
                <p>
                  {getRecordDataForKey("PI-PROFILE-GENDER", rec)?.value}
                </p>
              </Col>
              <Form.Label column sm={2}>
                Age:
              </Form.Label>
              <Col sm={3}>
                <p>
                  {getRecordDataForKey("PI-PROFILE-AGE_GROUP", rec)?.value}{" "}
                  minutes
                </p>
              </Col>
            </Form.Group>

            {renderLocationDate(rec)}
          </div>
        );

      case "SV":
        return (
          <div className="row-details">
            <Form.Group as={Row}>
              <Form.Label column sm={2}>
                Location in Venue:
              </Form.Label>
              <Col sm={3}>
                <p>
                  {getRecordDataForKey("SV-GENERAL-LOCATION_VENUE", rec)?.value}
                </p>
              </Col>
              <Form.Label column sm={2}>
                Visit Duration:
              </Form.Label>
              <Col sm={4}>
                <p>
                  {getRecordDataForKey("SV-GENERAL-VISIT_DURATION", rec)?.value}{" "}
                  minutes
                </p>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={2}>
                Department:
              </Form.Label>
              <Col sm={3}>
                <p>
                  {getRecordDataForKey("SV-PROFILE-DEPT", rec)?.value}
                </p>
              </Col>
              <Form.Label column sm={2}>
                Gender:
              </Form.Label>
              <Col sm={4}>
                <p>
                  {getRecordDataForKey("SV-PROFILE-GENDER", rec)?.value}
                </p>
              </Col>
            </Form.Group>

            {renderLocationDate(rec)}
          </div>
        );
    }

    return <p>Nothing here</p>;
  };

  /**
   * All rows include location and date in the expansion.
   * @param rec The expanded form record
   * @returns HTML for the location * date
   */
  const renderLocationDate = (rec: ExtFormRecord) => {
    return (
      <Form.Group as={Row}>
        <Form.Label column sm={2}>
          Location:
        </Form.Label>
        <Col sm={3}>
          <p>{rec.location?.name}</p>
        </Col>
        <Form.Label column sm={2}>
          Date:
        </Form.Label>
        <Col sm={5}>
          <p>{new Date(rec.reportedAt).toDateString()}</p>
        </Col>
      </Form.Group>
    );
  };

  /**
   * Calculate the difference in time between two dates and render it
   * @param inTime Start time
   * @param outTime End time
   * @returns HTML w/difference
   */
  const renderElapsedTime = (inTime: Date, outTime: Date) => {
    const inT = inTime.getTime();
    const outT = outTime.getTime();

    let timeInMilisec: number = outT - inT;

    let timeInMinutes = timeInMilisec / (1000 * 60);
    const timeInHours = Math.ceil(timeInMinutes / 60) - 1;

    timeInMinutes = timeInMinutes - 60 * timeInHours;
    timeInMinutes = Math.round(timeInMinutes);
    
    let hour: string = timeInHours.toString();
    let min: string = timeInMinutes.toString();

    if (hour.length === 1) {
      hour = `0${hour}`;
    }
    if (min.length === 0) {
      min = `0${min}`;
    }

    return (
      <React.Fragment>
        {hour}:{min}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <section className="reportList">
        <p className="expandCollapseTable">
          <Button variant="link" onClick={() => setIsExpandAll(!isExpandAll)}>
            {isExpandAll ? (
              <span>
                <svg
                  fill="currentColor"
                  height="24"
                  viewBox="0 0 24 24"
                  width="24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z"></path>
                  <path d="M0-.75h24v24H0z" fill="none"></path>
                </svg>
                Collapse All
              </span>
            ) : (
              <span>
                <svg
                  fill="currentColor"
                  height="24"
                  viewBox="0 0 24 24"
                  width="24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z"></path>
                  <path d="M0-.25h24v24H0z" fill="none"></path>
                </svg>
                Expand All
              </span>
            )}
          </Button>
        </p>
        <DataTable
          columns={columns}
          data={records}
          dense={isTabletOrMobile}
          fixedHeader
          pagination
          responsive
          subHeaderWrap
          highlightOnHover
          pointerOnHover
          progressPending={recordsNetStatus === "loading"}
          //   paginationComponentOptions={paginationComponentOptions}
          striped
          expandableRows
          expandableRowsComponent={ExpandedComponent}
          expandableRowExpanded={(row) => isExpandAll}
          onRowClicked={(record) => onRowClicked(record)}
          paginationServer
          paginationTotalRows={pagination.total}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          onSort={handleSort}
          sortServer
          paginationServerOptions={{
            persistSelectedOnPageChange: true, 
            persistSelectedOnSort: true
          }}
          paginationRowsPerPageOptions={[
            25, 50, 100, 200
          ]}
          paginationPerPage={50}
          //theme="rgc-table"
        />
      </section>{" "}
    </React.Fragment>
  );
};

export default ReportListDataTable;
