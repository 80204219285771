import React, { useEffect, useState } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';
import {  selectNotifications } from '../../features/notifications/notificationSlice';
import { useAppSelector } from '../hooks';
import './NotificationToast.scss'

const NotificationToast = () => {
    const [isShowingToast, setIsShowingToast] = useState<boolean>(true);

    const notification = useAppSelector(selectNotifications);
    
    useEffect(()=>{
        if (notification === undefined){
            return;
        }
      
        setIsShowingToast(true);
    }, [notification])

    return <React.Fragment>
        {notification && <ToastContainer className={`notification ${notification?.type}`} position='top-end'  >
            <Toast show={isShowingToast} onClose={() => setIsShowingToast(false) } 
                delay={notification.type === 'success' ? 4000 : 30000} autohide >
                <Toast.Header>
                    <strong className="me-auto">{notification.header}</strong>
                </Toast.Header>
                <Toast.Body>{notification.body}</Toast.Body>
            </Toast>
        </ToastContainer>}
    </React.Fragment>
  }
  
  export default NotificationToast


