import React from 'react';


const NotFound = () => {
  return (
    <h1>404 - Not Found</h1>
  );
}


export default NotFound;