import React, {   useEffect, useState } from "react";
import "./Login.scss";
import logo from "./../../assets/images/logo_en.png";
import { Button, Form } from "react-bootstrap";
import { useAppDispatch } from "../hooks";
import { login } from "../../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import feathersClient from "../../client";

const Login = () => {
  //const user = useAppSelector(userSelector);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isError, setIsError] = useState(false);

  const [errorMsg, setErrorMsg] = useState<string>("");
  const [isLoginDisabled, setIsLoginDisabled] = useState<boolean>(false);

  useEffect(()=>{
    // Logout the user by removing an existing token:
    localStorage.removeItem('feathers-jwt');
    feathersClient.logout();
  }, [])

  const onLogin = async () => {
    setIsLoginDisabled(true);

    try {
      const result = await dispatch(
        login({ email: email, password: password, remember: false })
      );

    /* 
      If there's an error returned by the API, Redux won't fail the promise
      like it would if the API call was made directly. Instead it returns a
      stateful object with an error.
      
      Thus we look at thre result.payload to see the actual error passed by
      the API. Checking the code is probably the most direct way.

      If there is no code value then result.payload would be the successful
      response data from the API. So we can check for undefined and assume 
      that means it's successful.

      NOTE: this only applicable to API calls made through a Redux dispatch().
            Normally we'd also watch the Redux state rather than touch
            the payload value directly. 
      */
   
      if (result.payload === undefined) {
        setErrorMsg("Sorry, something went wrong. Please try again later.");
        setIsError(true);
        
        return;
      }
      switch (result.payload.code) {
        case undefined:
          // Successful login, no error
          setIsError(false);
          
          navigate("/");
          break;
        case 401:
          setErrorMsg("Invalid login name or password.");
          setIsError(true);
          //         console.log("Handle invalid username or password error");
          //         console.log("This is the result object: ", result);
          break;
        case 400:
          // User hasn't validated their account yet

          break;
        default:
          setErrorMsg("Sorry, something went wrong. Please try again later.");
          setIsError(true);
          console.log("Handled unknown error: ", result.payload);
          break;
      }
    } catch (err) {
      // setError(true)
      console.error("Auth failed: ", err);
    }finally{
      setIsLoginDisabled(false);
    }
  };

  return (
    <div className="page-login">
      <div className="login">
        <img src={logo} alt="PlaySmart" />

        <h1>Responsible Gambling Council Extranet</h1>

        <Form >
          <section>
            <Form.Group className="mb-3">
              <Form.Label md={3}>Login</Form.Label>
              <Form.Control
                type="email"
                id="email"
                autoComplete="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label htmlFor="inputPassword5">Password</Form.Label>
              <Form.Control
                type="password"
                id="password"
                autoComplete="current-password"
                aria-describedby="passwordHelpBlock"
                onChange={(e) => setPassword(e.target.value)}
              />
              {isError && <p className='error'>{errorMsg}</p>}
            </Form.Group>
            {}
          </section>
          <div className="forgotPassword">
            <Button variant="text" onClick={()=>navigate('/forgot-password')}>Forgot Password?</Button>
          </div>
          <div className="d-grid gap-2">
            <Button variant="primary" size="lg" disabled={isLoginDisabled} onClick={onLogin}  type='submit'>
              Login
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Login;
