import React from "react";
import { Pie } from "@nivo/pie";
import { useMediaQuery } from "react-responsive";

/***
 * Generates a pie chart for aggregate value counts.
 */
export function PieChart({ pieData, isTabletOrMobile }) {
 
  const isPhone = useMediaQuery({ query: "(max-width: 620px)" });

  

  const largeProps = {
    width: 600,
    height: 260,
    margin: { top: 30, right: 40, bottom: 40, left: 40 },
    data: pieData,
    animate: true,
  };

  const mediumProps = {
    width: 500,
    height: 200,
    margin: { top: 20, right: 40, bottom: 30, left: 40 },
    data: pieData,
    animate: true,
    activeOuterRadiusOffset: 8,
  };

  const smallProps = {
    width: 380,
    height: 130,
    margin: { top: 20, right: 100, bottom: 30, left: 100 },
    data: pieData,
    animate: true,
    activeOuterRadiusOffset: 8,
  };

  return (
    <div className="pie-chart">
      {pieData &&  !isTabletOrMobile && !isPhone && (
       
          <Pie
            {...largeProps}
            innerRadius={0.5}
            // padAngle={0.7}
            // cornerRadius={3}
            colors={{ scheme: "set3" }}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            theme={{
              tooltip: {
                container: {
                  fontSize: "16px",
                },
              },
              labels: {
                text: {
                  fontSize: "10pt",
                },
              },
            }}
            arcLinkLabelsColor={{ from: "color" }}
            arcLinkLabelsThickness={3}
            borderWidth={2}
            borderColor={{
              from: "color",
              modifiers: [["darker", "1"]],
            }}
          />
    
      )}

      {pieData && isTabletOrMobile && !isPhone && (
       
          <Pie
            {...mediumProps}
            innerRadius={0.5}
            // padAngle={0.7}
            // cornerRadius={3}
            colors={{ scheme: "set3" }}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            theme={{
              tooltip: {
                container: {
                  fontSize: "16px",
                },
              },
              labels: {
                text: {
                  fontSize: "8pt",
                },
              },
            }}
            arcLinkLabelsColor={{ from: "color" }}
            arcLinkLabelsThickness={3}
            borderWidth={2}
            borderColor={{
              from: "color",
              modifiers: [["darker", "1"]],
            }}
          />
      
      )}
      
      {pieData && isTabletOrMobile && isPhone && (
       
          <Pie
            {...smallProps}
            innerRadius={0.3}
            // padAngle={0.7}
            // cornerRadius={3}
            colors={{ scheme: "set3" }}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            theme={{
              tooltip: {
                container: {
                  fontSize: "16px",
                },
              },
              labels: {
                text: {
                  fontSize: "6.5pt",
                },
              },
            }}
            arcLinkLabelsColor={{ from: "color" }}
            arcLinkLabelsThickness={3}
            borderWidth={2}
            borderColor={{
              from: "color",
              modifiers: [["darker", "1"]],
            }}
          />

      )}
    </div>
  );
}
