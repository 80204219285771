import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import "../FormRenderer.scss";
import ReportSearchCriteria from "./ReportSearchCriteria";
import { Col, Form, Row } from "react-bootstrap";
import "./Reports.scss";
import DataTable from "react-data-table-component";
import { PieChart } from "./PieChart";
import { CSVLink } from "react-csv";
import {
  createInteractionTimesReport,
  selectInteractionTimesReport,
  ExtInteractionTimesReportEntry,
} from "../../../features/reports/interactionTimesReportSlice";
import { useNavigate } from "react-router-dom";
import { selectAuthUser } from "../../../features/auth/authSlice";
import { ExtLocation, ExtPermissions } from "../../api/api.types";
import { showNotification } from "../../../features/notifications/notificationSlice";
import { selectLocations } from "../../../features/locations/locationsSlice";
import doesUserHavePermission from "../../../utils/doesUserHavePermission";

interface CsvHeader {
  label: string;
  key: string;
}

interface ReportRow {
  lid: string;
  locationName: string;
  "0000-0659": number;
  "0700-1159": number;
  "1200-1859": number;
  "1900-2359": number;
}

const ReportInteractionTimes = ({
  isTabletOrMobile,
}: {
  isTabletOrMobile: boolean;
}) => {
  const locationsList = useAppSelector(selectLocations);
  const interactionTimesReport = useAppSelector(selectInteractionTimesReport);
  const [reportData, setReportData] = useState<Array<ReportRow>>([]);

  const [reportCreatedAt, setReportCreatedAt] = useState<Date>(new Date());
  const [reportLocations, setReportLocations] = useState<Array<string>>([]);

  const [isGraphView, setIsGraphView] = useState<boolean>(false);
  const [pieChartAllLocData, setPieChartAllLocData] = useState<any>();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const authUser = useAppSelector(selectAuthUser);

  useEffect(() => {
    if (doesUserHavePermission(authUser, ExtPermissions.canCreateReports) ) {
      // All good, they're allowed
    } else {
      const navNow = () => {
        dispatch(
          showNotification({
            header: "You can't do that",
            body: "You do not have permission to access reports.",
            type: "error",
          })
        );
        navigate("/");
      };
      navNow();
      return;
    }
  }, [authUser, dispatch, navigate]);

  /**
   * API report data only includes locations that actually have data
   * for that particular time period. This function will look for a
   * record for this location & time. If not present the count is
   * automatically returned as zero.
   * @param locationId
   * @param range
   * @returns
   */
  const findLocationCountForRange = (locationId: string, range: string) => {
    if (interactionTimesReport === undefined) {
      return;
    }
    type ObjectKey = keyof typeof interactionTimesReport;
    // See if the location was returned:
    const key = range as ObjectKey;

    const entry = interactionTimesReport[key].find(
      (rec: ExtInteractionTimesReportEntry) => {
        return rec.location.lid === locationId;
      }
    );

    if (entry !== undefined) {
      return entry.numInteractions;
    }

    return 0;
  };



  /**
   * When the report data arrives we need to parse it into the format for display
   * (basically by location vs by time group)
   */
  const prepareReportDataForTableView = () =>{
    const report: Array<ReportRow> = [];

    let repLocations: Array<ExtLocation> = [...locationsList];
   
    if (interactionTimesReport?.criteria["location.lid"] !== undefined) {
      // We're only going to use a subset of locations:
      repLocations = [];
      // Use only the subset of locations chosen:
      interactionTimesReport.criteria["location.lid"]["$in"].forEach(
        (lid: string) => {
          const loc = locationsList.find((l) => {
            return l._id === lid;
          });
          if (loc === undefined) {
            console.error("Data contains unknown location id: ", lid);
          } else {
            repLocations.push(loc);
          }
        }
      );
    }

    const  repNamesList:Array<string> = [];
    // Now build the report of each time range:
    repLocations.forEach((loc) => {
      const row: ReportRow = {
        lid: loc._id,
        locationName: loc.name,
        "0000-0659": findLocationCountForRange(loc._id, "0000-0659"),
        "0700-1159": findLocationCountForRange(loc._id, "0700-1159"),
        "1200-1859": findLocationCountForRange(loc._id, "1200-1859"),
        "1900-2359": findLocationCountForRange(loc._id, "1900-2359"),
      };
      report.push(row);
      repNamesList.push(loc.name);
    });

    setReportData(report);

    if (interactionTimesReport?.criteria["location.lid"] !== undefined){
      setReportLocations(repNamesList);
    }else{
      setReportLocations(["All"]);
    }

  }




  /**
   * Calculates the all locations summary chart view data
   * @returns 
   */
  const prepareReportDataForPieChartView = () =>{
    if (interactionTimesReport===undefined){
      setPieChartAllLocData([])
      return;
    }

    // Prepare a single pie chart showing the total cumulative values for each time range:
    const allLocationsPieData:any = [
      {
       id: "12:00am - 06:59am",
        value: interactionTimesReport['0000-0659'].reduce((sum, row) => sum + row.numInteractions, 0),
      },
      {
        id: "07:00am - 12:00pm",
         value: interactionTimesReport['0700-1159'].reduce((sum, row) => sum + row.numInteractions, 0),
       },
       {
        id: "12:00pm - 06:59pm",
         value: interactionTimesReport['1200-1859'].reduce((sum, row) => sum + row.numInteractions, 0),
       },
       {
        id: "07:00pm - 11:59pm",
         value: interactionTimesReport['1900-2359'].reduce((sum, row) => sum + row.numInteractions, 0),
       },
    ]
    
    setPieChartAllLocData(allLocationsPieData);
  }


  /**
   * Trigger report formatting when the report data changes
   */
  useEffect(() => {
    prepareReportDataForTableView()
    prepareReportDataForPieChartView();
    setReportCreatedAt(new Date() );
  }, [interactionTimesReport]);

  /**
   * Format the report data for CSV export.
   * @returns Array with large object of key/value pairs
   */
  const getCsvReportData = (): Array<any> => {
    
    return reportData;
  };



  /**
   * Generate a filename based on the report type and date
   * @returns string the file name
   */
  const getCsvFileName = () => {
    const name = `Visitor_Interaction-${new Date().toDateString()}.csv`;
    const re = / /gi;
    const result = name.replace(re, "_");
    return result;
  };



  /**
   * Generate the headers necessary for a CSV document.
   */
  const getCsvHeaders = () => {
    const headers: Array<CsvHeader> = [
      {
        label: "Location",
        key: "LOCATION",
      },
      {
        label: "12:00am - 06:59am",
        key: "0000-0659",
      },
      {
        label: "07:00am - 11:59am",
        key: "0700-1159",
      },
      {
        label: "12:00pm - 06:59pm",
        key: "1200-1859",
      },
      {
        label: "07:00pm - 11:59pm",
        key: "1900-2359",
      },
     
    ];

    return headers;
  };



  /**
   * Data Table column definition
   */
  const columns = [
    {
      name: "Location",
      selector: (row: any) => row.locationName,
      sortable: true,
      compact: true,
      // center: true,
      wrap: true,
      maxWidth: "200px",
    },
    {
      name: "12:00am - 06:59am",
      selector: (row: any) => row["0000-0659"],
      sortable: true,
      compact: true,
      center: true,
      wrap: true,
      maxWidth: "200px",
    },
    {
      name: "07:00am - 11:59am",
      selector: (row: any) => row["0700-1159"],
      sortable: true,
      compact: true,
      center: true,
      wrap: true,
      maxWidth: "200px",
    },
    {
      name: "12:00pm - 06:59pm",
      selector: (row: any) => row["1200-1859"],
      sortable: true,
      compact: true,
      center: true,
      wrap: true,
      maxWidth: "200px",
    },
    {
      name: "07:00pm - 11:59pm",
      selector: (row: any) => row["1900-2359"],
      sortable: true,
      compact: true,
      center: true,
      wrap: true,
      maxWidth: "200px",
    },
  ];

  return (
    <React.Fragment>
      <section className="reports interaction-times">
        <h2>Interaction Times Report</h2>
        <p>
          Interaction Times Report breakout how many interactions occur in each
          block of the day.
          <br />
          Note: Only information from completed Interaction Reports are used to
          prepare this report. It does not reflect the total number of visitors
          to the PSC.
        </p>

        <hr />
        <ReportSearchCriteria
          isTabletOrMobile={isTabletOrMobile}
          reportSelector={selectInteractionTimesReport}
          reportThunk={createInteractionTimesReport}
          showFormTypeSelector={false}
          showStaffSelector={false}
          showLocationSelector={true}
        />

        {interactionTimesReport && (
          <section className="report">
            <Form className="ext-form">
              <Row className="header">
                <Col sm={5}>
                  <h3>Report</h3>
                </Col>
                <Col sm={4}>
                  <Form.Check
                    type="switch"
                    id="visualization-check"
                    label="Visualize Data"
                    className="visualization-check"
                    checked={isGraphView}
                    onChange={() => setIsGraphView(!isGraphView)}
                  /> 
                </Col>
                <Col sm={3}>
                  <CSVLink
                    data={getCsvReportData()}
                    headers={getCsvHeaders()}
                    filename={getCsvFileName()}
                    className="csv"
                  >
                    Export CSV
                  </CSVLink>
                </Col>
              </Row>

              <Row>
                <section className="input-section">
                  <h4>Interaction Time Report</h4>
                  <Form.Group as={Row} className="mb-3" controlId="reportType">
                    <Form.Label column md={4} aria-label="Reported At">
                      Report Created At:
                    </Form.Label>
                    <Col md={8}>
                      <Form.Control
                        as="input"
                        value={`${reportCreatedAt.toDateString()} @ ${reportCreatedAt.toLocaleTimeString()}`}
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3" controlId="location">
                    <Form.Label column md={4} aria-label="Location">
                      Location(s):
                    </Form.Label>
                    <Col md={8}>
                      <p className="report-locations">
                        {reportLocations.map((name) => {
                          return <span key={name}>{name}</span>;
                        })}
                      </p>
                    </Col>
                  </Form.Group>
                </section>
              </Row>

              {!isGraphView && (
                <Row className="data-table">
                  <DataTable
                    columns={columns}
                    data={reportData}
                    dense={isTabletOrMobile}
                    fixedHeader
                    pagination
                    paginationPerPage={30}
                    responsive
                    subHeaderWrap
                    highlightOnHover
                    pointerOnHover
                    striped
                  />
                </Row>
              )}
              {isGraphView && (
                <Row className="graph">
                  <div className="chart">
                    <label>Times for All Selected Locations</label>
                    <PieChart
                      pieData={pieChartAllLocData}
                      isTabletOrMobile={isTabletOrMobile}
                    />
                  </div>
                </Row>
              )}
              {isGraphView && reportData.map(row=>{
                return <Row className="graph" key={row.lid}>
                <div className="chart">
                  <label>{row.locationName}</label>
                  <PieChart
                    pieData={[
                      {
                        id: "12:00am - 06:59am",
                         value: row["0000-0659"],
                       },
                       {
                         id: "07:00am - 12:00pm",
                          value: row["0700-1159"],
                        },
                        {
                         id: "12:00pm - 06:59pm",
                          value: row["1200-1859"],
                        },
                        {
                         id: "07:00pm - 11:59pm",
                          value: row["1900-2359"],
                        },
                    ]}
                    isTabletOrMobile={isTabletOrMobile}
                  />
                </div>
              </Row>
              })}
            
            </Form>
          </section>
        )}
      </section>
    </React.Fragment>
  );
};

export default ReportInteractionTimes;
