import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import "../FormRenderer.scss";
import {
  AggResult,
  createSumReport,
  selectSumReport,
} from "../../../features/reports/sumReportSlice";
import ReportSearchCriteria from "./ReportSearchCriteria";
import { Col, Form, Row } from "react-bootstrap";
import { selectForms } from "../../../features/forms/formsSlice";
import {
  ExtControlTypes,
  ExtDataTypes,
  ExtForm,
  ExtFormField,
  ExtFormSection,
  ExtPermissions,
} from "../../api/api.types";
import "./Reports.scss";
import DataTable from "react-data-table-component";
import { selectLocations } from "../../../features/locations/locationsSlice";
import { PieChart } from "./PieChart";
import { CSVLink } from "react-csv";
import { selectAuthUser } from "../../../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { showNotification } from "../../../features/notifications/notificationSlice";
import doesUserHavePermission from "../../../utils/doesUserHavePermission";

interface PieChartData {
  id: string,
  label: string;
  value: number;
  color?: string;
}

interface CsvHeader {
  label: string;
  key: string;
}

/**
 * Create a report of the Cumulative Totals type.
 * These reports sum all the answer values entered
 * for a particular form type.
 */

const ReportCumulativeTotals = ({
  isTabletOrMobile,
}: {
  isTabletOrMobile: boolean;
}) => {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const locationsList = useAppSelector(selectLocations);
  const formList = useAppSelector(selectForms);
  const sumReport = useAppSelector(selectSumReport);
  const authUser = useAppSelector(selectAuthUser);

  const [reportCreatedAt, setReportCreatedAt] = useState<Date>(new Date());
  const [selectedForm, setSelectedForm] = useState<ExtForm | undefined>();
  const [reportLocations, setReportLocations] = useState<Array<string>>([]);

  const [isGraphView, setIsGraphView] = useState<boolean>(false);


  const columns = [
    {
      name: "Selected Option",
      selector: (row: any) => row.label,
      sortable: true,
    },
    {
      name: "Count",
      selector: (row: any) => row.count,
      sortable: true,
    },
  ];

  useEffect(()=>{

    if (doesUserHavePermission(authUser, ExtPermissions.canCreateReports) ){
          // All good, they're allowed
        }else{
          
          const navNow = ()=>{
           dispatch(showNotification({
                header: "You can't do that",
                body: 'You do not have permission to access reports.',
                type: 'error'
            }))
            navigate('/');
          }
          navNow();
          return;
        }
  }, [authUser, dispatch, navigate])

  useEffect(() => {
    if (sumReport === undefined) {
      return;
    }

    console.log("Report result", sumReport);
    setReportCreatedAt(new Date(sumReport!.createdAt));

    const f = formList.find((frm) => {
      return frm.formId === sumReport.criteria.formId;
    });

    setSelectedForm(f);

    // Set which locations this report includes:
    if (sumReport.criteria["location.lids"] === undefined) {
      setReportLocations(["All"]);
    } else {
      const locList: Array<string> = sumReport.criteria["location.lids"].map((lid) => {
        const loc = locationsList.find((l) => {
          return l._id === lid;
        });
        return(loc?.name!);
      });
      setReportLocations(locList);
    }
  
  }, [sumReport, formList, locationsList]);

  /**
   * Some form fields don't contain values that could be
   * summed. Eg. DateTime, string, etc.
   * @param field
   * @returns
   */
  const canRenderField = (field: ExtFormField): boolean => {
    switch (field.control) {
      case ExtControlTypes.Text:
        return false;
      case ExtControlTypes.TextField:
        return false;
      case ExtControlTypes.DateTime:
        return false;
      case ExtControlTypes.Date:
        return false;
      case ExtControlTypes.Duration:
        return false;
      default:
        return true;
    }
  };

  /**
   * Check to see if a section contains at least one
   * renderable field. If none are renderable,
   * the section isn't renderable
   * @param section
   * @returns
   */
  const canRenderSection = (section: ExtFormSection): boolean => {
    let canRender = false;
    section.fields.forEach((f) => {
      const isFieldRenderable = canRenderField(f);
      if (isFieldRenderable) {
        canRender = true;
      }
    });
    return canRender;
  };

  /**
   * Map the aggregated values with field answers
   * and labels
   * @param field
   * @param section
   * @returns
   */
  const getReportValuesForField = (
    field: ExtFormField,
    section: ExtFormSection
  ): Array<AggResult> => {
    const sectionData = sumReport?.sections.find((s) => {
      return s.sid === section.sid;
    });

    const element = sectionData?.elements.find((e) => {
      return e.key === field.fid;
    });

    if (element === undefined) {
      //console.warn("getReportValuesForField report element undefined. This can happen when changing report types.", field.fid );
      return [];
    }

    // Get the labels from the form:
    // Iterate the form elements so that we can
    // insert 0 for options that don't exist
    // in the report data
    const newValues: Array<AggResult> = [];

    if (field.control === ExtControlTypes.Number || field.control === ExtControlTypes.Duration) {
  
      const val: AggResult = {
        aid: field.label,
        label: field.label,
        count: element.values.length > 0 ? element.values[0].count : 0,
      };
      newValues.push(val);
    } else {
      // Multiple choice have several options, each with a count
      field.options.forEach((o) => {
        const el = element?.values.find((e) => {
          return e.aid === o.oid;
        });

        const cnt: number = el !== undefined ? el.count : 0;
        const val: AggResult = {
          aid: o.oid,
          label: o.label,
          count: cnt,
        };
        newValues.push(val);
      });
    }

    newValues.sort((a, b) => {
      // By default put Other at the bottom:
      if (a.label === "Other") {
        return 100;
      }
      return a.label.localeCompare(b.label);
    });

    return newValues;
  };


  const getPieDataForField = (field:ExtFormField, section:ExtFormSection) =>{
    const fieldData=getReportValuesForField( field, section);

    const data:Array<any> = [];
    fieldData.forEach((aggResult) => {
      if (aggResult.count > 0) {
        const d:PieChartData= {
          id: aggResult.label,
          label: aggResult.label,
          value: aggResult.count,
        };
        data.push(d);
      }
    });

    return(data);
  }

  


  /**
   * Generate the headers necessary for a CSV document.
   * For string/string array values:
   *  As some answer ID's are re-used (e.g. Other), we're generating the
   *  key name to include the field ID + answer ID.
   * For Numeric values:
   *  Just need the field ID as there is a single value
   */
  const getCsvHeaders = () => {
    const headers: Array<CsvHeader> = [];
    sumReport?.criteria.form?.sections.forEach((section) => {
      section.fields.forEach((field) => {
        if (canRenderField(field)) {
          if (field.dType === ExtDataTypes.String || field.dType === ExtDataTypes.ArrString){
            // Map each option individually. Use field and option IDs to 
            // avoid collisions by like options (eg. other)
            field.options.forEach((option) => {
              const h: CsvHeader = {
                label: option.label,
                key: `${field.fid}-${option.oid}`,
              };
              headers.push(h);
            });
          }else if (field.dType === ExtDataTypes.Number){
            // Map a single value
            const h: CsvHeader = {
              label: field.label,
              key: `${field.fid}`,
            };
            headers.push(h);
          }
        }
      });
    });
    return headers;
  };

  /**
   * Format the report data for CSV export.
   * This returns a single row (the complete report) of all key/values
   * For string/string array values:
   *  As some answer ID's are re-used (e.g. Other), we're generating the
   *  key name to include the field ID + answer ID.
   * For Numeric values:
   *  Just need the field ID as there is a single value
   * @returns Array with large object of key/value pairs
   */
  const getCsvReportData = () => {
    const row: any = {};

    selectedForm?.sections.forEach((section) => {
      section.fields.forEach((field) => {
        if (canRenderField(field)) {
          if (field.dType === ExtDataTypes.String || field.dType === ExtDataTypes.ArrString){
            // Set the data values for each option 
            const element = getReportValuesForField(field, section);
            element.forEach((val) => {
              row[`${field.fid}-${val.aid}`] = val.count;
            });
          }else if (field.dType === ExtDataTypes.Number){
            // Set a single data value for the number
            const element = getReportValuesForField(field, section);
            // If there isnt' a value, set it to zero
            row[`${field.fid}`] = element.length > 0 ? element[0].count : 0;
          }
        }
      });
    });
    return [row];
  };

  /**
   * Generate a filename based on the report type and date
   * @returns string the file name
   */
  const getCsvFileName = () => {
    let name = `${
      selectedForm?.label
    }-Cumulative_Totals-${new Date().toDateString()}.csv`;

    if (sumReport?.criteria.formSectionIds !== undefined && sumReport?.criteria.formSectionIds.length > 0){
      name = `${
        selectedForm?.label
      }-${sumReport.sections[0].title}-Cumulative_Totals-${new Date().toDateString()}.csv`;
    }


    const re = / /gi;
    const result = name.replace(re, "_");
    return result;
  };

  return (
    <React.Fragment>
      <div className="reports cumulative-total">
        <h2>Cumulative Totals Report</h2>
        <p>
          Cumulative reports compile the total number of times each form answer
          is selected. <br />
          Eg. how many times <strong>Visit Duration:</strong>{" "}
          <em>1-5 minutes</em> was selected vs. <em>11-20 minutes</em>, etc.
        </p>
        <p>
          These reports are only applicable to multiple choice selections and do
          not include any date/time fields, free-form text entry, etc.
        </p>

        <hr />
        <ReportSearchCriteria
          isTabletOrMobile={isTabletOrMobile}
          reportSelector={selectSumReport}
          reportThunk={createSumReport}
          showFormTypeSelector={true}
          showStaffSelector={false}
          showLocationSelector={true}
          showSectionFieldSelector={true}
        />

        {sumReport && (
          <section className="report">
            <Form className="ext-form">
              <Row className="header">
                <Col sm={5}>
                  <h3>Report</h3>
                </Col>
                <Col sm={4}>
                  <Form.Check
                    type="switch"
                    id="visualization-check"
                    label="Visualize Data"
                    className="visualization-check"
                    checked={isGraphView}
                    onChange={() => setIsGraphView(!isGraphView)}
                  />
                </Col>
                <Col sm={3}>
                  <CSVLink
                    data={getCsvReportData()}
                    headers={getCsvHeaders()}
                    filename={getCsvFileName()}
                    className="csv"
                  >
                    Export CSV
                  </CSVLink>
                </Col>
              </Row>
              <Row>
                <section className="input-section">
                  <h4>{sumReport?.criteria.form?.label} - Cumulative Totals Report</h4>
                  <Form.Group as={Row} className="mb-3" controlId="reportType">
                    <Form.Label column md={4} aria-label="Reported At">
                      Reported At:
                    </Form.Label>
                    <Col md={8}>
                      <Form.Control
                        as="input"
                        value={`${reportCreatedAt.toDateString()} @ ${reportCreatedAt.toLocaleTimeString()}`}
                        readOnly
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="location">
                    <Form.Label column md={4} aria-label="Location">
                      Location(s):
                    </Form.Label>
                    <Col md={8}>
                      <p className="report-locations">
                        {reportLocations.map((name) => {
                          return <span key={name}>{name}</span>;
                        })}
                      </p>
                    </Col>
                  </Form.Group>

                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="totalRecords"
                  >
                    <Form.Label column md={4} aria-label="Total Records">
                      Total Records:
                    </Form.Label>
                    <Col md={8}>
                      <Form.Control
                        as="input"
                        value={`${sumReport.recordCount}`}
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                </section>
              </Row>
              {sumReport?.criteria.form?.sections.map((section: ExtFormSection) => {
                return canRenderSection(section) ? (
                  <Row key={`row-${section.sid}`}>
                    <section key={section.sid} className="input-section">
                      <h4>{section.title}</h4>
                      {section.fields.map((field: ExtFormField) => {
                        return canRenderField(field) ? (
                          isGraphView ? (
                            <React.Fragment>
                              <label>{field.label}</label>
                              <PieChart
                                pieData={getPieDataForField(field, section) }
                                isTabletOrMobile={isTabletOrMobile}
                                key={field.fid}
                              />
                            </React.Fragment>  
                          ) : (
                            <Form.Group
                              as={Row}
                              className="mb-3"
                              key={field.fid}
                            >
                              <Form.Label
                                column
                                md={4}
                                aria-label={field.label}
                              >
                                {field.label}:
                              </Form.Label>
                              <Col md={8}>
                                <div className="report-details">
                                  <DataTable
                                    columns={columns}
                                    data={getReportValuesForField(
                                      field,
                                      section
                                    )}
                                    dense={true}
                                    fixedHeader
                                    responsive
                                    subHeaderWrap
                                    highlightOnHover
                                    pointerOnHover
                                    striped
                                  />
                                </div>
                              </Col>
                            </Form.Group>
                          )
                        ) : (
                          ""
                        );
                      })}
                    </section>
                  </Row>
                ) : (
                  ""
                );
              })}
            </Form>
          </section>
        )}
      </div>
    </React.Fragment>
  );
};

export default ReportCumulativeTotals;
