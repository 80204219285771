import React, {  useCallback, useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { ExtFormField, ExtFormRecord } from '../../api/api.types';
import { ExtFormFieldError, ExtFormFieldValue } from '../FormRenderer';
import FormFieldChildRenderer from './FormFieldChildRenderer';

const ExtTextInput = ({ field, isTabletOrMobile, onChange, existingValue, error, isLarge, record, getFormFieldError, parseAs, trackedAtDate }: 
    { 
        field: ExtFormField, 
        isTabletOrMobile:boolean, 
        onChange:Function, 
        existingValue:string, 
        error:ExtFormFieldError|undefined,
        isLarge:boolean,
        record:ExtFormRecord|undefined,
        getFormFieldError: Function,
        parseAs:string,
        trackedAtDate?:Date
    }) => {
    
    const [currentValue, setCurrentValue] = useState<string>("");

    // If this is the first time we're seeing a value selected:
    const [hasBeenChanged, setHasBeenChanged] = useState<boolean>(false);
    

    const onChangeCallback = useCallback(args => {
        return onChange(args);
     }, [onChange])
 



    const onTextChange = useCallback( ( value:string)=>{
        // Add/remove from our list of selected values:
        if (! hasBeenChanged){
            setHasBeenChanged(true);
        }

        
        const val:ExtFormFieldValue = {
            fid: field.fid,
            field: field,
            dType:  field.dType,
            value: parseAs === 'number' ? parseInt(value) : value,
            isError: error?  true : false,
        }
        setCurrentValue(value);
        onChangeCallback(val);
    }, [field, error, hasBeenChanged, onChangeCallback, parseAs])

    


    useEffect(()=>{
        if (hasBeenChanged){
            // User made a change, don't get caught in a loop
            return;
        }

        if (existingValue === undefined){
            setCurrentValue("");
            return;
        }
        onTextChange(existingValue);
    }, [existingValue, hasBeenChanged, onChangeCallback, onTextChange])


    /**
     * Cleanup effect to remove value for removed fields
     * (e.g. children that are disabled)
     */
    useEffect(()=>{
        return ()=>{
            const val:ExtFormFieldValue = {
                fid: field.fid,
                field: field,
                dType:  field.dType,
                value: undefined,
                isError: error?  true : false,
            }
            onChangeCallback(val);
        }
    },[])
   


    /**
     * If the user has a form visible then changed to a form
     * that still has this control, make sure we pick-up 
     * the change to existing data emptying, allowing this
     * to change again (to the empty value).
     */
     useEffect(()=>{
        if (existingValue === undefined){
            setHasBeenChanged(false)
        }
    },[existingValue])



    return  isLarge ? <Form.Group as={Row} className="mb-3" key={field.fid}>
            <Row className='text-input-label'><label>{field.required && '*'} {field.label}</label></Row>
            <Row  className='text-input-control'>
                <Form.Control
                    as='textarea'
                    id={field.fid}
                    onChange={e => onTextChange( e.target.value) }
                    value={currentValue}
                    rows={4}
                />
                {field.hint && <p className='hint'>{field.hint}</p>}
                {error?.fid && <p className='error'>{error?.errorMessage}</p>}
            </Row>
            { field.children && field.children?.length > 0 && 
                <FormFieldChildRenderer 
                    children={field.children!} 
                    isTabletOrMobile={isTabletOrMobile}
                    onChange={onChange}
                    record={record} 
                    error={error}
                    parentValue={currentValue}
                    getFormFieldError={getFormFieldError}
                />  
            }
        </Form.Group>
        :
        <Form.Group as={Row} className="mb-3" key={field.fid}>
            <Form.Label column md={3}>{field.required && '*'}{field.label}:</Form.Label>
            <Col md={9} key={field.fid} >
                <Form.Control
                    type={parseAs === 'number' ? 'number' : ''}
                    as='input'
                    id={field.fid}
                    onChange={e => onTextChange( e.target.value) }
                    value={currentValue}
                    min={0}
                />
                 {field.hint && <p className='hint'>{field.hint}</p>}
                {error?.fid && <p className='error'>{error?.errorMessage}</p>}
            </Col>
            { field.children && field.children?.length > 0 && 
                <FormFieldChildRenderer 
                    children={field.children!} 
                    isTabletOrMobile={isTabletOrMobile}
                    onChange={onChange}
                    record={record} 
                    error={error}
                    parentValue={currentValue}
                    getFormFieldError={getFormFieldError}
                    trackedAtDate={trackedAtDate}
                />  
            }
        </Form.Group>

    }
   

export default ExtTextInput;