import React, { useState } from 'react';
import { Button, Collapse } from 'react-bootstrap';
import logo from './../../assets/images/logo_en.png';
import './Header.scss';
import * as Icon from 'react-bootstrap-icons';
import Navigation from './Navigation';
import { useMediaQuery } from 'react-responsive'
import NotificationToast from './NotificationToast';

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })

    return (
        <header className='header-main'>
            <NotificationToast />
            <div className='header-items'>
                <img src={logo} alt="PlaySmart" />

                { isTabletOrMobile && 
                    <Button 
                        className='mobile-menu-button'
                        variant="link"
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                        aria-controls="open-menu"
                        aria-expanded={isMenuOpen}
                    ><Icon.ThreeDots /></Button>
                }
            </div>

            <Collapse in={isMenuOpen}>
                <div id="mobile-nav">
                    <Navigation />
                </div>
            </Collapse>
        </header>
    );
  }

export default Header;