import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
   apiCreateSummaryReport,
} from "../../app/api/api";
import { serializeFeathersError } from "../../app/api/serializeFeathersError";
import { ExtRecordSearchQuery } from "../forms/recoredsSlice";


interface ExtSummaryReport {
  criteria: ExtRecordSearchQuery;
  createdAt: Date;
  recordsByLocation: Array<ExtSummaryReportElement>;
  totalRowByLocation:  Array<ExtSummaryReportElement>;
  recordCount: number;
  recordsByDate: Array<CompiledResultByDate>,
  totalRowByDate: Array<CompiledResultByDate>,
}


type CompiledResultByDate = {
  year: number;
  month: number;
  PI?: number;
  PV?: number;
  SV?: number;
  EVstaffVisitors?: number;
  EVpatronVisitors?: number;
  EVtotalVisitors?: number;
  EScsTouchPoints?: number;
  TotalVisitors?: number,
};

  
  interface ExtSummaryReportElement {
    lid: string,
    name: string,
    SV: number,
    PV: number,
    PI: number,
    EVstaffVisitors: number,
    EVpatronVisitors: number,
    EVtotalVisitors: number,
    EScsTouchPoints: number
    TotalVisitors?: number,
  }
  
  

export interface SummaryReportState {
  value: ExtSummaryReport | undefined;
  status: "idle" | "loading" | "failed";
}

const initialState: SummaryReportState = {
  value: undefined,
  status: "idle",
};

/**
 * Submit the report criteria
 */
export const createSummaryReport = createAsyncThunk(
  "reporting/summary/create",
  async (query: ExtRecordSearchQuery, { rejectWithValue }) => {
    try {
      console.log("Sending query", query);
      const response = await apiCreateSummaryReport(query);
      console.log("Thunk api response", response)
      return response;
    } catch (err) {
      const e = serializeFeathersError(err);

      return rejectWithValue(e);
    }
  }
);

/**
 * Creates the Redux Slice for the forms
 */
export const SummaryReportSlice = createSlice({
  name: "reporting/summary",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(createSummaryReport.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(createSummaryReport.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createSummaryReport.fulfilled, (state, action) => {
        state.status = "idle";
        state.value = action.payload;
        state.value?.recordsByLocation.sort((a, b) =>{
            if(a.lid === "ALL"){
                return 1
            }
          return (a.name < b.name ? -1 : 1)
        }) ;

      });
  },
});


export const selectSummaryReport = (state: RootState) => state.summaryReport.value;

export default SummaryReportSlice.reducer;
