import React from 'react';
import { Col, Row } from 'react-bootstrap';


const Footer = ( {isTabletOrMobile}:{isTabletOrMobile:boolean} ) => {



  return (
    <Row className='footer'>
        <Col md={3}>
            <p>&copy;2024 RGC - {process.env.REACT_APP_VERSION}</p>
        </Col>
        <Col md={9}>
            <p>Have some feedback to share? <a className='' href='https://www.surveymonkey.com/r/PSCExtranet' target='_blank' rel="noreferrer">Please submit your thoughts!</a></p>
        </Col>      
     
    </Row>
  );
}


export default Footer;