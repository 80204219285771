import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
  apiCreateCumulativeTimeReport,
} from "../../app/api/api";
import { serializeFeathersError } from "../../app/api/serializeFeathersError";
import { ExtRecordSearchQuery } from "../forms/recoredsSlice";



export interface ExtStaffMember {
  uid:        string;
  firstName:  string;
  lastName:   string;
  name:       string;
}

export interface ExtStaffDurationReportElement {
  patronVisitor: {
    minutes: number;
  };
  patronInteraction: {
    minutes: number;
  };
  event: {
    partrons: {
      minutes: number;
    };
    staff: {
      minutes: number;
    };
    other: {
      minutes: number,
    }
  };
  gamingStaffVisitor: {
    minutes: number;
  };

  staff: ExtStaffMember;
}

export interface ExtStaffDurationReport {
  criteria: ExtRecordSearchQuery;
  createdAt: Date;
  records: Array<ExtStaffDurationReportElement>;
  recordCount: number;
}

export interface StaffTotalTimeReportState {
  value: ExtStaffDurationReport | undefined;
  status: "idle" | "loading" | "failed";
}

const initialState: StaffTotalTimeReportState = {
  value: undefined,
  status: "idle",
};

/**
 * Submit the report criteria
 */
export const createStaffTotalTimeReport = createAsyncThunk(
  "reporting/staff-total-time/create",
  async (query: ExtRecordSearchQuery, { rejectWithValue }) => {
    try {
      console.log("Sending query", query);
      const response = await apiCreateCumulativeTimeReport(query);
      console.log("Thunk api response", response)
      return response;
    } catch (err) {
      const e = serializeFeathersError(err);
      return rejectWithValue(e);
    }
  }
);

/**
 * Creates the Redux Slice for the forms
 */
export const staffTotalTimeReportSlice = createSlice({
  name: "reporting/staff-total-time",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(createStaffTotalTimeReport.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createStaffTotalTimeReport.fulfilled, (state, action) => {
        state.status = "idle";
        state.value = action.payload;
        state.value?.records.sort((a, b) =>{
          return (a.staff.name < b.staff.name ? -1 : 1)
        }) ;

      });
  },
});


export const selectStaffTotalTimeReport = (state: RootState) => state.staffTotalTimeReport.value;

export default staffTotalTimeReportSlice.reducer;
