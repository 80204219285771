import React from "react";
import { Accordion, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ExtForm, ExtPermissions } from "../api/api.types";
import { selectForms } from "../../features/forms/formsSlice";
import { useAppSelector } from "../hooks";
import './Navigation.scss';
import convertFormLabelToSlug from "../../utils/convertLabelToSlug";
import { selectAuthUser } from "../../features/auth/authSlice";
import doesUserHavePermission from "../../utils/doesUserHavePermission";

const Navigation = () => {
  const forms = useAppSelector(selectForms);
  const authUser = useAppSelector(selectAuthUser);



  return (
    <Nav defaultActiveKey="/home" className="flex-column primary-nav">
      <Nav.Item ><Link to="/">Dashboard</Link></Nav.Item>
      {/*  <Nav.Item ><Link to="/my-reports">My Reports</Link></Nav.Item> */ }
      <Nav.Item ><Link to="/search">Search Forms</Link></Nav.Item>
      <Accordion defaultActiveKey="0">
        {doesUserHavePermission(authUser, ExtPermissions.canSubmitForms) && <Accordion.Item eventKey="0">
          <Accordion.Header>Submit Form</Accordion.Header>
          <Accordion.Body>
            {forms.map( (form:ExtForm, idx:number)=>{
              return <Nav.Item key={form.formId}><Link to={`/forms/${convertFormLabelToSlug(form.label)}`}>{form.label}</Link></Nav.Item>
            })}

          </Accordion.Body>
        </Accordion.Item> }
        { doesUserHavePermission(authUser, ExtPermissions.canCreateReports) && <Accordion.Item eventKey="1">
          <Accordion.Header>Reports</Accordion.Header>
          <Accordion.Body>
            <Nav.Item><Link to='/reports/summary'>Summary Report</Link></Nav.Item>
            <Nav.Item><Link to='/reports/filtered'>Filtered Report</Link></Nav.Item>
            <Nav.Item><Link to='/reports/cumulative-totals'>Cumulative Totals</Link></Nav.Item>
            <Nav.Item><Link to='/reports/event-summary'>Event Summary</Link></Nav.Item>
            <Nav.Item><Link to='/reports/interaction-times'>Interaction Times</Link></Nav.Item> 
            <Nav.Item><Link to='/reports/staff-time'>Staff Time</Link></Nav.Item>
            
           {/* <Nav.Item>(More reports in development)</Nav.Item>  
             <Nav.Item>Gaming Staff Report</Nav.Item>
            <Nav.Item>Full Report</Nav.Item>
            <Nav.Item>Time Report</Nav.Item>
<Nav.Item>Staff Report</Nav.Item> */}
          </Accordion.Body>
        </Accordion.Item> }
        
        { (doesUserHavePermission(authUser, ExtPermissions.canAddUsers)  || doesUserHavePermission(authUser, ExtPermissions.canManageUsers) ) &&
        <Accordion.Item eventKey="4">
          <Accordion.Header>System Admin</Accordion.Header>
          <Accordion.Body>
            {doesUserHavePermission(authUser, ExtPermissions.canManageUsers)  && <Nav.Item><Link to='/admin/users'>User List</Link></Nav.Item> }
            {doesUserHavePermission(authUser, ExtPermissions.canAddUsers)  && <Nav.Item><Link to={'/admin/users/add'}>Add User</Link></Nav.Item> }

            {doesUserHavePermission(authUser, ExtPermissions.canManageLocations)  && <Nav.Item><Link to={'/admin/locations'}>Location List</Link></Nav.Item> }
            {doesUserHavePermission(authUser, ExtPermissions.canManageLocations)  && <Nav.Item><Link to={'/admin/locations/add'}>Add Location</Link></Nav.Item> }

            {doesUserHavePermission(authUser, ExtPermissions.canManageEventTypes)  && <Nav.Item><Link to={'/admin/event-types'}>Event Types List</Link></Nav.Item> }
            {doesUserHavePermission(authUser, ExtPermissions.canManageEventTypes)  && <Nav.Item><Link to={'/admin/event-types/add'}>Add Event Type</Link></Nav.Item> }

          </Accordion.Body>
        </Accordion.Item>
        }
      </Accordion>
        
      <Nav.Item><Link to={'/login'}>Logout</Link></Nav.Item>
    </Nav>
  );
};
export default Navigation;
