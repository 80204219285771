import React, {  useCallback, useEffect,  useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import { ExtFormField, ExtFormRecord } from '../../api/api.types';
import { ExtFormFieldError, ExtFormFieldValue } from '../FormRenderer';
import FormFieldChildRenderer from './FormFieldChildRenderer';



const ExtDateTime = ({ field, isTabletOrMobile, onChange, existingValue, error, showTime, record,  getFormFieldError, trackedAtDate}: 
    { 
        field: ExtFormField, 
        isTabletOrMobile:boolean, 
        onChange:Function, 
        existingValue:Date, 
        error:ExtFormFieldError|undefined, 
        showTime:boolean 
        record:ExtFormRecord|undefined,
        getFormFieldError: Function, 
        trackedAtDate?:Date
    }) => {
  
//    const isUnmountingRef = useRef<boolean>(false);

    const [selectedDate, setSelectedDate] = useState<Date>(new Date());

     // If this is the first time we're seeing a value selected:
    const [hasBeenChanged, setHasBeenChanged] = useState<boolean>(false);
    
    const onChangeCallback = useCallback(args => {
        return onChange(args);
     }, [onChange])
 

    const onDateChanged = useCallback( ( value:Date)=>{
        if (value === null){
            return;
        }
        // Add/remove from our list of selected values:
        if (! hasBeenChanged){
            setHasBeenChanged(true);
        }

        const val:ExtFormFieldValue = {
            fid:      field.fid,
            dType:    field.dType,
            field:    field,
            value:    value,
            isError: error ? true: false,
        }

        setSelectedDate(value);
        onChangeCallback(val);    
    }, [field, error, hasBeenChanged, onChangeCallback] );


   

    useEffect(()=>{
        if (hasBeenChanged){
            // User made a change, don't get caught in a loop
            return;
        }

        if (existingValue === undefined){
            onDateChanged( new Date() );
            return;
        }
        
        onDateChanged( new Date(existingValue) );
    }, [existingValue, hasBeenChanged, onDateChanged])



    useEffect(()=>{
        return ()=>{
           
            const val:ExtFormFieldValue = {
                fid: field.fid,
                field: field,
                dType:  field.dType,
                value: undefined,
                isError: error?  true : false,
            }
            onChangeCallback(val);
        }
    },[])


    /**
     * To avoid dependency hell and ensure our main cleanup logic
     * only fires once, use a ref to track if we are unmounting.
     
       useEffect(()=>{
        return ()=> {
            isUnmountingRef.current = true;
        }
    }, [])
    


    /**
     * Cleanup effect to remove value for removed fields
     * (e.g. children that are disabled)
    
         useEffect(()=>{
            if(isUnmountingRef.current === true){
                return ()=>{
                    const val:ExtFormFieldValue = {
                        fid: field.fid,
                        field: field,
                        dType:  field.dType,
                        value: undefined,
                        isError: error?  true : false,
                    }
                    onChangeCallback(val);
                }
            }
        },[isUnmountingRef, error, field, onChangeCallback])
 */
    
return <Form.Group as={Row} className="mb-3" key={field.fid}>
            <Form.Label column md={3}>{field.required && '*'}{field.label}:</Form.Label>
            <Col sm={9} key={field.fid} >
                <ReactDatePicker
                    dateFormat="MM/dd/yyyy h:mm aa"
                    selected={selectedDate}
                    onChange={onDateChanged} //only when value has changed
                    showTimeSelect={showTime}       
                    timeIntervals={5}            
                />
                {field.hint && <p className='hint'>{field.hint}</p>}
                {error?.fid && <p className='error'>{error?.errorMessage}</p>}
            </Col>
            { field.children && field.children?.length > 0 && 
                <FormFieldChildRenderer 
                    children={field.children!} 
                    isTabletOrMobile={isTabletOrMobile}
                    onChange={onChange}
                    record={record} 
                    error={error}
                    parentValue={selectedDate}
                    getFormFieldError={getFormFieldError}
                    trackedAtDate={trackedAtDate}
                />  
            }
    </Form.Group>
}

export default ExtDateTime;