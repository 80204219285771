import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
   apiCreateEventSummaryReport,
} from "../../app/api/api";
import { serializeFeathersError } from "../../app/api/serializeFeathersError";
import { ExtRecordSearchQuery } from "../forms/recoredsSlice";


interface ExtEventSummaryReport {
    criteria: ExtRecordSearchQuery;
    createdAt: Date;
    records: Array<ExtEventSummaryReportElement>;
    recordCount: number;
  }
  
  interface ExtEventSummaryReportElement {
    location: {
      lid: string,
      name: string,
    },
    audience: string,
    locationInVenue: string,
    eventCount: number,
    visitorCount: number,
  }
  
  

export interface EventSummaryReportState {
  value: ExtEventSummaryReport | undefined;
  status: "idle" | "loading" | "failed";
}

const initialState: EventSummaryReportState = {
  value: undefined,
  status: "idle",
};

/**
 * Submit the report criteria
 */
export const createEventSummaryReport = createAsyncThunk(
  "reporting/event-summary/create",
  async (query: ExtRecordSearchQuery, { rejectWithValue }) => {
    try {
      console.log("Sending query", query);
      const response = await apiCreateEventSummaryReport(query);
      console.log("Thunk api response", response)
      return response;
    } catch (err) {
      const e = serializeFeathersError(err);

      return rejectWithValue(e);
    }
  }
);

/**
 * Creates the Redux Slice for the forms
 */
export const eventSummaryReportSlice = createSlice({
  name: "reporting/event-summary",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(createEventSummaryReport.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(createEventSummaryReport.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createEventSummaryReport.fulfilled, (state, action) => {
        state.status = "idle";
        state.value = action.payload;
        state.value?.records.sort((a, b) =>{
          return (a.location.name < b.location.name ? -1 : 1)
        }) ;

      });
  },
});


export const selectEventSummaryReport = (state: RootState) => state.eventSummaryReport.value;

export default eventSummaryReportSlice.reducer;
