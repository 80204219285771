import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import DataTable from 'react-data-table-component';
import { ExtLocation } from '../../api/api.types';
import { useNavigate } from 'react-router-dom';
import { findDeletedLocations, findLocations, selectDeletedLocations, selectLocations, selectLocationsNetStatus } from '../../../features/locations/locationsSlice';


const LocationList = ({activeLocations}:{activeLocations:boolean}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const locations = useAppSelector(selectLocations);
  const deletedLocations = useAppSelector(selectDeletedLocations);
  const locationsStatus = useAppSelector(selectLocationsNetStatus);


  useEffect(()=>{
    if (activeLocations){
        dispatch(findLocations());
    }else{
        dispatch(findDeletedLocations());
    }
   
  }, [activeLocations, dispatch])


  const columns = [
    {
      name: 'Name',
      selector: (row:any) => row.name,
      sortable: true,
      grow:3,
    },
    {
        name: 'City',
        selector: (row:any) => row.city,
        sortable: true,
    },
   /* Redundant {
        name: 'Province',
        selector: (row:any) => row.province,
        sortable: true,
    }, */
    {
        name: 'Region',
        selector: (row:any) => row.region,
        sortable: true,
    },
    {
      name: 'LoB',
      selector: (row:any) => row.lob,
      sortable: true,
    },
    {
      name: 'Updated At',
      selector: (row: any) => {
        if (row.updatedAt === undefined){
            return "Never";
        }
        return new Date(row.updatedAt).toDateString();
      },
      sortable: true,
      sortField: "updatedAt"
    },
   
];

const onLocationClicked = (loc:ExtLocation) =>{
  navigate(`/admin/locations/edit/${loc._id}`);
}

const paginationComponentOptions = {
  selectAllRowsItem: true,
  selectAllRowsItemText: 'All',
};
  
  return (
      <section className='user-list'>
        <DataTable
              columns={columns}
              data={ activeLocations ? locations : deletedLocations}
              expandableRowsHideExpander
              fixedHeaderScrollHeight="300px"
              pagination
              responsive
              subHeaderWrap
              highlightOnHover
              pointerOnHover
              progressPending={locationsStatus === 'loading'}
              paginationComponentOptions={paginationComponentOptions}
              striped
              onRowClicked={(loc)=>onLocationClicked(loc)}
          />
      </section>
  );
}


export default LocationList;