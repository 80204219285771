import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import formReducer from '../features/forms/formsSlice';
import locationReducer from '../features/locations/locationsSlice';
import authReducer from '../features/auth/authSlice';
import recordsReducer from '../features/forms/recoredsSlice';
import usersReducer from '../features/users/usersSlice';
import notificationsReducer from '../features/notifications/notificationSlice';
import staffReducer from '../features/users/staffSlice';
import searchReducer from '../features/search/searchSlice';
import sumReportReducer from '../features/reports/sumReportSlice';
import cumulativeTimeReportReducer from '../features/reports/staffTotalTimeReportSlice';
import eventSummaryReportSliceReducer from '../features/reports/eventSummaryReportSlice';
import interactionTimesReportReducer from '../features/reports/interactionTimesReportSlice';
import summaryReportSlice from '../features/reports/summaryReportSlice';
import filterReportSlice from '../features/reports/filterReportSlice';
import eventTypeReducer from '../features/events/eventTypeSlice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    forms: formReducer,
    locations: locationReducer,
    eventTypes: eventTypeReducer,
    auth: authReducer,
    records: recordsReducer,
    users: usersReducer,
    notifications: notificationsReducer,
    staff: staffReducer,
    searchCriteria: searchReducer,
    sumReport: sumReportReducer,
    staffTotalTimeReport: cumulativeTimeReportReducer,
    eventSummaryReport: eventSummaryReportSliceReducer,
    interactionTimesReport: interactionTimesReportReducer,
    summaryReport:summaryReportSlice,
    filterReport:filterReportSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
