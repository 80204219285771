import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../components/Header";
import Navigation from "../components/Navigation";
import { useMediaQuery } from "react-responsive";
import Footer from "../components/Footer";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router-dom";

const MainLayout = ({ content }: { content: React.ReactNode }) => {
  let navigate = useNavigate(); 

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [isIdle, setIsIdle] = useState<boolean>(false);

  const onIdle = () => {
    // Close Modal Prompt
    // Do some idle action like log out your user
   
    if (isIdle){
      return;
    }
    setIsIdle(true);
    console.log("IDLE FIRED");
    navigate("/login");
  }

  const onActive = (event:any) => {
    // Close Modal Prompt
    // Do some active action
    setIsIdle(false);
  }



  useIdleTimer({ onIdle,onActive,timeout: 1000 * 60 * 30 }) 

  return (
    <React.Fragment>
      <Container fluid="xl">
        <Row>
          <Header />
        </Row>
        <Row>
          {!isTabletOrMobile && (
            <Col sm={3}>
              <Navigation />
            </Col>
          )}
          <Col sm={isTabletOrMobile ? 12 : 9} className="content">
            {content}
          </Col>
        </Row>
        
        <Footer isTabletOrMobile={isTabletOrMobile} />
        
      </Container>
    </React.Fragment>
  );
};

export default MainLayout;
