import React, {  useCallback, useEffect, useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import { ExtFormField, ExtFormRecord, ExtOption } from '../../api/api.types';
import { ExtFormFieldError, ExtFormFieldValue } from '../FormRenderer';
import FormFieldChildRenderer from './FormFieldChildRenderer';



const ExtCheckBox = ({ field, isTabletOrMobile, onChange, existingValues, error, record, getFormFieldError, trackedAtDate }: 
    { 
        field: ExtFormField, 
        isTabletOrMobile:boolean, 
        onChange:Function, 
        existingValues:Array<string>, 
        error:ExtFormFieldError|undefined 
        record:ExtFormRecord|undefined,    
        getFormFieldError: Function,
        trackedAtDate?:Date
    }) => {
    
    // List of selected value ID's
    const [selectedValues, setSelectedValues] = useState<Array<string>>([]);
    const [filteredOptionsList, setFilteredOptionsList] = useState<Array<ExtOption>>([]);

     // If this is the first time we're seeing a value selected:
    const [hasBeenChanged, setHasBeenChanged] = useState<boolean>(false);
    
    const onChangeCallback = useCallback(args => {
        return onChange(args);
     }, [onChange])
 


   
    const onCheckClicked = ( (id:string, checked:boolean)=>{
        // Add/remove from our list of selected values:
        if (! hasBeenChanged){
            setHasBeenChanged(true);
        }

        let newList = [...selectedValues];
        if (checked){
            newList = [...selectedValues, id];
            setSelectedValues(newList);
        }else{
            const idx = selectedValues.indexOf(id);
            newList.splice(idx, 1);
            setSelectedValues(newList);
        }

        const val:ExtFormFieldValue = {
            fid:    field.fid,
            field:  field,
            dType:  field.dType,
            value:  newList,
            isError: error? true: false,
        }
       
        onChangeCallback(val);
    })


    useEffect(()=>{
        if (hasBeenChanged){
            // User made a change, don't get caught in a loop
            return;
        }

        if (existingValues === undefined){
            setSelectedValues([]);
            return;
        }

        setSelectedValues(existingValues);

        const val:ExtFormFieldValue = {
            fid:    field.fid,
            field:  field,
            dType:  field.dType,
            value:  existingValues,
            isError: error? true: false,
        }
       
        onChangeCallback(val);

    }, [existingValues, hasBeenChanged, onChangeCallback, error, field])




    /** 
     * Filter the available options based on what's available on the selected date
     */
    useEffect(()=>{
        const filteredList:Array<ExtOption> = [];
        field.options.forEach( (opt:ExtOption)=>{
            if (opt.enabledDates !== undefined && trackedAtDate !== undefined){
                const past = new Date(opt.enabledDates.startDate);
                const future = new Date(opt.enabledDates.endDate);
                if (trackedAtDate >= past && trackedAtDate < future){
                    filteredList.push(opt);
                }else{
                   // We skip this option. 
                }
            }else{
                // No restriction, so add:
                filteredList.push(opt);
            }
        })
        setFilteredOptionsList(filteredList);
          
    }, [trackedAtDate])



    /**
     * Cleanup effect to remove value for removed fields
     * (e.g. children that are disabled)
     */
    useEffect(()=>{
        return ()=>{
            const val:ExtFormFieldValue = {
                fid: field.fid,
                field: field,
                dType:  field.dType,
                value: undefined,
                isError: error?  true : false,
            }
            onChangeCallback(val);
        }
    },[])
       

    return <React.Fragment>
        <Form.Group as={Row} className="mb-3" key={field.fid}>
        <Row className='check-label'><label>{field.required && '*'} {field.label}:</label></Row>
        <Row  className='check'>
            {filteredOptionsList.map( (opt:ExtOption) => {
                return <Form.Check
                    type={isTabletOrMobile ? 'switch' : 'checkbox'}
                    id={`${field.fid}-${opt.oid}`}
                    label={opt.label}
                    key={opt.oid}
                    name={field.fid}
                    checked={ selectedValues.indexOf(opt.oid) > -1 ? true : false  }
                    onChange={(e:any)=>{onCheckClicked(opt.oid, e.target.checked)}}
                />
            })}
            {field.hint && <p className='hint'>{field.hint}</p>}
            {error?.fid && <p className='error'>{error?.errorMessage}</p>}
        </Row>   
        { field.children && field.children?.length > 0 && 
            <FormFieldChildRenderer 
                children={field.children!} 
                isTabletOrMobile={isTabletOrMobile}
                onChange={onChange}
                record={record} 
                error={error}
                parentValue={selectedValues}
                getFormFieldError={getFormFieldError}
                trackedAtDate={trackedAtDate}
            />  
        }
        </Form.Group>
    </React.Fragment>
}

export default ExtCheckBox;