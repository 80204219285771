import React, { useEffect, useState } from "react";
import { Button, Col, Container,  Form, Modal, Row } from "react-bootstrap";
import { createLocation, deleteLocation, findLocations, getLocation, selectLocation, selectLocationsNetStatus, updateLocation } from "../../../features/locations/locationsSlice";
import { ExtLineOfBusiness, ExtLocation,   ExtRegion, LineOfBusinessList } from "../../api/api.types";
import { useAppDispatch } from "../../hooks";
import { showNotification } from '../../../features/notifications/notificationSlice';
import './LocationEditor.scss';
import scrollToTop from "../../../utils/scrollTop";
import {  useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";


//import { Formik } from "formik";
//import { object, string, number, date, InferType, boolean } from 'yup';

const regionList:Array<ExtRegion> = [
    {
        "rid": "CENTRAL",
        "name": "Central"
    },
    {
        "rid": "CENTRAL_NORTH",
        "name": "Central North"
    },
    {
        "rid": "EAST",
        "name": "East"
    },
    {
        "rid": "SOUTH",
        "name": "South"
    },
    {
        "rid": "SOUTH_EAST",
        "name": "South East"
    },
    {
        "rid": "SOUTH_WEST",
        "name": "South West"
    },   
    
]

const provList = ['AB', 'BC', 'MB', 'NB', 'NL', 'NT', 'NS', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT'];


const LocationEditor = ({isTabletOrMobile}:{ isTabletOrMobile:boolean}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();


    const { id } = useParams();

    const editLocation = useSelector(selectLocation);
    const locationNetStatus = useSelector(selectLocationsNetStatus);

    const [name, setName] = useState<string>('');
    const [margi, setMargi] = useState<string>('');
    const [address1, setAddress1] = useState<string>('');
    const [address2, setAddress2] = useState<string>('');
    const [city, setCity] = useState<string>('');
    const [province, setProvince] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [region, setRegion] = useState<string>('');
    const [ lob, setLob] = useState<string>('');

    const [isEditExisting, setIsEditExisting] = useState<boolean>(false);

    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState<boolean>(false);


    useEffect(()=>{
        const loadUser = async (id:string|undefined) =>{
            if (id !== undefined) {
                setIsEditExisting(true);
                const resp = await dispatch(getLocation(id));
                if (resp.payload !== undefined){
                    switch (resp.payload.code){
                        case undefined:
                            // Nothing to do, all good
                        break;
                        case 404:
                            setIsDisabled(true);
                            dispatch(showNotification({
                                header: 'Location not found',
                                body: resp.payload.message,
                                type: 'error'
                            }));
                            break;
                        default:
                            setIsDisabled(true);
                            dispatch(showNotification({
                                header: 'Something went wrong',
                                body: resp.payload.message,
                                type: 'error'
                            }))
                    }
                }
            }
        }
        if (id === undefined){
            resetData();
            setIsEditExisting(false);
        }
        resetData();
        loadUser(id);
    }, [id, dispatch])


    useEffect(()=>{
        if (id && editLocation !== undefined){
            setAddress1(editLocation.address1);
            setAddress2(editLocation.address2);
            setCity(editLocation.city);
            setMargi(editLocation.margi);
            setName(editLocation.name);
            setPhone(editLocation.phone);
            setProvince(editLocation.province)
            setRegion(editLocation.region)
            setLob(editLocation.lob)

            if (editLocation.deletedAt){
                setIsDisabled(true)
            }else{
                setIsDisabled(false)
            }
        }
    }, [editLocation])


  


    /**
     * Wipe out any existing form data
     */
    const resetData = ()=>{
          setAddress1('');
        setAddress2('');
        setCity('');
        setMargi('');
        setName('');
        setPhone('');
        setProvince('')
        setRegion('')
    }



    /**
     * When submit is clicked on the form
     * @param event Form Event
     */
    const onSubmitClicked = async (event:any)=>{
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            return;
        }

        const newId = name.replace(/\s/g, "") + Math.random() * 1000;

        const loc:ExtLocation = {
            _id: id ? id : newId, 
            address1:address1,
            address2:address2,
            city:city,
            margi:margi,
            name:name,
            phone: phone,
            province:province,
            region:region,
            lob: lob,
        }

      
       
        let result;
        if (isEditExisting){
          //  loc._id = editLocation?._id;
            result = await dispatch(updateLocation({location:loc}));
        }else{
            result = await dispatch(createLocation({location: loc}));
        }
        
        
        scrollToTop()
       

        if (result.meta.requestStatus === 'rejected'){
            dispatch(showNotification({
                header: 'Something went wrong',
                body: `${result.payload.name}: ${result.payload.message}`,
                type: 'error'
            }))
        }else{
            dispatch(showNotification({
                header: 'Success!',
                body: 'The location was saved successfully',
                type: 'success'
            }))
            resetData();
            dispatch(findLocations())
            navigate('/admin/locations')
   
        }


       
    }


    const onDeleteLocation = async ()=>{
        if (editLocation){
            const result = await dispatch(deleteLocation({id: editLocation._id }))

            if (result.meta.requestStatus === 'rejected'){
                dispatch(showNotification({
                    header: 'Something went wrong',
                    body: `${result.payload.name}: ${result.payload.message}`,
                    type: 'error'
                }))
            }else{
                dispatch(showNotification({
                    header: 'Success!',
                    body: 'The location was deleted',
                    type: 'success'
                }))
                resetData();
                navigate('/admin/locations')
       
            }
        }  

    }

  return (
    <Container className='input-form location-editor'  fluid='xl' >
        {isEditExisting ? <h2>Update Location</h2> : <h2>Add New Location</h2>}
        <hr/>

        {locationNetStatus === 'loading' ? <h3>Loading...</h3>  : <Form onSubmit={onSubmitClicked}   >
            <fieldset disabled={isDisabled }>
            <section key='basic-info' className='input-section'>
          
                <Form.Group as={Row} className="mb-3" controlId="name">
                    <Form.Label column md={3}>*Name:</Form.Label>
                    <Col md={9} >
                        <Form.Control 
                            name='name' 
                            type="text" 
                            placeholder="Location name"    
                            required
                            value={name}
                            onChange={e => setName( e.target.value)}
                        />
                        
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="address1">
                    <Form.Label column md={3}>Address 1:</Form.Label>
                    <Col md={9} >
                        <Form.Control 
                            name='address1'
                            type="text" 
                            placeholder="Address 1" 
                            
                            value={address1}
                            onChange={e => setAddress1( e.target.value)}
                        />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="address1">
                    <Form.Label column md={3}>Address 2:</Form.Label>
                    <Col md={9} >
                        <Form.Control 
                            name='address2'
                            type="text" 
                            placeholder="Address 2" 
                            value={address2}
                            onChange={e => setAddress2( e.target.value)}
                        />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="city">
                    <Form.Label column md={3}>*City:</Form.Label>
                    <Col md={9} >
                        <Form.Control 
                            name='city'
                            type="text" 
                            placeholder="City" 
                            required
                            value={city}
                            onChange={e => setCity( e.target.value)}
                        />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="province">
                    <Form.Label column md={3}>*Province:</Form.Label>
                    <Col md={9} >
                        <Form.Select aria-label="region" 
                            
                            value={province}
                            onChange={e => setProvince(e.target.value) }  >
                                <option value=''>Select</option>
                                {provList.map( (prov:string) => {
                                    return <option key={prov} value={prov}>{prov}</option>
                                })}
                            </Form.Select>
                    </Col>
                </Form.Group>
  
                <Form.Group as={Row} className="mb-3" controlId="phone">
                    <Form.Label column md={3}>Phone:</Form.Label>
                    <Col md={9} >
                        <Form.Control 
                            name='phone'
                            type="phone" 
                            placeholder="Phone" 
                            
                            value={phone}
                            onChange={e => setPhone( e.target.value)}
                        />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="margi">
                    <Form.Label column md={3}>MARGI:</Form.Label>
                    <Col md={9} >
                        <Form.Control 
                            name='margi'
                            type="text" 
                            placeholder="MARGI" 
                            
                            value={margi}
                            onChange={e => setMargi( e.target.value)}
                        />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="region">
                    <Form.Label column md={3}>*Region:</Form.Label>
                        <Col md={9} >
                        <Form.Select aria-label="region" 
                            required
                            value={region}
                            onChange={e => setRegion(e.target.value) }  >
                                <option value=''>Select</option>
                                {regionList.map( (reg:ExtRegion) => {
                                    return <option key={`${reg.rid}`} value={reg.rid}>{reg.name}</option>
                                })}
                            </Form.Select>
                        </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="lob">
                    <Form.Label column md={3}>*Line of Business:</Form.Label>
                        <Col md={9} >
                        <Form.Select aria-label="lob" 
                            required
                            value={lob}
                            onChange={e => setLob(e.target.value) }  >
                                <option value=''>Select</option>
                                {LineOfBusinessList.map( (reg:ExtLineOfBusiness) => {
                                    return <option key={`${reg.lid}`} value={reg.lid}>{reg.name}</option>
                                })}
                            </Form.Select>
                        </Col>
                </Form.Group>
            </section>

            {isEditExisting && 
                <section className="warning">
                    <p>Please note: editing an existing location is intended <strong>only</strong> for correcting things like spelling, etc. 
                     If you edit an existing location these changes will apply to <em>all submitted records and reports, existing or future</em>. 
                     If this is not what you want please mark this location as deleted and create a new one to keep old forms and reports accurate.
                    </p>
                </section>
            }
         
            <section className='form-controls'>
                <Button variant="primary" type="submit" disabled={editLocation?.deletedAt !== undefined} >
                    {isEditExisting ? "Update Location" : "Create Location"}
                </Button>
                {isEditExisting && <Button variant="danger" onClick={()=>setIsConfirmDeleteOpen(true) }  disabled={editLocation?.deletedAt !== undefined}>Mark Deleted</Button> }
            </section> 
        </fieldset>

        
      </Form>
}

    <Modal show={isConfirmDeleteOpen} onHide={()=>setIsConfirmDeleteOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Location?</Modal.Title>
        </Modal.Header>
        <Modal.Body>This will mark the location as inactive. You won't be able to use it with any forms going forward.</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={()=>setIsConfirmDeleteOpen(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={()=>onDeleteLocation()}>
            DELETE
          </Button>
        </Modal.Footer>
      </Modal>

    </Container>
  );
};

export default LocationEditor;
