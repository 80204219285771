import React, {  useCallback, useEffect,  useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { ExtFormField, ExtFormRecord, ExtOption } from '../../api/api.types';
import { ExtFormFieldError, ExtFormFieldValue } from '../FormRenderer';
import FormFieldChildRenderer from './FormFieldChildRenderer';
import doesUserHavePermission from '../../../utils/doesUserHavePermission';
import { useAppSelector } from '../../hooks';
import { selectAuthUser } from '../../../features/auth/authSlice';



const ExtSelect = ({ field, isTabletOrMobile, onChange, existingValue, error, record, getFormFieldError, trackedAtDate }: 
    { 
        field: ExtFormField, 
        isTabletOrMobile:boolean, 
        onChange:Function, 
        existingValue:string, 
        error:ExtFormFieldError|undefined 
        record:ExtFormRecord|undefined,    
        getFormFieldError: Function,
        trackedAtDate?:Date
    }) => {
  
    const authUser = useAppSelector(selectAuthUser);
 //   const isUnmountingRef = useRef<boolean>(false);

    const [selectedValue, setSelectedValue] = useState<string>();

     // If this is the first time we're seeing a value selected:
    const [hasBeenChanged, setHasBeenChanged] = useState<boolean>(false);
    const [filteredOptionsList, setFilteredOptionsList] = useState<Array<ExtOption>>([]);



    const onChangeCallback = useCallback(args => {
        return onChange(args);
     }, [onChange])
 


    const onSelectChanged = useCallback( (id:string)=>{
        // Add/remove from our list of selected values:
        if (! hasBeenChanged){
            setHasBeenChanged(true);
        }

        const val:ExtFormFieldValue = {
            fid:      field.fid,
            dType:    field.dType,
            field:    field,
            value:    id,
            isError: error ? true: false,
        }
        
        // Set the option value if applicable:
        const opt = field.options.find( (o)=>{
            return o.oid === id;
        });
        
        if (opt?.value !== undefined){
            val.optValue = opt.value;
        }
        

        setSelectedValue(id);
        onChangeCallback(val);    
    }, [field, error, hasBeenChanged, onChangeCallback])


    useEffect(()=>{
       
        if (hasBeenChanged){
            // User made a change, don't get caught in a loop
            return;
        }

        if (existingValue === undefined){
            setSelectedValue("");
            return;
        }
 
        onSelectChanged(existingValue);
    }, [existingValue, hasBeenChanged, onSelectChanged])


     /** 
     * Filter the available options based on what's available on the selected date
     * and/or user's permission
     */
      useEffect(()=>{
        const filteredList:Array<ExtOption> = [];
        field.options.forEach( (opt:ExtOption)=>{
            if (opt.enabledDates !== undefined && trackedAtDate !== undefined){
                const past = new Date(opt.enabledDates.startDate);
                const future = new Date(opt.enabledDates.endDate);
                if (trackedAtDate >= past && trackedAtDate < future){
                   // filteredList.push(opt);
                }else{
                   
                   // We skip this option. 
                   console.warn("Skipping option: ", opt);
                   return;
                }
            }
            
            // Do a permission check:
            if (opt.permissions !== undefined){
              
                const hasPerm = doesUserHavePermission(authUser, opt.permissions)
                if (!hasPerm){
                    console.warn("User doesn't have permission for field ", opt.oid)
                    return; // Don't draw it
                }
            }
            // Relational options may be marked deleted vs. enabled date ranges
            // If it's a new form, hide deleted. If it's an old form check 
            // when it was deleted:
            if (opt.deletedAt){
                const delDate = new Date(opt.deletedAt)
                // if tracked at, it may have been valid
                if(trackedAtDate !== undefined){
                    if((opt.deletedAt !== undefined) && (trackedAtDate >= delDate) ){
                        // Skip, deleted value (like event type reference)
                        return;
                    }
                }
                else{
                    // If it's past now, hide it:
                    const now = (new Date());
                   
                    if (now > delDate){
                        return;
                    }
                }
            }

            filteredList.push(opt);
        })
        setFilteredOptionsList(filteredList);
     
    }, [trackedAtDate])


    /**
     * If the user has a form visible then changed to a form
     * that still has this control, make sure we pick-up 
     * the change to existing data emptying, allowing this
     * to change again (to the empty value).
     */
    useEffect(()=>{
        if (existingValue === undefined){
            setHasBeenChanged(false)
        }
    },[existingValue])


    /**
     * To avoid dependency hell and ensure our main cleanup logic
     * only fires once, use a ref to track if we are unmounting.
     
    useEffect(()=>{
        return ()=> {
            isUnmountingRef.current = true;
        }
    }, [])*/
    
    /** DISABLED: Was using a unmounting ref, not firing when it should
     * Cleanup effect to remove value for removed fields
     * (e.g. children that are disabled)
     * Only really works if we're unmounting.
     */
    /*
    useEffect(()=>{
        if(isUnmountingRef.current === true){
            return ()=>{
                console.log(" **SELECT Unmounting")
                const val:ExtFormFieldValue = {
                    fid: field.fid,
                    field: field,
                    dType:  field.dType,
                    value: undefined,
                    isError: error?  true : false,
                }
                onChangeCallback(val);
            }
        }
    },[isUnmountingRef, error, field, onChangeCallback])
*/


 /**
     * Cleanup effect to remove value for removed fields
     * (e.g. children that are disabled)
     */
  useEffect(()=>{
    return ()=>{
       
        const val:ExtFormFieldValue = {
            fid: field.fid,
            field: field,
            dType:  field.dType,
            value: undefined,
            isError: error?  true : false,
        }
        onChangeCallback(val);
    }
},[])

return <Form.Group as={Row} className="mb-3" key={field.fid}>
    <Form.Label column md={3}>{field.required && '*'}{field.label}:</Form.Label>
        <Col md={9} key={field.fid} >
            <Form.Select aria-label={field.label} 
            
            value={selectedValue}
            onChange={e => onSelectChanged(e.target.value) }  >
                <option value=''>Select</option>
                {filteredOptionsList.map( (opt:ExtOption) => {
                    return <option key={`${field.fid}-${opt.oid}`} value={opt.oid}>{opt.label}</option>
                })}
            </Form.Select>
            {field.hint && <p className='hint'>{field.hint}</p>}
            {error?.fid && <p className='error'>{error?.errorMessage}</p>}
        </Col>
        { field.children && field.children?.length > 0 && 
            <FormFieldChildRenderer 
                children={field.children!} 
                isTabletOrMobile={isTabletOrMobile}
                onChange={onChange}
                record={record} 
                error={error}
                parentValue={selectedValue}
                getFormFieldError={getFormFieldError}
                trackedAtDate={trackedAtDate}
            />  
        }
    </Form.Group>
}

export default ExtSelect;