import React from 'react';

import {
  BrowserRouter ,
  Routes,
  Route,
//  Link
} from "react-router-dom";
import './App.scss';
import Dashboard from './app/components/Dashboard';
import Login from './app/pages/Login';
import NotFound from './app/pages/NotFound';
import './App.scss';
import PatronVisitorForm from './app/components/Forms/PatronVisitorForm';
import MainLayout from './app/Layouts/MainLayout';
import FormRenderer from './app/components/FormRenderer';
import PrivateRoute from './app/components/PrivateRoute';
import ReAuthenticate from './app/components/ReAuthenticate';
import UserManager from './app/components/UserManager';
import { useMediaQuery } from 'react-responsive';
import ApiWatchers from './app/components/ApiWatchers';
import UserEditor from './app/components/UserManagement/UserEditor';
import SearchRecords from './app/components/SearchRecords';
import ForgotPassword from './app/pages/ForgotPassword';
import PasswordReset from './app/pages/PasswordReset';
import ReportCumulativeTotals from './app/components/Reports/ReportCumulativeTotals';
import ReportCumulativeTime from './app/components/Reports/ReportStaffTime';
import ReportEventSummary from './app/components/Reports/ReportEventSummary';
import ReportInteractionTimes from './app/components/Reports/ReportInteractionTimes';
import LocationManager from './app/components/LocationManager';
import LocationEditor from './app/components/LocationManagement/LocationEditor';
import ReportSummary from './app/components/Reports/ReportSummary';
import ReportFiltered from './app/components/Reports/ReportFiltered';
import EventTypeEditor from './app/components/EventManagement/EventTypeEditor';
import EventTypeList from './app/components/EventManagement/EventTypeList';
import EventTypeManager from './app/components/EventTypeManager';


const App = () => {

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })
  

  return (
    <BrowserRouter>
        <ReAuthenticate />
        <ApiWatchers />
        <Routes>
          <Route path="/" element={
            <PrivateRoute children = {
             <MainLayout content={<Dashboard isTabletOrMobile={isTabletOrMobile} />}/>
            } />
          }/>

          <Route path="/search" element={
            <PrivateRoute children = {
             <MainLayout content={<SearchRecords isTabletOrMobile={isTabletOrMobile} />}/>
            } />
          }/>

          <Route path="/forms/:slug" element={
            <PrivateRoute children = {
              <MainLayout content={<FormRenderer isTabletOrMobile={isTabletOrMobile} />}/>
            } />
          }/>
          <Route path="/forms/:slug/:id" element={
            <PrivateRoute children = {
              <MainLayout content={<FormRenderer isTabletOrMobile={isTabletOrMobile} />}/>
            } />
          }/>

          <Route path="/reports/summary" element={
            <PrivateRoute children = {
             <MainLayout content={<ReportSummary isTabletOrMobile={isTabletOrMobile} />}/>
            } />
          }/>

          <Route path="/reports/cumulative-totals" element={
            <PrivateRoute children = {
             <MainLayout content={<ReportCumulativeTotals isTabletOrMobile={isTabletOrMobile} />}/>
            } />
          }/>

          <Route path="/reports/event-summary" element={
            <PrivateRoute children = {
             <MainLayout content={<ReportEventSummary isTabletOrMobile={isTabletOrMobile} />}/>
            } />
          }/>

          <Route path="/reports/interaction-times" element={
            <PrivateRoute children = {
             <MainLayout content={<ReportInteractionTimes isTabletOrMobile={isTabletOrMobile} />}/>
            } />
          }/>

          <Route path="/reports/staff-time" element={
            <PrivateRoute children = {
             <MainLayout content={<ReportCumulativeTime isTabletOrMobile={isTabletOrMobile} />}/>
            } />
          }/>

          <Route path="/reports/filtered" element={
            <PrivateRoute children = {
             <MainLayout content={<ReportFiltered isTabletOrMobile={isTabletOrMobile} />}/>
            } />
          }/>

          <Route path="/admin/users" element={
            <PrivateRoute children = {
              <MainLayout content={ <UserManager isTabletOrMobile={isTabletOrMobile}/> }/>
            } />
          }/>
           <Route path="/admin/users/add" element={
            <PrivateRoute children = {
              <MainLayout content={ <UserEditor isTabletOrMobile={isTabletOrMobile}/> }/>
            } />
          }/>
          <Route path="/admin/users/edit/:id" element={
            <PrivateRoute children = {
              <MainLayout content={ <UserEditor isTabletOrMobile={isTabletOrMobile}/> }/>
            } />
          }/>

          
          <Route path="/admin/locations" element={
            <PrivateRoute children = {
              <MainLayout content={ <LocationManager isTabletOrMobile={isTabletOrMobile}/> }/>
            } />
          }/>
          <Route path="/admin/locations/add" element={
            <PrivateRoute children = {
              <MainLayout content={ <LocationEditor isTabletOrMobile={isTabletOrMobile}/> }/>
            } />
          }/>
          <Route path="/admin/locations/edit/:id" element={
            <PrivateRoute children = {
              <MainLayout content={ <LocationEditor isTabletOrMobile={isTabletOrMobile}/> }/>
            } />
          }/>

          <Route path="/admin/event-types" element={
            <PrivateRoute children = {
              <MainLayout content={ <EventTypeManager isTabletOrMobile={isTabletOrMobile}/> }/>
            } />
          }/>
          <Route path="/admin/event-types/add" element={
            <PrivateRoute children = {
              <MainLayout content={ <EventTypeEditor isTabletOrMobile={isTabletOrMobile}/> }/>
            } />
          }/>
          <Route path="/admin/event-types/edit/:id" element={
            <PrivateRoute children = {
              <MainLayout content={ <EventTypeEditor isTabletOrMobile={isTabletOrMobile}/> }/>
            } />
          }/>
          
          {/*
          <Route path="/forms/patron-visitor" element={<MainLayout content={<PatronVisitorForm/>}/>}/>
          <Route path="/forms/patron-interaction" element={<MainLayout content={<PatronInteractionForm/>}/>}/>
          <Route path="/forms/event" element={<MainLayout content={<EventForm/>}/>}/>
          <Route path="/forms/gaming-staff-visitor" element={<MainLayout content={<GamingStaffVisitorForm/>}/>}/>
          <Route path="/forms/end-shift-summary" element={<MainLayout content={<EndOfShiftSummaryForm/>}/>}/>
  */}
          <Route path="/login" element={<Login/>}/>
          <Route path="/forgot-password" element={<ForgotPassword />}/>
          <Route path="/password-reset" element={<PasswordReset />}/>
          <Route path="*" element={<NotFound/>}/>
          <Route path="/test" element={<MainLayout content={<PatronVisitorForm/>}/>}/>
        </Routes>   
    </BrowserRouter>
  );
}

export default App;
/*
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <Counter />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <span>
          <span>Learn </span>
          <a
            className="App-link"
            href="https://reactjs.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            React
          </a>
          <span>, </span>
          <a
            className="App-link"
            href="https://redux.js.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Redux
          </a>
          <span>, </span>
          <a
            className="App-link"
            href="https://redux-toolkit.js.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Redux Toolkit
          </a>
          ,<span> and </span>
          <a
            className="App-link"
            href="https://react-redux.js.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            React Redux
          </a>
        </span>
      </header>
    </div>
  );
}

export default App;
*/