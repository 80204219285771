import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { selectLocations } from "../../../features/locations/locationsSlice";
import { createUser,  getUser, selectUser, selectUsersNetStatus, updateUser } from "../../../features/users/usersSlice";
import { ExtLocation,  ExtPermissions,  ExtRegion,  ExtRegionList, ExtUser } from "../../api/api.types";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { showNotification } from '../../../features/notifications/notificationSlice';
import './UserEditor.scss';
import scrollToTop from "../../../utils/scrollTop";
import {  useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import doesUserHavePermission from "../../../utils/doesUserHavePermission";
import { selectAuthUser } from "../../../features/auth/authSlice";

//import { Formik } from "formik";
//import { object, string, number, date, InferType, boolean } from 'yup';

const UserEditor = ({isTabletOrMobile}:{ isTabletOrMobile:boolean}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

/*
    const schema = object().shape({
        email: string().email().required(),
        firstName: string().required(),
        lastName: string().required(),
        password: string().required(),
        state: string().required(),
        zip: string().required(),
       // terms: boolean().required().oneOf([true], 'Terms must be accepted'),
    });
*/

    const { id } = useParams();
    const locations = useAppSelector(selectLocations);
    const authUser = useAppSelector(selectAuthUser);

    const editUser = useSelector(selectUser);
    const userNetStatus = useSelector(selectUsersNetStatus);

    const [email, setEmail] = useState<string>('');
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [location, setSelectedLocation] = useState<string>('');
    const [selectedRegion, setSelectedRegion] = useState<string>('');

    const [associatedLocations, setAssociatedLocations] = useState<Array<string>>([]);
    const [permissions, setPermissions] = useState<Array<string>>([ ExtPermissions.isActive, ExtPermissions.canSubmitForms])
    
    const [isEditExisting, setIsEditExisting] = useState<boolean>(false);

    const [isDisabled, setIsDisabled] = useState<boolean>(false);


    useEffect(()=>{
        const loadUser = async (id:string|undefined) =>{
            if (id !== undefined) {
                setIsEditExisting(true);
                const resp = await dispatch(getUser(id));
                if (resp.payload !== undefined){
                    switch (resp.payload.code){
                        case undefined:
                            // Nothing to do, all good
                        break;
                        case 404:
                            setIsDisabled(true);
                            dispatch(showNotification({
                                header: 'User not found',
                                body: resp.payload.message,
                                type: 'error'
                            }));
                            break;
                        default:
                            setIsDisabled(true);
                            dispatch(showNotification({
                                header: 'Something went wrong',
                                body: resp.payload.message,
                                type: 'error'
                            }))
                    }
                }
            }
        }
        if (id === undefined){
            resetData();
            setIsEditExisting(false);
        }
        loadUser(id);
    }, [id, dispatch])


    useEffect(()=>{
        if (editUser !== undefined){
            setEmail(editUser.email);
            setFirstName(editUser.firstName);
            setLastName(editUser.lastName);
            setSelectedLocation(editUser.defaultLocation);
            setSelectedRegion(editUser.region);
            setAssociatedLocations(editUser.locations);
            setPermissions(editUser.permissions);
        }
    }, [editUser])


    /**
     * When a location checkbox is clicked
     * @param id Location ID
     * @param checked Is it checked?
     * @param forcedAdd Is this forcibly added (prevents a toggle action)
     */
    const onLocationClicked = ( (id:string, checked:boolean, forcedAdd:boolean = false)=>{
        // Add/remove from our list of selected values:
 
        let newList:Array<string> = [];
        if (associatedLocations !== undefined){
           newList = [...associatedLocations]
        }

        if (checked){
            newList = [...newList, id];
            setAssociatedLocations(newList);
        }else{
            if (! forcedAdd){
                const idx = associatedLocations.indexOf(id);
                newList.splice(idx, 1);
                setAssociatedLocations(newList);
            }
        }
    })


    /**
     * When a location is selected in the drop-down
     * @param id Location ID
     */
    const setDefaultLocation = ( (id:string)=>{
        setSelectedLocation(id);
        onLocationClicked(id, true, true);
    })


    const setRegion = ( (id:string)=>{
        setSelectedRegion(id);
    })


    /**
     * Wipe out any existing form data
     */
    const resetData = ()=>{
        setEmail('');
        setFirstName('');
        setLastName('');
        setSelectedLocation('');
        setAssociatedLocations([]);
        setPermissions([ ExtPermissions.isActive, ExtPermissions.canSubmitForms]);
    }


    /**
     * When a permission box is checked
     * @param id Permission ID
     * @param checked If it's checked
     */
    const onPermissionClicked = ( (id:string, checked:boolean)=>{
        // Add/remove from our list of selected values:
        let newList = [...permissions];
        if (checked){
            newList = [...permissions, id];
            setPermissions(newList);
        }else{
            const idx = permissions.indexOf(id);
            newList.splice(idx, 1);
            setPermissions(newList);
        
        }

    })

    
    /**
     * When submit is clicked on the form
     * @param event Form Event
     */
    const onSubmitClicked = async (event:any)=>{
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            return;
        }

        const usr:ExtUser = {
            email:      email,
            firstName:  firstName,
            lastName:   lastName,
            active:     permissions.indexOf(ExtPermissions.isActive) > -1 ,
            defaultLocation: location,
            region: selectedRegion,
            locations: associatedLocations,
            permissions: permissions,
        }

        if (password !== ''){
            usr.password = password;
        }
       
        let result;
        if (isEditExisting){
            usr._id = editUser?._id;
            result = await dispatch(updateUser({user:usr}));
        }else{
            result = await dispatch(createUser({user:usr}));
        }
        
        
        scrollToTop()
       

        if (result.meta.requestStatus === 'rejected'){
            dispatch(showNotification({
                header: 'Something went wrong',
                body: `${result.payload.name}: ${result.payload.message}`,
                type: 'error'
            }))
        }else{
            dispatch(showNotification({
                header: 'Success!',
                body: 'The user was saved successfully',
                type: 'success'
            }))
            resetData();
            navigate('/admin/users')
   
        }

       
       
    }


  return (
    <Container className='input-form user-editor'  fluid='xl' >
        {isEditExisting ? <h2>Update User</h2> : <h2>Add New User</h2>}
        <hr/>

        {userNetStatus === 'loading' ? <h3>Loading...</h3>  : <Form onSubmit={onSubmitClicked} >
            <fieldset disabled={isDisabled}>
            <section key='basic-info' className='input-section'>
                <h4>Basic Information</h4>
                <Form.Group as={Row} className="mb-3" controlId="email">
                    <Form.Label column md={3}>Email address:</Form.Label>
                    <Col md={9} >
                        <Form.Control 
                            name='email' 
                            type="email" 
                            placeholder="Enter email"    
                            required
                            value={email}
                            onChange={e => setEmail( e.target.value)}
                        />
                        
                        <Form.Text className="text-muted">
                            User will login with this address
                        </Form.Text>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="firstName">
                    <Form.Label column md={3}>First Name:</Form.Label>
                    <Col md={9} >
                        <Form.Control 
                            name='firstName'
                            type="text" 
                            placeholder="First Name" 
                            required
                            value={firstName}
                            onChange={e => setFirstName( e.target.value)}
                        />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="lastName">
                    <Form.Label column md={3}>Last Name:</Form.Label>
                    <Col md={9} >
                        <Form.Control 
                            name='lastName'
                            type="text" 
                            placeholder="Last Name"
                            required
                            value={lastName}
                            onChange={e => setLastName( e.target.value)}
                        />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="formBasicPassword">
                    <Form.Label column md={3}>Password:</Form.Label>
                    <Col md={9} >
                        <Form.Control 
                            name='password'
                            type="password" 
                            placeholder="New password" 
                            value={password}
                            onChange={e => setPassword( e.target.value)}
                        />
                    </Col>
                </Form.Group>
  
            </section>

            <section key='location-settings' className='input-section'>
                <h4>Location Settings</h4>
                <Form.Group as={Row} className="mb-3" controlId="region">
                <Form.Label column md={3}>Region:</Form.Label>
                <Col md={9} >
                    <Form.Select aria-label="Region"
                        name='region'
                        value={selectedRegion}
                        onChange={e => setRegion(e.target.value) }
                        required
                    >
                        <option value=''>Select</option>
                        {ExtRegionList.map( (reg:ExtRegion) => {
                            return <option key={reg.rid} value={reg.rid}>{reg.name}</option>
                        })}
                    </Form.Select>
                </Col>
                </Form.Group>
        


                <Form.Group as={Row} className="mb-3" controlId="defaultLocation">
                <Form.Label column md={3}>Default Location:</Form.Label>
                <Col md={9} >
                    <Form.Select aria-label="Default Location"
                        name='defaultLocation'
                        value={location}
                        onChange={e => setDefaultLocation(e.target.value) }
                        required
                    >
                        <option value=''>Select</option>
                        {locations.map( (loc:ExtLocation) => {
                            return <option key={loc._id} value={loc._id}>{loc.name}</option>
                        })}
                    </Form.Select>
                </Col>
                </Form.Group>
        

                <Form.Group as={Row} controlId="defaultLocation" required>
                    <Row className='check-label'><label>Associated Locations:</label></Row>
                    <Row className='all-locations'>
                        {locations.map( (loc:ExtLocation) => {
                            return <Form.Check
                                type={isTabletOrMobile ? 'switch' : 'checkbox'}
                                id={loc._id}
                                label={loc.name}
                                key={loc._id}
                                name={loc.name}
                                checked={ associatedLocations && associatedLocations.indexOf(loc._id) > -1 ? true : false  }
                                onChange={(e:any)=>{onLocationClicked(loc._id, e.target.checked)}}
                            />
                        })}
                    </Row>
                </Form.Group>
            </section>

            <section key='permissions' className='input-section'>
                <h4>Permissions</h4>
                <Form.Group as={Row} className="mb-3" controlId="active">
                    <Col md={9} >
                        <Form.Check id='isActive'  type="checkbox" label="Account is active" 
                            checked={ permissions.indexOf(ExtPermissions.isActive) > -1 ? true : false  }
                            onChange={(e:any)=>{onPermissionClicked(ExtPermissions.isActive, e.target.checked)}} />
                            
                        <Form.Check id='canSubmitForms' type="checkbox" label="Can Submit Forms"  
                            checked={ permissions.indexOf(ExtPermissions.canSubmitForms) > -1 ? true : false  }
                            onChange={(e:any)=>{onPermissionClicked(ExtPermissions.canSubmitForms, e.target.checked)}} />

                        { doesUserHavePermission(authUser, ExtPermissions.canViewOthersForms)  && 
                        <Form.Check id='canViewOtherForms' type="checkbox" label="Can View Other People's Forms"  
                            checked={ permissions.indexOf(ExtPermissions.canViewOthersForms) > -1 ? true : false  }
                            onChange={(e:any)=>{onPermissionClicked(ExtPermissions.canViewOthersForms, e.target.checked)}} />
                        }

                        { doesUserHavePermission(authUser, ExtPermissions.canManageForms)  && 
                        <Form.Check id='canEditOtherForms' type="checkbox" label="Can Edit Other People's Forms"  
                            checked={ permissions.indexOf(ExtPermissions.canManageForms) > -1 ? true : false  }
                            onChange={(e:any)=>{onPermissionClicked(ExtPermissions.canManageForms, e.target.checked)}} />
                        }

                        { doesUserHavePermission(authUser, ExtPermissions.canDeleteForms)  && 
                        <Form.Check id='canDeleteForms' type="checkbox" label="Can Delete Forms"  
                            checked={ permissions.indexOf(ExtPermissions.canDeleteForms) > -1 ? true : false  }
                            onChange={(e:any)=>{onPermissionClicked(ExtPermissions.canDeleteForms, e.target.checked)}} />
                        
                        }
                        { doesUserHavePermission(authUser, ExtPermissions.canCallPostFields)  && 
                        <Form.Check id='canCallPostFields' type="checkbox" label="Can Use Post Call Options"  
                            checked={ permissions.indexOf(ExtPermissions.canCallPostFields) > -1 ? true : false  }
                            onChange={(e:any)=>{onPermissionClicked(ExtPermissions.canCallPostFields, e.target.checked)}} />
                        }

                        { doesUserHavePermission(authUser, ExtPermissions.canCreateReports)  && 
                        <Form.Check id='canManageReports' type="checkbox" label="Can Create Reports"  
                            checked={ permissions.indexOf(ExtPermissions.canCreateReports) > -1 ? true : false  }
                            onChange={(e:any)=>{onPermissionClicked(ExtPermissions.canCreateReports, e.target.checked)}} />
                        }

                        { doesUserHavePermission(authUser, ExtPermissions.canAddUsers)  && 
                        <Form.Check id='canAddUsers' type="checkbox" label="Can Add Users"  
                            checked={ permissions.indexOf(ExtPermissions.canAddUsers) > -1 ? true : false  }
                            onChange={(e:any)=>{onPermissionClicked(ExtPermissions.canAddUsers, e.target.checked)}} />
                        }

                        { doesUserHavePermission(authUser, ExtPermissions.canAddUsers)  && 
                        <Form.Check id='canManageUsers' type="checkbox" label="Can Manage Users"  
                            checked={ permissions.indexOf(ExtPermissions.canManageUsers) > -1 ? true : false  }
                            onChange={(e:any)=>{onPermissionClicked(ExtPermissions.canManageUsers, e.target.checked)}} />
                        }

                        { doesUserHavePermission(authUser, ExtPermissions.canManageLocations)  && 
                        <Form.Check id='canManageLocations' type="checkbox" label="Can Manage Locations"  
                            checked={ permissions.indexOf(ExtPermissions.canManageLocations) > -1 ? true : false  }
                            onChange={(e:any)=>{onPermissionClicked(ExtPermissions.canManageLocations, e.target.checked)}} />
                        }

                        { doesUserHavePermission(authUser, ExtPermissions.canManageEventTypes)  && 
                        <Form.Check id='canManageManageEvents' type="checkbox" label="Can Manage Events"  
                            checked={ permissions.indexOf(ExtPermissions.canManageEventTypes) > -1 ? true : false  }
                            onChange={(e:any)=>{onPermissionClicked(ExtPermissions.canManageEventTypes, e.target.checked)}} />
                        }

                        
                        { doesUserHavePermission(authUser, ExtPermissions.isAdmin)  &&
                        <Form.Check id='isAdmin' type="checkbox" label="Is an Admin"  
                            checked={ permissions.indexOf(ExtPermissions.isAdmin) > -1 ? true : false  }
                            onChange={(e:any)=>{onPermissionClicked(ExtPermissions.isAdmin, e.target.checked)}} />
                        }
                    </Col> 
                </Form.Group>
            </section>
            
            <section className='form-controls'>
                <Button variant="primary" type="submit" >
                    Submit
                </Button>
            </section>
        </fieldset>
      </Form>
}
    </Container>
  );
};

export default UserEditor;
