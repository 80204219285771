import { createAsyncThunk,  createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { FeathersError, serializeFeathersError } from '../../app/api/serializeFeathersError';
import { ExtUser } from '../../app/api/api.types';
import { apiCreateUser, apiFindUsers, apiGetUser, apiPatchUser } from '../../app/api/api';


/**
 * Async thunk state
 */
export interface UsersState {
  list: Array<ExtUser>;
  user: ExtUser|undefined;
  status: 'idle' | 'loading' | 'failed';
  error: FeathersError|undefined,
  pagination: {
    limit: number,
    skip: number,
    total: number,
  },
}

const initialState: UsersState = {
  list: [],
  user: undefined,
  status: 'idle',
  error: undefined,
  pagination: {
    limit: 0,
    skip: 0,
    total: 0,
  },
};



/**
 * Get a single user
 */
 export const getUser = createAsyncThunk(
  'users/get',
  async ( id:string, { rejectWithValue }) => {
    try{
      const response = await apiGetUser(id);
      return response;
    }catch(err){
      const e = serializeFeathersError(err)
      return rejectWithValue(e);
    }
  }
);


/**
 * Search users based on a query
 */
export const findUsers = createAsyncThunk(
  'users/find',
  async ( query:Object, { rejectWithValue }) => {
    try{
      const response = await apiFindUsers(query);
      return response;
    }catch(err){
      const e = serializeFeathersError(err)
      return rejectWithValue(e);
    }
  }
);


/**
 * Create a new user
 */
export const createUser = createAsyncThunk(
  'users/create',
  async ( 
    {user}:{user:ExtUser}, 
    { rejectWithValue }) => {
    try{
       const response = await apiCreateUser({user:user});
      return response.data;
    }catch(err){
      const e = serializeFeathersError(err)
      return rejectWithValue(e);
    }
  }
);


/**
 * Update an existing user
 */
 export const updateUser = createAsyncThunk(
  'users/update',
  async ( 
    {user}:{user:ExtUser}, 
    { rejectWithValue }) => {
    try{
       const response = await apiPatchUser({user:user});
      return response.data;
    }catch(err){
      const e = serializeFeathersError(err)
      return rejectWithValue(e);
    }
  }
);





/**
 * Creates the Redux Slice 
 */
export const usersSlice = createSlice({
  name: 'users',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.status = 'idle';
        if ((action.payload.data) ){
          state.list = action.payload.data;
          state.pagination.limit = action.payload.limit;
          state.pagination.skip = action.payload.skip;
          state.pagination.total = action.payload.total;
        }else{
          state.user = action.payload;
        }
        state.error = undefined;
      })
      .addCase(findUsers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(findUsers.fulfilled, (state, action) => {
        state.status = 'idle';
        if ((action.payload.data) ){
          state.list = action.payload.data;
          state.pagination.limit = action.payload.limit;
          state.pagination.skip = action.payload.skip;
          state.pagination.total = action.payload.total;
        }else{
          state.user = action.payload;
        }
        state.error = undefined;
      })
      .addCase(findUsers.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(createUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.status = 'idle';
        state.user = action.payload;
      })
      .addCase(createUser.rejected, (state) => {
        state.status = 'failed';
      })
  },
});


/**
 * Create the Redux Selector to access  state data.
 * @param state RootState
 * @returns Array of all users
 */
export const selectUsersList = (state: RootState) => state.users.list;
export const selectUser = (state: RootState) => state.users.user;
export const selectUsersNetStatus = (state: RootState) => state.users.status;


export default usersSlice.reducer;
